import { useMutation, useQuery } from '@tanstack/react-query';
import { refreshToken, sendUserBrandSms, sendUserSms } from './api';
import { isProdOffConsole } from '@/config';

export function useUserSms() {
  return useMutation((params: HmgId.IUserSmsReq) => sendUserSms(params));
}

export function useUserBrandSms() {
  return useMutation((params: HmgId.IUserBrandSmsReq) => sendUserBrandSms(params), {
    onError: error => isProdOffConsole(error),
  });
}

export function useRefreshToken(options: any) {
  return useQuery([], () => refreshToken(), {
    ...options,
    staleTime: 3600000, // 1시간 (1000 * 60 * 60 )
    refetchOnWindowFocus: true,
  });
}
