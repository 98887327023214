import { userSelector } from '@/stores/common/user';
import { useRecoilValue } from 'recoil';

type TMember = [true, any];
type TNonMember = [false, null];

function useMember(): TNonMember | TMember {
  const userStore = useRecoilValue(userSelector);

  return !userStore?.profile ? [false, null] : [true, userStore.profile];
}

export default useMember;
