// import { cloneDeep } from './cloneDeep';
import _ from 'lodash-es';

/**
 * 객체 데이터에서 키 리스트 위치에 있는 값 반환
 * @param keys
 * @param obj
 */
export const getValueByKeys = <T = unknown>(
  keys: (string | number)[] | string,
  obj: Record<string | number, unknown>,
): T => {
  if (!Array.isArray(keys)) {
    return obj?.[keys as string] as T;
  }

  const key = keys[0];

  if (keys.length === 1) {
    return obj?.[key] as T;
  } else {
    return getValueByKeys<T>(keys.slice(1), obj?.[key] as Record<string | number, unknown>);
  }
};

/**
 * 객체 데이터에서 키 배열 리스트 위치에 데이터 세팅
 * @param value
 * @param keys
 * @param obj
 */
export const setValueByKeys = (value: unknown, keys: (string | number)[], obj: Record<string | number, unknown>) => {
  const key = keys[0];

  if (keys.length === 1) {
    obj[key] = value;
  } else {
    if (!obj[key]) {
      obj[key] = typeof keys[1] === 'number' ? [] : {};
    }
    setValueByKeys(value, keys.slice(1), obj[key] as Record<string | number, unknown>);
  }
};

/**
 * 입력한 키 배열에 해당하는 위치에 value 값을 갖는 object를 반환하는 함수
 * @param keys
 * @param value
 */
export const createObjectByKeys = (keys: (string | number)[], value: unknown) => {
  const result = {};

  let currentObj: Record<string | number, unknown> = result;

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];
    const isLastKey = i === keys.length - 1;

    if (isLastKey) {
      currentObj[currentKey] = value;
    } else {
      const nextKeyType = typeof keys[i + 1];
      const shouldCreateArray = nextKeyType === 'number';

      currentObj = (currentObj[currentKey] = shouldCreateArray ? [] : {}) as Record<string | number, unknown>;
    }
  }

  return _.cloneDeep(result);
};
