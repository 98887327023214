import { css } from '@emotion/react';

const myMapStyle = css`
  width: 100%;
  height: 100%;

  position: relative;

  img {
    box-sizing: content-box;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  img[alt~='Google'] {
    display: none !important;
  }

  img + img {
    display: none;
  }
`;

export default myMapStyle;
