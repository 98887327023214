import dealerBlockTopStyle from '@/components/modules/dealerBlock/components/top/style';
import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';

interface IDealerBlockTopProps {
  title: string;
  distance?: string | number;
}

function DealerBlockTop({ title, distance }: IDealerBlockTopProps) {
  const { t } = useTranslation();

  return (
    <div className="dealer-block-top" css={dealerBlockTopStyle}>
      <div className="dealer-block-top-left">
        <div className="title-box">
          <span className="dealer-block-title">{title}</span>
        </div>
      </div>
      {distance && distance !== 0 ? (
        <div className="dealer-block-top-right">
          <div className="dealer-location-box">
            <span className="dealer-location">
              {t('Label.NearBy')}
              <span className="distance">{distance}</span>km
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DealerBlockTop;
