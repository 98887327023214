import React, { useEffect, useId, useRef, useState } from 'react';
import classNames from 'classnames';
import { DropdownProps, DropdownItem as DropdownItemInterface } from './type';
import DropdownItem from './components/dropdownItem';
import { SelectedItems } from './components/selectedItems';

// TODO: value type generics로 받아야하는가?
// onDeselect가 뱉어줄 매개변수들은 value만?
// 검색에 debounce 추가
export default function Dropdown({
  defaultValue = null,
  value,
  onChange = () => null,
  items = [],
  itemRender,
  placeholder = '',
  disabled = false,
  open: openProp,
  onDropdownOpen = () => null,
  status,
  multiSelect = false,
  tagRender,
  onDeselect = () => null,
  allowSearch = false,
  searchFilterFunction = (input, option) => option?.label.startsWith(input),
  searchSortFunction,
  className = '',
}: DropdownProps) {
  const id = useId();

  const dropdownRef = useRef<HTMLDivElement>();

  const dropdownListRef = useRef<HTMLUListElement>();
  const searchInputRef = useRef<HTMLInputElement>();

  const [showDefault, setShowDefault] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(openProp);
  const [selectedItem, setSelectedItem] = useState<DropdownItemInterface | null>(
    items.find(item => item.value === defaultValue),
  );
  const [selectedItemList, setSelectedItemList] = useState<DropdownItemInterface[]>(
    Array.isArray(defaultValue) ? items.filter(item => defaultValue.includes(item.value)) : [],
  );
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [posAbove, setPosAbove] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && dropdownListRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownListRect = dropdownListRef.current.getBoundingClientRect();
      const bottomMargin = window.innerHeight - (dropdownRect.bottom + dropdownListRect.height);

      if (bottomMargin < 50) {
        // 하단에 여백이 없음, 드롭다운을 위로 보이도록 함
        setPosAbove(true);
      } else {
        // 하단에 여백이 있음, 클래스를 제거하거나 다른 조치를 취함
        setPosAbove(false);
      }
    }
  }, [isOpen]);

  const changeDropdownOpenState = function (open: boolean) {
    if (typeof openProp !== 'boolean') {
      setIsOpen(open);
    }
    onDropdownOpen(open);
  };

  const closeDropdown = function () {
    changeDropdownOpenState(false);
    setSearchInputValue('');
    searchInputRef.current?.blur();
  };

  const onClickOpenButton = function () {
    if (disabled) return;
    if (isOpen && !!searchInputValue) setSearchInputValue('');
    changeDropdownOpenState(!isOpen);
    searchInputRef.current?.focus();
  };

  const deselectOption = function (option: DropdownItemInterface) {
    const tempSelectedList = selectedItemList.filter(prevSelectedItem => prevSelectedItem.value !== option.value);
    const tempValueList = tempSelectedList.map(selectedItem => selectedItem.value);

    setSelectedItemList(tempSelectedList);
    onChange(tempValueList, tempSelectedList);
    onDeselect(option.value, option);
    searchInputRef.current?.focus();
  };

  const onClickOption = async (option: DropdownItemInterface) => {
    searchInputRef.current?.focus();

    if (multiSelect) {
      if (isItemSelected(option.value) === true) {
        // 기선택 되어 있으면 선택 해제
        deselectOption(option);

        return;
      }
      // 미선택 되어 있으면 선택하기
      const tempValues = selectedItemList?.map(selectedItem => selectedItem.value);

      setSelectedItemList([...selectedItemList, option]);
      onChange([...tempValues, option.value], [...selectedItemList, option]);
      setSearchInputValue('');

      return;
    } else {
      setSelectedItem(option);
      onChange(option.value, option);
      closeDropdown();

      return;
    }
  };

  const onClickTagDelete = function (option: DropdownItemInterface) {
    deselectOption(option);
  };

  const handleInputChange = function (value: string) {
    setSearchInputValue(value);
    if (!isOpen) changeDropdownOpenState(true);
  };

  const isItemSelected = (targetValue: string | number) => {
    return multiSelect
      ? selectedItemList.some(item => item.value === targetValue)
      : selectedItem?.value === targetValue;
  };

  useEffect(() => {
    if (typeof openProp === 'boolean' && openProp !== isOpen) setIsOpen(openProp);
  }, [openProp]);

  useEffect(() => {
    if (showDefault === true && !value) {
      // 첫 렌더링 시에는 defaultValue값 유지
      setShowDefault(false);

      return;
    }
    if (!value) {
      multiSelect ? setSelectedItemList([]) : setSelectedItem(null);

      return;
    }
    if (multiSelect) {
      const itemListWithMatchingValue = value?.map((valueString: number | string) =>
        items.find(item => item.value === valueString),
      );

      if (JSON.stringify(itemListWithMatchingValue) === JSON.stringify(selectedItemList)) return;

      setSelectedItemList(itemListWithMatchingValue);

      return;
    } else {
      const itemWithMatchingValue = items.find(item => item.value === value);

      setSelectedItem(itemWithMatchingValue);

      return;
    }
  }, [value, items]);

  useEffect(() => {
    const clickOutOfDropdown = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('click', clickOutOfDropdown);

    return () => {
      document.removeEventListener('click', clickOutOfDropdown);
    };
  }, []);

  const renderFilteredOptions = () => {
    let searchResult = items;

    if (allowSearch) {
      searchResult = items.filter(item => searchFilterFunction(searchInputValue, item));
      if (!!searchSortFunction) {
        searchResult = searchResult.sort((option1, option2) => searchSortFunction(option1, option2));
      }
    }

    return searchResult.map((item, index) => (
      <DropdownItem
        key={`${id}-dropdownItem-${index}`}
        value={item.value}
        onClick={() => onClickOption(item)}
        disabled={item.disabled}
        isSelected={isItemSelected(item.value)}
      >
        {!!itemRender ? itemRender(item) : <p>{item.label}</p>}
      </DropdownItem>
    ));
  };

  return (
    <div
      className={classNames('haefe-dropdown', className, isOpen ? 'haefe-dropdown-open' : 'haefe-dropdown-closed', {
        'haefe-dropdown-multiselect': multiSelect,
        'haefe-dropdown-disabled': disabled,
        error: status === 'error',
        warning: status === 'warning',
        success: status === 'success',
        above: posAbove,
      })}
      ref={dropdownRef}
    >
      <div
        tabIndex={0}
        className={classNames('haefe-dropdown-selector', {
          'haefe-dropdown-searching': allowSearch && !!searchInputValue,
        })}
        onClick={onClickOpenButton}
      >
        <SelectedItems
          multiSelect={multiSelect}
          selectedItem={selectedItem}
          selectedItemList={selectedItemList}
          value={value}
          placeholder={placeholder}
          tagRender={tagRender}
          onTagDelete={onClickTagDelete}
        />
        {allowSearch && (
          <div className="haefe-dropdown-search-wrapper" style={{ width: `calc(${searchInputValue.length}*9px)` }}>
            <input
              ref={searchInputRef}
              className="haefe-dropdown-search-input"
              value={searchInputValue}
              onChange={e => handleInputChange(e.target.value)}
            />
          </div>
        )}
        <i className="haefe-dropdown-ic-arrow"></i>
        {/* //input, tag7 등 삽입 예정 위치 Button */}
      </div>
      <ul tabIndex={0} className="haefe-dropdown-content" ref={dropdownListRef}>
        {renderFilteredOptions()}
      </ul>
    </div>
  );
}
