export const getValidRangeValueBase = (value: number, valueRange: number, step: number, min: number, max: number) => {
  const lastValidValue = min + Math.round(valueRange / step) * step;

  if (value > max) return max;
  else if (value <= min) return min;
  else if (value > lastValidValue) {
    const differenceFromMax = max - value;
    const differenceFromLast = value - lastValidValue;

    return differenceFromMax > differenceFromLast ? lastValidValue : max;
  }

  return Math.round(value / step) * step;
};
