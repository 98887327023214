export function decodeJWT(token: string) {
  const parts = token.split('.');

  if (parts.length !== 3) {
    throw new Error('Invalid JWT');
  }

  const decoded = parts.slice(0, 2).map(part => {
    return JSON.parse(globalThis.atob(part.replace(/_/g, '/').replace(/-/g, '+')));
  });

  return {
    header: decoded[0],
    payload: decoded[1],
  };
}
