export const objectToString = (obj: any) =>
  Object.entries(obj)
    .reduce((acc: any, cur: any) => {
      typeof cur[1] !== 'object' && acc.push(cur.join('='));

      return acc;
    }, [])
    .join('&');

export const getDataWithPage = (params: any) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export const omit = (obj: { [key: string]: any }, props: string[]) => {
  if (obj === null) return {};

  if (typeof props === 'string') {
    props = [props];
  }

  return Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]: [string, any]) => {
    if (!props || props.indexOf(key) === -1) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const pickNotEmptyValues = (obj: { [key: string]: any }) => {
  if (obj === null) return {};

  return Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]: [string, any]) => {
    const valueType = typeof value;

    if (value === undefined) return acc;
    else if (valueType === 'object' && value instanceof Array && value.length === 0) {
      return acc;
    } else if (valueType === 'object' && value instanceof Object && Object.keys(value).length === 0) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
};

export const priceWithComma = (price: number) => price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function arrayChunk<T>(data: T[], size: number): T[][] {
  size = size ?? 1;
  data = data ?? [];

  const arr = [];

  for (let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }

  return arr;
}

export const removeKey = (curData: any, removeKeyAry: string[]) => {
  const newData = Object.entries(curData).reduce(
    (acc: any, [key, item]: any) => {
      let newMap = undefined;
      const hasRemoveKey: boolean = removeKeyAry?.includes(key) ?? false;

      if (typeof item === 'object' && !item?.$$typeof) {
        newMap = removeKey(item, removeKeyAry);
      } else {
        newMap = item;
      }

      if (!hasRemoveKey) acc[key] = newMap;

      return acc;
    },
    Array.isArray(curData) ? [] : {},
  );

  return newData;
};

export const getKmFromLatAndLon = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  if (!(lat1 && lat2 && lon1 && lon2)) return null;

  function converToRadian(degree: number) {
    return (Math.PI * degree) / 180;
  }

  const radianLat1 = converToRadian(lat1);
  const radianLat2 = converToRadian(lat2);
  const radianLon1 = converToRadian(lon1);
  const radianLon2 = converToRadian(lon2);

  const distance =
    Math.acos(
      Math.sin(radianLat1) * Math.sin(radianLat2) +
        Math.cos(radianLat1) * Math.cos(radianLat2) * Math.cos(radianLon1 - radianLon2),
    ) * 6371;

  return Math.floor(distance * 10) / 10;
};

export const setEmptyArr = (data: any[], len: number) => {
  const newData = data?.filter(d => d);
  const emptyArr =
    newData?.length >= len ? [] : new Array(newData?.length < len ? len - newData?.length : len).fill(null);

  return newData?.length > 0 ? [...newData, ...emptyArr] : emptyArr;
};

export const makeImageKey = (idList: (number | string)[]) => {
  if (idList.includes(null) || idList.includes(undefined)) return null;

  const imageKey = idList?.reduce((acc: any, d: number | string, index: number) => {
    if (index === 0) {
      return d?.toString();
    }

    return acc?.toString() + '-' + d?.toString();
  }, '');

  return imageKey?.toString() ?? null;
};

export function getCharByteSize(ch: string) {
  if (ch === null || ch.length === 0) {
    return 0;
  }

  const charCode = ch.charCodeAt(0);

  if (charCode <= 0x00007f) {
    return 1;
  } else if (charCode <= 0x0007ff) {
    return 2;
  } else if (charCode <= 0x00ffff) {
    return 3;
  } else {
    return 4;
  }
}

export function getSliceByteString(str: string, maxSize: number): string {
  let charBytes = 0;
  let sliceString = '';

  if (!str) return '';
  if (!maxSize) return str;

  for (let i = 0; i < str.length; i++) {
    if (getCharByteSize(str.charAt(i)) + charBytes > maxSize) {
      break;
    }

    charBytes += getCharByteSize(str.charAt(i));
    sliceString += str[i];
  }

  return sliceString;
}

export const getDealerAddress = (data: Global.IDealerDetail | Dealers.TDealerEntity) => {
  return data
    ? [
        (data?.address ?? '') + (data?.address && data?.subDistrict ? ', ' : '') + (data?.subDistrict ?? ''),
        (data?.district ?? '') + (', ' + data?.province ?? '') + (data?.postalCode ? ` ${data?.postalCode}` : ''),
      ]
    : ['-', ''];
};
