import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import RadioGroup from './RadioGroup';
import { RadioBasicProps } from './type';
import radioStyle from './style';

function Radio({
  onClick = () => null,
  checked: checkedProp,
  defaultChecked,
  disabled = false,
  label,
  name = '',
  value = '',
  className = '',
  style,
  ...otherProps
}: RadioBasicProps) {
  const [checked, setChecked] = useState<boolean>(checkedProp ?? defaultChecked ?? false);

  const ref = useRef(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (checkedProp !== undefined || disabled) return;
      const check = e.target?.checked;

      setChecked(check);
    },
    [checkedProp],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      onClick(checked, e, value);
    },
    [value, checked, onClick],
  );

  useEffect(() => {
    if (checkedProp === undefined) return;
    setChecked(checkedProp);
  }, [checkedProp]);

  return (
    <div className={classNames(className, 'haefe-radio')} style={style}>
      <label
        className={classNames(
          'haefe-radio-label',
          checked ? 'haefe-radio-checked' : 'haefe-radio-unchecked',
          disabled && 'haefe-radio-disabled',
        )}
      >
        <input
          className="haefe-radio-input"
          type="radio"
          name={name}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onClick={handleClick}
          ref={ref}
          checked={checked}
          {...otherProps}
        />
        <i className="haefe-radio-itag"></i>
        {label && <div className="haefe-radio-content">{label}</div>}
      </label>
    </div>
  );
}

Radio.Group = RadioGroup;

export default Radio;
