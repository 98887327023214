import { userSelector } from '@/stores/common/user';
import { useSetRecoilState } from 'recoil';
import { useCustomCookies } from '@/hooks/useCustomCookie';
import { CookieDomainUrl, nonMemberCookieKey } from '@/constants/common';
import { revokeToken } from '@/query/hmgId/api';

const useLogout = () => {
  //
  const [cookies, setCookie, removeCookie] = useCustomCookies([
    'cookie_BuyOnline_th',
    'refresh_BuyOnline_th',
    nonMemberCookieKey,
  ]);
  const setUserStore = useSetRecoilState(userSelector);

  const logout = async () => {
    const cookieOption = {
      path: '/',
      domain: CookieDomainUrl,
    };

    revokeToken().then(() => {
      removeCookie('cookie_BuyOnline_th', cookieOption);
      removeCookie('refresh_BuyOnline_th', cookieOption);
      removeCookie(nonMemberCookieKey, cookieOption);
      setUserStore({ profile: null });
    });
  };

  return logout;
};

export default useLogout;
