import React, { useState } from 'react';
import { InputBasicProps } from '../type';
import classNames from 'classnames';
import { STATUS_CLASS } from '../../constants/status';
import InputCount from './inputCount';

function InputBasic({
  onChange,
  value,
  defaultValue,
  type = 'text',
  allowClear = false,
  visiblePassword = false,
  disabled = false,
  onKeyDown,
  status = null,
  maxByte,
  maxLength,
  showCount,
  suffix,
  prefix,
  className,
  clearInputValue,
  refProp,
  ...otherProps
}: InputBasicProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true);
  const [focus, setFocus] = useState<boolean>(false);
  const hasMax = maxByte > 0 || maxLength > 0;
  const isByte = !!maxByte ? true : false;
  const maxSize = isByte ? maxByte : maxLength;
  const isPassword = type === 'password';
  const isNumber = type === 'number';
  const statusClassName = STATUS_CLASS[status] ?? null;

  function handleVisible() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <div
      className={classNames(
        className,
        'haefe-input-wrapper',
        { 'haefe-input-wrapper-focus': focus },
        { 'haefe-input-wrapper-disabled': disabled },
        statusClassName ?? null,
      )}
    >
      {prefix && <div className="haefe-input-prefix">{prefix}</div>}
      <input
        onFocus={e => {
          setFocus(true);
          otherProps?.onFocus && otherProps?.onFocus(e);
        }}
        onBlur={e => {
          setFocus(false);
          otherProps?.onBlur && otherProps?.onBlur(e);
        }}
        ref={refProp}
        type={(!isPasswordVisible && isPassword) || isNumber ? 'text' : type}
        className="haefe-input"
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        {...(isPassword && isPasswordVisible && value ? {} : { value: value })}
        {...(isNumber ? { inputMode: 'decimal' } : {})}
        {...otherProps}
        onKeyDown={onKeyDown}
      />
      {allowClear && !!value?.length && !disabled && (
        <button className="haefe-input-clear-btn" onClick={clearInputValue}>
          <i className="haefe-input-clear-icon" />
        </button>
      )}
      {isPassword && visiblePassword && (
        <button className="haefe-input-visible-btn" onClick={handleVisible}>
          <i
            className={classNames(
              { 'haefe-input-visible-icon': !isPasswordVisible },
              { 'haefe-input-invisible-icon': isPasswordVisible },
            )}
          />
        </button>
      )}
      {showCount && hasMax && <InputCount isByte={isByte} value={value} maxSize={maxSize} type={type} />}
      {suffix && <div className="haefe-input-suffix">{suffix}</div>}
    </div>
  );
}

export default InputBasic;
