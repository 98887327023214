import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InputProps } from './type';
import InputTextarea from './components/inputTextArea';
import InputBasic from './components/inputBasic';
import { INPUT_VALIDATION_PATTERN } from '../constants/pattern';
import checkMaxSizeValidation from '../utils/checkMaxSizeValidation';
import checkIsValidInput from '../utils/checkIsValidInput';
import checkIsEnterPressed from '../utils/checkIsEnterPressed';
import { INPUT_TYPE } from '../constants/inputType';

function Input({
  type = 'text',
  value: valueProp,
  defaultValue = '',
  status: statusProps = null,
  mask,
  ...props
}: InputProps) {
  const { maxByte, maxLength, onPressEnter, onChange, onKeyDown, disabled: disabledProps } = props;
  const [valueState, setValueState] = useState<string>(defaultValue ?? '');
  const [status, setStatus] = useState(statusProps);
  const ref = useRef(null);
  const hasMax = !!maxByte || !!maxLength;
  const isByte = !!maxByte ? true : false;
  const maxSize = isByte ? maxByte : maxLength;
  const isTextArea = type === 'textarea';
  const disabled = disabledProps || maxByte <= 0 || maxLength <= 0;

  const value = useMemo(() => (valueProp !== undefined ? valueProp : valueState), [valueProp, valueState]);

  const handleMask = (input: string) => {
    const numericValue = input.replace(/\-/g, '');
    let formattedValue = '';
    let inputIndex = 0;

    for (let i = 0; i <= mask.length; i++) {
      const inputChar = numericValue[inputIndex];
      const maskChar = mask[i];

      if (!inputChar) break;
      if (maskChar === '0') inputIndex++;

      formattedValue += maskChar === '-' ? '-' : inputChar;
    }

    return formattedValue;
  };

  function clearInputValue(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setValueState('');
    onChange && onChange('');
  }

  function handleChange(e: React.ChangeEvent<any>) {
    if (hasMax) {
      const isValidSize = checkMaxSizeValidation(e?.target?.value, isByte, maxSize);

      if (!isValidSize) {
        ref.current.value = value;

        return;
      }
    }

    switch (type) {
      case INPUT_TYPE.Password:
        const isValidPassword = checkIsValidInput(e?.target?.value, INPUT_VALIDATION_PATTERN.password);

        if (!isValidPassword) {
          ref.current.value = value;

          return;
        }

        break;
      case INPUT_TYPE.Number:
        const numericValue = mask ? e?.target?.value.replace(/\-/g, '') : e?.target?.value;
        const isValidNumber = checkIsValidInput(numericValue, INPUT_VALIDATION_PATTERN.number);

        if (!isValidNumber && e.target.value !== '') {
          ref.current.value = value;

          return;
        }

        break;
      case INPUT_TYPE.Email:
        const isValidEmail = checkIsValidInput(e?.target?.value, INPUT_VALIDATION_PATTERN.email);

        setStatus(isValidEmail ? statusProps : 'error');

        break;
    }

    const newVal = mask ? handleMask(e.target.value) : e.target.value;

    setValueState(newVal);

    onChange && onChange(newVal, e);
  }

  function handleKeyDown(e: React.KeyboardEvent<any>) {
    const isEnterPressed = checkIsEnterPressed(e);

    if (isEnterPressed && onPressEnter) onPressEnter(e);

    onKeyDown && onKeyDown(e);
  }

  useEffect(() => {
    if (valueProp) setValueState(valueProp);
    else if (valueProp !== undefined) setValueState('');
  }, [valueProp]);

  useEffect(() => {
    setStatus(statusProps);
  }, [statusProps]);

  return isTextArea ? (
    <InputTextarea
      {...props}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      clearInputValue={clearInputValue}
      value={value}
      status={status}
      refProp={ref}
      disabled={disabled}
    />
  ) : (
    <InputBasic
      {...props}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      clearInputValue={clearInputValue}
      value={value}
      type={type}
      status={status}
      refProp={ref}
      disabled={disabled}
    />
  );
}

export default Input;
