import React, { useMemo } from 'react';
import { globalAlertPropsState, globalSpinnerState, showLayoutSelector, showMobileMenuSelector } from '@/stores/common';
import { i18nLocaleState, i18nTenantState } from '@/stores/common/i18n';
import { memberCheckState, userSelector } from '@/stores/common/user';
import { Location } from '@components/modules/location';
import { LayoutStyle } from '@layouts/style';
import { getDevice } from '@utils/getDevice';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import GlobalStyle from 'styles/globalStyle';
import Footer from './footer';
import Header from './header';
import PageTitle from './pageTitle';
import { useCookies } from 'react-cookie';
import AgreementLayout from './components/agreement';
import useLazyCallback from '@/hooks/useLazyCallback';
import { AxiosError } from 'axios';
import useMenuLists from '@/hooks/useMenuList';
import { GAScript, GATracker } from './GATracker';
import GlobalComponents from '@/components/modules/globalComponents';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useSetDefaultDealer from '@/hooks/useSetDefaultDealer';
import { isProdOffConsole } from '@/config';
import { useTranslation } from 'next-i18next';
import { ICustomErrorProps, handleError } from '@/utils/customError';
import useLogout from '@/hooks/useLogout';

const errorFilterArr = ['UNABLE_TO_CHANGE_CONFIGURATION'];

interface ILayout {
  children: React.ReactNode;
  pagePropsError: ICustomErrorProps;
}

function Layout({ children, pagePropsError }: ILayout) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const setUserStore = useSetRecoilState(userSelector);
  const userStore = useRecoilValue(userSelector);
  const showLayout = useRecoilValue(showLayoutSelector);
  const setGlobalAlertStore = useSetRecoilState<Global.IAlert>(globalAlertPropsState);
  const setI18nTenant = useSetRecoilState(i18nTenantState);
  const setI18nLocale = useSetRecoilState(i18nLocaleState);
  const setShowMobileMenu = useSetRecoilState(showMobileMenuSelector);
  const setLoading = useSetRecoilState(globalSpinnerState);
  const [isMemberChecked, setMemberCheckState] = useRecoilState(memberCheckState);
  const menuLists = useMenuLists();
  const logout = useLogout();
  const pathNameList = useMemo(
    () =>
      router.pathname?.split(/[?/]/).filter((item: string) => item !== '' && item !== 'th' && !item.includes('lang=')),
    [router],
  );

  const title = useMemo(() => {
    const defaultTitle = 'Hyundai Mobility Thailand – Click to Buy';

    if (pathNameList?.length > 0) {
      if (pathNameList[0] === 'configuration') {
        return defaultTitle;
      } else {
        return (
          pathNameList?.reduce((acc: any, d: string, i: number) => {
            acc = i < pathNameList?.length - 1 ? acc[d]?.children : acc[d]?.name;

            return acc;
          }, menuLists) ?? defaultTitle
        );
      }
    }

    return defaultTitle;
  }, [pathNameList, menuLists]);

  const setDefaultDealer = useSetDefaultDealer();

  const lazyQueue = useLazyCallback<AxiosError<any>>((queue: AxiosError<any>[]) => {
    const errorList = queue
      .filter(err => !errorFilterArr.includes(err?.response?.data?.code))
      .map(err => ({
        status: err?.response?.status,
        url: err?.response?.config?.url,
        code: err?.response?.data?.code?.replaceAll('_', ' ') || err?.response?.data?.errCode || 'API Error',
        message:
          err?.response?.data?.message || err?.response?.data?.errMsg || err?.response?.statusText || 'Unknown Error',
      }));

    const pattern = /^\/payment_api\/payments\/.+\/confirm$/;

    if (errorList.some(conf => pattern.test(conf.url))) {
      return;
    }

    if (errorList.some(conf => conf.url === '/hmg-id/api/v1/user/sms' && conf.status === 401)) {
      const err = errorList.find(conf => conf.url === '/hmg-id/api/v1/user/sms' && conf.status === 401);

      handleError(err, setGlobalAlertStore, t);

      return;
    }

    const tokenError = errorList.find(conf => conf.url === '/gateway/users/token');

    if (tokenError) {
      handleError(
        tokenError,
        err => {
          setGlobalAlertStore({
            title: t('Label.LoginExpired'),
            message: t('Alert.InvalidToken'),
            handlerOk() {
              logout();
              setGlobalAlertStore(null);
              if (router.pathname !== '/') {
                router.push(menuLists.root.path);
              }
            },
          });
        },
        t,
      );

      return;
    }

    if (errorList.some(conf => conf.url === '/user_api/users/me' && [401, 400].includes(conf.status))) {
      isProdOffConsole('show Agreement Popup >> AgreementLayout');

      // 401 에러시 Login Page로 이동 break 처리
      return;
    }

    // wwn <-> ctb 진입 시에 baseModel, dealerId 모두 정상인데도 에러떨어지는 케이스는 메인으로 이동 처리
    if (
      errorList.some(
        conf => conf.url === '/gateway/product/configuration' && conf.status !== 200 && router.query?.fromWWN === 'y',
      )
    ) {
      router.replace('/');

      return;
    }

    if (errorList.some(conf => conf.status === 401)) {
      logout();
      router.push(menuLists.login.path);

      return;
    }

    for (const err of errorList) {
      handleError(err, setGlobalAlertStore, t);
    }
  });

  const DynamicNavigation = dynamic(() => import('@components/modules/navigation'));
  const [cookies] = useCookies(['lang']);
  //최초 1번 device 구분을 위해서 실행

  useEffect(() => {
    const setScreenSize = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setScreenSize();

    window.addEventListener('resize', setScreenSize);

    return () => {
      window.removeEventListener('resize', setScreenSize);
    };
  }, []);

  useEffect(() => {
    const device = getDevice();

    setUserStore({ device });
    setI18nTenant(router.locale);
    setI18nLocale(router?.query?.lang?.toString() ?? 'en');
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault('Asia/Bangkok');
  }, []);

  useEffect(() => {
    if (pagePropsError) {
      handleError(pagePropsError, setGlobalAlertStore, t);
    }
  }, []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          // 🎉 이미 캐시에 데이터가 있는 경우에만 오류 알림을 표시합니다.
          // onError: (error: any) => {
          //   setGlobalStore({ apiError: true, apiErrorMessage: <p>api 에러입니다</p> });
          // },
        }),
        defaultOptions: {
          queries: {
            retry: false, //기본 값 3회 off
            refetchOnWindowFocus: false,
            cacheTime: 100, //TODO: mirage api 테스트용 cacheTime 변경
            staleTime: 100,
            // cacheTime: 1000 * 60 * 10, // 10 secs
            // staleTime: 1000 * 60 * 10, // 10 secs

            // useErrorBoundary: true, // onError 와 메소드 중복 조심

            onError: (error: any) => {
              lazyQueue(error);
            },
            networkMode: 'always',
          },

          mutations: {
            // useErrorBoundary: true,
            onError: (error: any) => {
              lazyQueue(error);
            },
            networkMode: 'always',
          },
        },
      }),
  );

  useEffect(() => {
    if (isMemberChecked) {
      setDefaultDealer(queryClient);
    }
  }, [cookies?.lang, isMemberChecked]);

  useEffect(() => {
    queryClient.refetchQueries({ stale: true, type: 'active' });
  }, [cookies?.lang]);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
      setShowMobileMenu(false);
    };

    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  return (
    <>
      <GATracker />
      <GAScript />
      <QueryClientProvider client={queryClient}>
        <Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
          {router?.pathname.indexOf('pay-result') > -1 ? (
            <>
              {children} <GlobalComponents />
            </>
          ) : (
            <>
              <Head>
                <link rel="preload" as="font" type="font/woff2" crossOrigin="" href="/fonts/HyundaiHeadReg.woff" />

                <title>{title}</title>
                <meta name="format-detection" content="telephone=no" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1, viewport-fit=cover"
                />
                {/* favicon 추가 */}
                <link rel="icon" href="/assets/images/favicon.ico" />

                <meta
                  name="keywords"
                  content="Hyundai Mobility Thailand, Buy a car Online, Hyundai Motor Company, Hyundai, Hyundai Motor"
                />
                <meta
                  name="description"
                  content="Welcome to the official Hyundai Mobility Thailand Online Sales website. View the latest models including new Hyundai Vehicles, download a brochure, find your dealer and book a test drive."
                />
                <meta
                  property="og:site_name"
                  content="Hyundai Mobility Thailand – Click to Buy"
                  id="og-sitename-value"
                />
                <meta property="og:type" content="website" id="og-type-value" />
                <meta property="og:url" content="https://buyonline.hyundai.com/th" id="og-url-value" />
                <meta property="og:title" content="Hyundai Mobility Thailand – Click to Buy" id="og-title-value" />
                <meta
                  property="og:description"
                  content=" Welcome to the official Hyundai Mobility Thailand Online Sales website. View the latest models including new Hyundai Vehicles, download a brochure, find your dealer and book a test drive."
                  id="og-description-value"
                />
                <meta
                  property="og:tags"
                  content="Hyundai Mobility Thailand, Buy a car Online, Hyundai Motor Company, Hyundai, Hyundai Motor, Click to Buy"
                  id="og-tags-value"
                />
                {/* TODO OG Tag 이미지 변경 */}
                <meta
                  property="og:image"
                  content="https://www.hyundai.com/content/dam/hyundai/id/id/images/local/HYU_Logo_Vertical_Blue_RGB.png"
                  id="og-image-value"
                />

                <GlobalStyle />
              </Head>
              {/* TODO: wrapper className 빠질 예정 - 아메바 */}
              <div className={`wrapper ctb ${showLayout ? 'ctb-models' : 'ctb-variants'}`} css={LayoutStyle}>
                {showLayout && <Header />}

                <main className="content">
                  <div className="content-container">
                    <div className="content-area">
                      {showLayout && (
                        <div className="content-top">
                          <PageTitle />
                          <div className="breadcrumb-container">
                            <div className="breadcrumb-area">
                              <div className="breadcrumb-left use-pc">
                                <DynamicNavigation />
                              </div>
                              <div className="breadcrumb-right">
                                <Location />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="content-middle">{children}</div>
                    </div>
                  </div>
                </main>
                {showLayout && (
                  <footer className="footer">
                    <Footer />
                  </footer>
                )}
              </div>
              <AgreementLayout />
              <GlobalComponents />
            </>
          )}
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default Layout;
