import Dim from '@/components/modules/dim';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { ModalWrapperProps } from '../type';

const bgClassName = 'haefe-modal-bg';

function ModalWrapper({
  open,
  closeModal,
  backDropClose,
  escClose,
  backgroundScroll = false,
  wrapperClassName,
  zIndex,
  style, //TODO: 온판용 emotion 스타일 추가
  children,
  ...otherProps
}: ModalWrapperProps) {
  const [wrapperEl, setWrapperEl] = useState<HTMLElement>();

  useEffect(() => {
    const element = document.getElementById('modal-portal');

    if (element) {
      setWrapperEl(element);
    }
  }, []);

  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (e: MouseEvent) => {
      const element = e.target as HTMLDivElement;

      if (element?.classList?.contains(bgClassName)) {
        closeModal && closeModal();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        closeModal && closeModal();
      }
    };

    backDropClose && document.addEventListener('mousedown', handleClickOutside);
    escClose && document.addEventListener('keydown', handleKeyDown);

    return () => {
      backDropClose && document.removeEventListener('mousedown', handleClickOutside);
      escClose && document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, closeModal, backDropClose, escClose]);

  if (!open || !wrapperEl) return null;

  return createPortal(
    <div
      className={classNames('haefe-modal', wrapperClassName)}
      style={{ zIndex }}
      css={[style ?? '']} //TODO: 온판용 emotion 스타일 추가
      {...otherProps}
    >
      <div className="haefe-modal-wrapper">
        <Dim className={bgClassName} openDim={open} backgroundScroll={backgroundScroll} />
        {children}
      </div>
    </div>,
    wrapperEl,
  );
}

export default ModalWrapper;
