import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Loading from '@/components/modules/Loading';
import {
  changeDealerModalPropsSelector,
  globalAlertPropsSelect,
  globalSpinnerState,
  globalToastModalPropsSelector,
} from '@/stores/common';
import AlertModal from '@/components/modules/modals/alertModal';
import ToastModal from '../modals/toastModal';
import ChangeDealerModal from '@/components/modules/changeDealerModal';
import useTokenRefresh from '@/hooks/useTokenRefresh';

function GlobalComponents() {
  useTokenRefresh();
  const isLoading = useRecoilValue(globalSpinnerState);
  const [alertProps, setAlertProps] = useRecoilState(globalAlertPropsSelect);
  const [toastProps, setToastProps] = useRecoilState(globalToastModalPropsSelector);
  const [changeDealerProps, setChangeDealerProps] = useRecoilState(changeDealerModalPropsSelector);

  const handleCloseChangeDealerModal = () => {
    if (typeof changeDealerProps.closeModal === 'function') changeDealerProps.closeModal();

    setChangeDealerProps(null);
  };

  const handleCloseToastModal = () => {
    if (typeof toastProps.closeModal === 'function') toastProps.closeModal();

    setToastProps(null);
  };

  return (
    <>
      <AlertModal
        visible={!!alertProps}
        closeModal={() => {
          setAlertProps(null);
        }}
        {...alertProps}
      />

      <ToastModal visible={!!toastProps} closeModal={handleCloseToastModal} {...toastProps}>
        {toastProps?.message}
      </ToastModal>

      <ChangeDealerModal
        isVisible={!!changeDealerProps}
        {...changeDealerProps}
        closeModal={handleCloseChangeDealerModal}
      />

      {isLoading && <Loading />}
    </>
  );
}

export default GlobalComponents;
