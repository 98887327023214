import { i18nLocaleState, i18nTenantState } from '@/stores/common/i18n';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { MENU } from '@/constants/menu';

interface ILocalePath extends Global.IMenuItem {
  query?: {
    [key: string]: unknown;
  };
}

type TMenuItems = Record<keyof Global.IMenuItems, ILocalePath>;

interface ISetLocalePathProp {
  menuList: TMenuItems;
  locale: Global.TLanguages;
  language: Global.TLanguages;
}

function useMenuLists(): TMenuItems {
  const router = useRouter();
  const i18nLanguage = useRecoilValue(i18nLocaleState);
  const i18nLocale = router.locale; //useRecoilValue(i18nTenantState);

  return setLocalePath({
    menuList: JSON.parse(JSON.stringify(MENU)),
    locale: i18nLocale,
    language: i18nLanguage,
  });
}

export default useMenuLists;

function setLocalePath({ menuList, locale, language }: ISetLocalePathProp): TMenuItems {
  const langQueryPattern = new RegExp('lang\\=');

  for (const key in menuList) {
    if ('children' in menuList[key]) {
      menuList[key].children = setLocalePath({
        menuList: menuList[key].children,
        locale,
        language,
      });
    }
    if ('path' in menuList[key] && !menuList[key].path.startsWith(`/${locale}`)) {
      menuList[key].path = `/${locale}` + menuList[key].path;
      menuList[key].pathname = menuList[key].path;
    }
    if (!langQueryPattern.test(menuList[key].path)) {
      menuList[key].path += `?lang=${language}`;
      menuList[key].query = {
        lang: language,
      };
    }
  }

  return menuList;
}
