import Tab from '@/components/haeLibraryRefactored/Tab';
import { selfSelectedDealerSelector } from '@/stores/common/user';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';
import { DEALER_BASE_TYPE } from '../../constants';

interface IChangeDealerModalHeaderProps {
  type: TDealerBaseType;
  showTab?: boolean;
  onTabChange: (key: string | number) => void;
}

function ChangeDealerModalHeader({ type, onTabChange, showTab }: IChangeDealerModalHeaderProps) {
  const { t } = useTranslation();
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  // 모달 title
  const modalTitle = selfSelectedDealer ? t('Label.ChangeDealer') : t('Label.SelectDealer');

  return (
    <>
      <div className="title">{modalTitle}</div>
      {showTab && (
        <Tab
          data={[
            { key: DEALER_BASE_TYPE.NEARBY, label: 'Nearby' },
            { key: DEALER_BASE_TYPE.FAST_DELIVERY, label: 'Fast Delivery' },
          ]}
          className="change-dealer-tab"
          activeKey={type}
          onChange={onTabChange}
        />
      )}
    </>
  );
}

export default ChangeDealerModalHeader;
