import axios from 'axios';
import Logger from './logger';

Logger.init({
  applicationName: 'buyonline',
  env: process.env.NEXT_PUBLIC_HMG_ENV,
  level: ['error'],
  hook: data => {
    const { host } = global?.location || { host: 'localhost:3000' };
    // const baseUrl = process.env.NEXT_PUBLIC_SERVICE_TYPE === 'Local' ? `http://${host}` : process.env.NEXT_PUBLIC_HOST;

    // axios.defaults.baseURL = baseUrl;
    axios.post(`/gateway/log/error`, data);
    // http.post(`/gateway/log/error`, data);
  },
});

export default Logger;
