import React from 'react';
import RegionSelectFormStyle from './style';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { Dropdown, Form } from '@components/haeLibraryRefactored';
import useFormInstance from '@/components/haeLibraryRefactored/Form/hooks/useFormInstance';
import LocationButton from '@/components/modules/locationButton';
import { QUICK_MODAL_TYPE } from '@/components/modules/quickMenu/components/modal/constants';

interface IRegionSelectFormContentsProps {
  findMyLocationSuccessCallback?: (dealerList: Dealers.TDealerEntity[]) => void;
  positionSuccessCallback?: (position: GeolocationPosition) => void;
  type?: TQuickModalType;
  disabled?: boolean;
  provinceList?: string[];
  districtList?: string[];
  subDistrictList?: string[];
  requiredFields?: string[];
}

function RegionSelectFormContents({
  findMyLocationSuccessCallback,
  positionSuccessCallback,
  type,
  disabled = false,
  provinceList = [],
  districtList = [],
  subDistrictList = [],
  requiredFields = [],
}: IRegionSelectFormContentsProps) {
  const { t } = useTranslation();
  const form = useFormInstance<IRegionFormData>();

  const { province, district } = form.getFieldsValueAll() ?? {};

  const hasRequiredField = requiredFields.length > 0;

  {
    /* {!type.includes('ioniq') && (
          <div className="form-top-box">
            <legend>
              <div className="title-box">
                <span className="title">{t('Label.Region')}</span>
                {type !== QUICK_MODAL_TYPE.CONTACT_DEALER && (
                  <span className="desc">{t('ClickToBuy.SelectYourLocation')}</span>
                )}
              </div>
            </legend>
            {type !== QUICK_MODAL_TYPE.CONTACT_DEALER && (
              <div className="location-button-box">
                <LocationButton
                  successCallback={findMyLocationSuccessCallback}
                  positionSuccessCallback={positionSuccessCallback}
                />
              </div>
            )}
          </div>
        )}
        <div className="form-top-box noti">
          <div className="required-noti-box">
            <span className="text">{t('Label.RequiredFields')}</span> */
  }

  return (
    <>
      <div className="region-selectform" css={RegionSelectFormStyle}>
        {!type?.includes('ioniq') && (
          <>
            <div className="form-top-box">
              <legend>
                <div className="title-box">
                  <span className="title">{t('Label.Region')}</span>
                  {type !== QUICK_MODAL_TYPE.CONTACT_DEALER && (
                    <span className="desc">{t('ClickToBuy.SelectYourLocation')}</span>
                  )}
                </div>
              </legend>
              {type !== QUICK_MODAL_TYPE.CONTACT_DEALER && (
                <div className="location-button-box">
                  <LocationButton
                    successCallback={findMyLocationSuccessCallback}
                    positionSuccessCallback={positionSuccessCallback}
                  />
                </div>
              )}
            </div>
            {hasRequiredField && (
              <div className="form-top-box noti">
                <div className="required-noti-box">
                  <span className="text">{t('Label.RequiredFields')}</span>
                </div>
              </div>
            )}
          </>
        )}
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <Form.Item
                className="col-3"
                name="province"
                label={t('ClickToBuy.StateProvince')}
                required={requiredFields.includes('province')}
              >
                <Dropdown
                  placeholder={t('ClickToBuy.SelectAStateProvince')}
                  items={provinceList?.map(item => ({
                    value: item,
                    label: item,
                  }))}
                  disabled={disabled || type === QUICK_MODAL_TYPE.CONTACT_DEALER}
                />
              </Form.Item>
              <Form.Item
                className="col-3"
                name="district"
                label={t('ClickToBuy.District')}
                required={requiredFields.includes('district')}
              >
                <Dropdown
                  placeholder={t('ClickToBuy.SelectACity')}
                  items={districtList?.map(item => ({
                    value: item,
                    label: item,
                  }))}
                  disabled={disabled || type === QUICK_MODAL_TYPE.CONTACT_DEALER || !province}
                />
              </Form.Item>
              <Form.Item
                className="col-3"
                name="subDistrict"
                label={t('ClickToBuy.City')}
                required={requiredFields.includes('subDistrict')}
              >
                <Dropdown
                  placeholder={t('ClickToBuy.SelectADistrict')}
                  disabled={disabled || type === QUICK_MODAL_TYPE.CONTACT_DEALER || !province || !district}
                  items={subDistrictList?.map(item => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Form.Item>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default RegionSelectFormContents;
