import { css } from '@emotion/react';
import { flex, layout } from 'styles/mixin';

const agreementBoxStyle = css`
  &.agreement-box {
    margin-top: 1.6rem;
    padding: 0 1rem;

    .agreement {
      margin-top: 1.6rem;
      &:first-of-type {
        margin-top: 0;
      }

      .haefe-checkbox-content {
        display: inline-block;
      }
    }
  }

  @media ${layout.$mobile} {
    &.agreement-box {
      padding: 0;
    }
  }
`;

export default agreementBoxStyle;
