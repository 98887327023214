import React, { useCallback, useEffect, useRef } from 'react';
import ModalWrapper from '@/components/haeLibraryRefactored/Modal/components/modalWrapper';
import Button from '@/components/units/button';
import ToastModalStyle from './style';
import Link from 'next/link';
import useMenuLists from '@/hooks/useMenuList';
import { useTranslation } from 'next-i18next';

interface ToastModalProps {
  className?: string;
  visible: boolean;
  icon: boolean;
  children: React.ReactNode;
  button: boolean;
  modalWrapperClassName?: string;
  closeModal: (...props: any) => void;
}

const ToastModal = ({
  visible,
  icon,
  children,
  button,
  modalWrapperClassName = '',
  closeModal = () => null,
}: ToastModalProps) => {
  const modalRef = useRef<any>();
  const closeButtonRef = useRef<any>();
  const menuLists = useMenuLists();
  const { t } = useTranslation();

  const handleFocusModal = useCallback((e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (!e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
      modalRef?.current?.focus();
    }
  }, []);

  const handleFocusCloseButton = useCallback((e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
      closeButtonRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (visible) {
      const timeout = window.setTimeout(() => closeModal(), 3000 * 1);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [visible]);

  return (
    <ModalWrapper
      backDropClose={false}
      open={visible}
      wrapperClassName={`toast-popup ${modalWrapperClassName}`}
      backgroundScroll={true}
    >
      <div
        className="modal-wrap-class"
        css={ToastModalStyle}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-label"
        tabIndex={0}
      >
        <div className="modal-content">
          {icon && (
            <div className="icon-box">
              <i className="ic ic-toast-popup-arrow"></i>
            </div>
          )}
          <div className="text-box">
            <p
              id="modal-label"
              ref={modalRef}
              tabIndex={0}
              onKeyDown={(e: React.KeyboardEvent<HTMLParagraphElement>) => {
                if (button) {
                  handleFocusCloseButton(e);
                } else {
                  if (e.keyCode === 9) {
                    e.preventDefault();
                  }
                }
              }}
            >
              {children}
            </p>
          </div>
          {button && (
            <div className="button-box">
              <Link href={menuLists.compare.path}>
                <Button
                  buttonType="text"
                  height="auto"
                  className="btn-text"
                  onKeyDown={handleFocusModal}
                  refProps={closeButtonRef}
                >
                  {t('Label.Compare')}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ToastModal;
