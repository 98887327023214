import { axiosInstance as axios } from '@/query/axios';
import { delay } from '@/utils/delay';

export const getEmployeeList = async (params: Users.IEmployeeListReq): Promise<Users.IEmployeeListRes[]> => {
  try {
    const response = await axios.get(`/user_api/employee`, {
      params: { salesConsultant: true, ...params },
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getUserProfile = async (): Promise<Global.IUserProfile | null> => {
  try {
    const response: { data: Global.IUserProfile } = await axios.post(`/user_api/users/me`, null, {
      needsAuth: true,
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getUserJoin = async (data: Users.IUsersJoinReq) => {
  try {
    const response = await axios.post(`/gateway/users/join`, { data });
    // const response = await axios.post(`/api/users/join`, { data });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const requestUserWithdrawal = async () => {
  try {
    const response = await axios.put(`/user_api/users/withdrawal`, null, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getUserMygarage = async (
  { activityTypes, containsCanceled }: Users.IUserMygarageParams,
  pageParam: number,
) => {
  try {
    // HACK :: 탈퇴 대기 회원 접속시 /users/me 보다 먼저 호출 되고 있어서 delay 설정함.
    await delay();

    const response = await axios.get(`/gateway/users/mygarage`, {
      params: { activityTypes, containsCanceled, size: 9, page: pageParam },
      needsAuth: true,
    });

    return response?.data
      ? {
          ...response?.data,
          current_page: pageParam,
        }
      : null;
  } catch (err: any) {
    throw err;
  }
};

export const updateUserProfile = async (params: Users.IUpdateUserReq) => {
  try {
    const response = await axios.put(`/user_api/users`, params, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const updateAssociatedDealer = async (params: Users.IUpdateAssociatedDealerReq) => {
  try {
    const response = await axios.post(`/user_api/users/associated-dealers`, params, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
