import React, { useId } from 'react';
import classNames from 'classnames';
import { DropdownItem, DropdownValue } from '../type';

interface SelectedItemProps {
  multiSelect: boolean;
  selectedItem: DropdownItem;
  selectedItemList: DropdownItem[];
  value: DropdownValue;
  placeholder: string;
  tagRender: (item: DropdownItem) => React.ReactNode;
  onTagDelete: (item: DropdownItem) => void;
}

export function SelectedItems({
  multiSelect,
  selectedItem,
  selectedItemList,
  value,
  placeholder,
  tagRender,
  onTagDelete,
}: SelectedItemProps) {
  const id = useId();

  const showPlaceholder = !(!!selectedItem?.label || !!value);

  if (!multiSelect)
    return (
      <span
        className={classNames(
          selectedItem?.label || value ? 'haefe-dropdown-current-value' : 'haefe-dropdown-placeholder',
        )}
      >
        {showPlaceholder ? placeholder : selectedItem?.label ?? value}
      </span>
    );

  if (selectedItemList?.length === 0) return <span className="haefe-dropdown-placeholder">{placeholder}</span>;
  if (!!tagRender) {
    return <>{selectedItemList.map(item => tagRender(item))}</>;
  }

  return (
    <>
      {selectedItemList?.map((item, index) => (
        <div key={`dropdown-${id}-tag-${index}`} className="haefe-dropdown-tag">
          <span className="haefe-dropdown-tag-item">
            <span>{item.label}</span>
            <button
              className="haefe-dropdown-tag-btn-delete"
              onClick={e => {
                e.stopPropagation();
                onTagDelete(item);
              }}
            >
              x
            </button>
          </span>
        </div>
      ))}
    </>
  );
}
