import React, { useEffect, useRef } from 'react';
import DimStyle from './style';
import classNames from 'classnames';
import { IDimProps } from './type';

function Dim({
  transparent = false,
  onClick,
  openDim = true,
  className,
  backgroundScroll = false,
  ...otherProps
}: IDimProps) {
  const getVisibleDim = () => {
    const dimElements = Array.from(document.body.querySelectorAll('.dim'));

    return dimElements?.reverse()?.find(item => {
      const dimStyle = window?.getComputedStyle(item);

      return dimStyle?.visibility !== 'hidden';
    });
  };

  const setBodyScroll = () => {
    const outerDim = getVisibleDim();

    document.body.style.cssText =
      outerDim?.classList.contains('backgroundScroll') || !outerDim ? 'overflow: unset' : 'overflow: hidden';
  };

  useEffect(() => {
    setBodyScroll();
    window.addEventListener('resize', setBodyScroll);

    return () => {
      setBodyScroll();
      window.removeEventListener('resize', setBodyScroll);
    };
  }, []);

  useEffect(() => {
    setBodyScroll();
  }, [openDim, backgroundScroll]);

  return (
    <div
      className={classNames('dim', className, { backgroundScroll })}
      css={DimStyle({ transparent, openDim })}
      onClick={onClick}
      {...otherProps}
    ></div>
  );
}

export default Dim;
