import { axiosInstance as axios } from '@/query/axios';

export const getAutoAssign = async (
  params: Employee.IEmployeeAutoAssignReq,
): Promise<Employee.IEmployeeAutoAssignRes> => {
  try {
    const response = await axios.get(`/user_api/employee/auto-assign`, { params, needsAuth: true });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getAutoAssignGuest = async (
  params: Employee.IEmployeeAutoAssignReq,
): Promise<Employee.IEmployeeAutoAssignRes> => {
  try {
    const response = await axios.get(`/user_api/employee/auto-assign/guest`, { params });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeDetail = async (employeeId: string | number): Promise<Employee.IEmployeeDetailRes> => {
  try {
    const response = await axios.get(`/user_api/employee/detail`, { params: { id: employeeId } });

    return response.data;
  } catch (err) {
    throw err;
  }
};
