import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { BuyonlineCookies } from '@/hooks/useCustomCookie';
import Logger from '@/utils/logger';

const cookies = new BuyonlineCookies();

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  needsAuth?: boolean;
}

interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  needsAuth?: boolean;
}
interface CustomAxiosInstance extends AxiosInstance {
  get<T = any, R = AxiosResponse<T>>(url: string, config?: CustomAxiosRequestConfig): Promise<R>;
  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig): Promise<R>;
  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig): Promise<R>;
  patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig): Promise<R>;
}

// axiosInstance (CSR에 사용)
const axiosInstance: CustomAxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    TenantCode: process.env.NEXT_PUBLIC_TENANT_CODE,
    Tenant: 'th',
    Locale: cookies.get('lang') || 'en',
  },
  // withCredentials: true,
  responseType: 'json',
});

axiosInstance.interceptors.request.use((config: CustomInternalAxiosRequestConfig) => {
  const isAuthApi = config.needsAuth ?? false;

  if (config.data === null) {
    delete config.data;
  }

  if (cookies.get('cookie_BuyOnline_th') && isAuthApi) {
    config.headers.Authorization = `Bearer ${cookies.get('cookie_BuyOnline_th')}`;
  }
  config.headers.Locale = cookies.get('lang') || config.headers.Locale || 'en';

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    Logger.api(error);

    // axios error message setting
    return Promise.reject(error);
  },
);

// ssrAxiosInstance (SSR, prefetch에 사용)
const ssrAxiosInstance: CustomAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Tenant: 'th',
    TenantCode: process.env.NEXT_PUBLIC_TENANT_CODE,
    Locale: cookies.get('lang') || 'en',
  },
  responseType: 'json',
});

ssrAxiosInstance.interceptors.request.use((config: CustomInternalAxiosRequestConfig) => {
  const isAuthApi = Boolean(config.needsAuth) ?? false;

  if (config.data === null) {
    delete config.data;
  }

  // Authorization cookie 추후 필요 시 추가
  if (!isAuthApi) {
    delete config.headers.Authorization;
  }

  if (config.url === '/user_api/codes' && config.headers?.Authorization) {
    delete config.headers.Authorization;
  }

  // react-query의 prefetch는 cookie 사용 가능하기 때문에 lang cookie 값으로 우선 사용!
  config.headers.Locale = cookies.get('lang') || config.headers.Locale || 'en';

  const customConfig = {
    ...config,
  };

  customConfig.url = config.url.replace('_api', '/front');

  return customConfig;
});

ssrAxiosInstance.interceptors.response.use(
  response => response,
  error => {
    Logger.api(error);

    return error.response.config.url === '/user/front/codes' ? error : Promise.reject(error);
  },
);

export { axiosInstance, ssrAxiosInstance };
