import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const PageTitleStyle = css`
  @media ${layout.$mobile} {
    .page-title-container {
      border-bottom: 1px solid ${colors.$color_d9d};
      .page-title-area {
        ${flex('row', 'flex-start', 'center')};
        padding: 1.6rem 2rem;
        background-color: ${colors.$color_fff};
        .title-box {
          ${font('1.8rem', 400, colors.$color_000, '2.4rem')};
        }
      }
      + .breadcrumb-container {
        border: 0;
        .breadcrumb-right {
          display: none;
        }
      }
    }
  }
`;

export default PageTitleStyle;
