import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getEmployeeList,
  getUserJoin,
  getUserMygarage,
  getUserProfile,
  requestUserWithdrawal,
  updateAssociatedDealer,
  updateUserProfile,
} from './api';

export const useEmployeeList = (params: Users.IEmployeeListReq) => {
  return useQuery<Users.IEmployeeListRes[], Error>(['GET_EMPLOYEELIST', params], () => getEmployeeList(params), {
    enabled: !!params?.dealerId,
  });
};

export const useUserWithdrawal = () => {
  return useMutation(() => requestUserWithdrawal());
};

export const useUserProfile = (options?: any) => {
  return useMutation(() => getUserProfile(), options);
};

export const useUserJoin = (options: any) => {
  return useMutation((params: Users.IUsersJoinReq) => getUserJoin(params), options);
};

export const useUserMygarage = (params: Users.IUserMygarageParams) => {
  //return useQuery(['GET_USER_MYGARAGE', params], () => getUserMygarage(params));

  return useInfiniteQuery(['GET_USER_MYGARAGE', params], ({ pageParam = 0 }) => getUserMygarage(params, pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.last ? undefined : lastPage.current_page + 1;
    },
    keepPreviousData: true,
  });
};

export const useUpdateUserProfile = () => {
  return useMutation((params: Users.IUpdateUserReq) => updateUserProfile(params));
};

export const useUpdateAssociatedDealer = () => {
  return useMutation((params: Users.IUpdateAssociatedDealerReq) => updateAssociatedDealer(params));
};
