import { css } from '@emotion/react';
import { colors, flex, font, layout } from './mixin';

const buttonStyle = css`
  .btn {
    &.h56 {
      width: 36rem;
      height: 5.6rem;
    }
    &.h48 {
      width: 32rem;
      height: 4.8rem;
    }
    &.h40 {
      width: 20rem;
      height: 4rem;
    }
    &.h32 {
      width: 8rem;
      height: 3.2rem;
    }
    &.btn-primary {
      background-color: ${colors.$primary};
      ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};
      &:disabled {
        background-color: ${colors.$color_999};
        color: ${colors.$color_ccc};
        &:hover {
          background-color: ${colors.$color_999};
        }
      }
      &:hover {
        background-color: #0b2451;
      }
    }
    &.btn-secondary {
      border: 1px solid ${colors.$primary};
      background-color: ${colors.$color_fff};
      ${font('1.6rem', 400, '#0e306c', '2.4rem')};
      &:disabled {
        border-color: ${colors.$color_999};
        color: ${colors.$color_999};
      }
    }
    &.btn-secondary-2 {
      border: 1px solid ${colors.$color_e4e};
      background-color: ${colors.$color_fff};
      ${font('1.6rem', 400, colors.$color_767, '2.4rem')};
      &:disabled {
        border-color: ${colors.$color_ddd};
        color: ${colors.$color_ddd};
        &:hover {
          border-color: ${colors.$color_ddd};
          color: ${colors.$color_ddd};
        }
      }
      &:hover {
        border-color: ${colors.$color_ccc};
      }
    }
    &.btn-tertiary {
      border: 1px solid ${colors.$color_d9d};
      background-color: ${colors.$color_fff};
      ${font('1.6rem', 400, colors.$color_000, '2.4rem')};
      &:disabled {
        border-color: ${colors.$color_d9d};
        color: ${colors.$color_d9d};
        &:hover {
          box-shadow: none;
        }
      }
      &:hover {
        box-shadow: 0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
      }
    }
    &.btn-line {
      ${flex('row', 'center', 'center')};
      background-color: ${colors.$line};
      ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};
      &:disabled {
        background-color: ${colors.$color_767};
        color: ${colors.$color_999};
        .ic-line-pay {
          background: asset-image('ic_line_pay_dis.svg') no-repeat center / 7.6rem 2.2rem;
        }
      }

      .ic-line {
        margin-right: 0.8rem;
      }
      .ic-line-pay {
        margin-left: 0.8rem;
      }
    }

    &.btn-text {
      ${font('1.3rem', 400, colors.$primary, '2rem')};
      text-decoration: underline;
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default buttonStyle;
