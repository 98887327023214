import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { STATUS_CLASS } from '../../constants/status';
import { InputTextAreaProps } from '../type';
import InputCount from './inputCount';

function InputTextarea({
  onChange,
  value,
  allowClear = true,
  disabled = false,
  onKeyDown,
  status = null,
  maxByte,
  maxLength,
  showCount,
  refProp,
  clearInputValue,
  ...otherProps
}: InputTextAreaProps) {
  const [focus, setFocus] = useState<boolean>(false);
  const hasMax = maxByte > 0 || maxLength > 0;
  const isByte = !!maxByte ? true : false;
  const maxSize = isByte ? maxByte : maxLength;
  const statusClassName = STATUS_CLASS[status] ?? null;

  return (
    <div
      className={classNames(
        'haefe-textarea-wrapper',
        { 'haefe-textarea-wrapper-focus': focus },
        { 'haefe-textarea-wrapper-disabled': disabled },
      )}
    >
      <textarea
        onFocus={e => {
          setFocus(true);
          otherProps?.onFocus && otherProps?.onFocus(e);
        }}
        onBlur={e => {
          setFocus(false);
          otherProps?.onBlur && otherProps?.onBlur(e);
        }}
        ref={refProp}
        className={classNames(statusClassName ?? null, 'haefe-textarea')}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        {...otherProps}
      />
      {allowClear && !!value?.length && (
        <button className="haefe-textarea-clear-btn" onClick={clearInputValue}>
          <i className="haefe-textarea-clear-icon" />
        </button>
      )}
      {showCount && hasMax && <InputCount isByte={isByte} value={value} maxSize={maxSize} type={'textarea'} />}
    </div>
  );
}

export default InputTextarea;
