import React, { useEffect, useState } from 'react';
import { userSelector } from '@/stores/common/user';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MENU } from '@/constants/menu';
import TagManager from 'react-gtm-module';
import Script from 'next/script';
import { i18nLocaleState, i18nTenantState } from '@/stores/common/i18n';
import useMember from '@/hooks/useIsMeber';
import useLogout from '@/hooks/useLogout';
import { isNull } from 'lodash-es';
import { gaAttributeName } from '@/constants/common';

export function GATracker() {
  const router = useRouter();
  const [initialized, setInitialized] = useState(false);

  const userStore = useRecoilValue(userSelector);

  function findCurrentMenu(url: string) {
    const pathNameList = url
      .split(/[?/]/)
      .filter((item: string) => item !== '' && item !== 'th' && !item.includes('lang='));

    let menu = 'Cl!ick to Buy: Main'; //TODO: ctb 홈 menu 'Cl!ck to Buy'로 넣음

    if (pathNameList.length && MENU[pathNameList[0]]?.name) {
      menu = MENU[pathNameList[0]]?.name; // 대메뉴만
    }

    return menu;
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
        dataLayer: {
          page: {
            page_path: window.location.href,
            menu_nm: findCurrentMenu(window.location.pathname),
          },
          user: {
            userId: userStore?.profile?.username ?? 'anonymous',
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!initialized) return;

    function handleRouteChange(url: string) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
        dataLayer: {
          page: {
            page_path: process.env.NEXT_PUBLIC_HOST + url,
            menu_nm: findCurrentMenu(url),
          },
          user: {
            userId: userStore?.profile?.username ?? 'anonymous',
          },
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [initialized, router.events]);

  if (!initialized) return null;

  return <></>;
}

/**
 * GAScript의 전역변수는 정적스크립트로 최초 값으로 셋팅
 */
export function GAScript() {
  const i18nLanguage = useRecoilValue(i18nLocaleState);
  const i18nTenant = useRecoilValue(i18nTenantState);

  const HMG_COMMON_TAG = JSON.stringify({
    login_status: 'N',
    page_type: 'prd',
    country_code: i18nTenant,
    language_code: i18nLanguage || 'en',
  });

  return (
    <Script id="google-analytics" strategy="afterInteractive">
      {`var HMG_COMMON_TAG= HMG_COMMON_TAG||{};
        HMG_COMMON_TAG = ${HMG_COMMON_TAG};
          `}
    </Script>
  );
}
