import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const changeDealerModalStyle = css`
  &.modal-change-dealer {
    &.modal-inner {
      .modal-area {
        .modal-top.modal-header {
          padding-bottom: 0;
        }
      }
    }

    .region-selectform {
      padding: 0;
      border-top: none;
    }

    .change-dealer-tab {
      width: 100%;

      .swiper-wrapper {
        justify-content: center;
      }

      .haefe-tab-item {
        width: 50%;
        margin: 0 !important;
      }
    }

    &.modal-inner {
      .modal-area {
        .modal-top.modal-header {
          // border-bottom: none;
        }
      }
    }

    .modal-title {
      width: 100%;
      text-align: center;
      .title {
        padding-bottom: 2.4rem;
      }
    }

    .modal-middle {
      .form-top-box {
        ${flex('row', 'space-between', 'flex-start')};
        .title-box {
          .desc {
            text-align: left;
          }
        }
      }
    }

    form {
      &:first-of-type {
        .form-top-box {
          margin: 0;
        }
      }
    }
  }

  .dealer-block-button {
    width: 100%;
    margin-top: 1.6rem;
    border: 1px solid #dddddd;
    &.selected {
      .dealer-sc {
        .dealer-block {
          padding: 1.5rem 0 0 0;

          .info {
            ${font('1.3rem', 400, colors.$color_767, '2rem')};
          }
        }
      }
    }
  }

  legend {
    .title-box {
      ${flex('column', 'flex-start', 'flex-start')};
      ${font('1.6rem', 500, colors.$color_000, '2.4rem')};
    }
    .desc {
      margin-top: 0.8rem;
      ${font('1.4rem', 400, colors.$color_767, '2rem')};
    }
  }

  .field-box {
    ${flex('row', 'space-between', 'flex-end')};
    flex: 1;
    margin-top: 3.2rem;
  }

  .field {
    width: 100%;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }

    &.required {
      .field-label {
        &::after {
          content: '*';
          padding-left: 0.4rem;
          color: ${colors.$secondary1};
        }
      }
    }

    label {
      ${font('1.3rem', 400, colors.$color_000, '2rem')};

      & + .field-form {
        margin-top: 0.8rem;

        .radio,
        .checkbox {
          margin-top: 0.8rem;
        }
      }
    }
  }

  @media ${layout.$tablet} {
    &.modal-change-dealer.modal-inner {
      .modal-area {
        .modal-top.modal-header {
          .modal-closeButton.btn-close {
            position: absolute;
            // right: 2rem;
            // top: 1.6rem;
          }
        }
      }
    }
  }
  @media ${layout.$mobile} {
    &.modal-change-dealer.modal-inner {
      .modal-area {
        .modal-top.modal-header {
          border-bottom: 1px solid #ededed;
          padding-bottom: 0;
          .modal-title {
            text-align: left;
            .title {
              position: relative;

              &::before {
                position: absolute;
                width: calc(100% + 3.2rem);
                content: '';
                border-top: 1px solid #ededed;
                bottom: 0;
                left: -1.6rem;
              }
            }

            .change-dealer-tab {
              margin-top: 1rem;
            }
          }

          .modal-closeButton.btn-close {
            position: absolute;
            right: 2rem;
            top: 1.6rem;
          }
        }
      }
    }
  }
`;

export default changeDealerModalStyle;
