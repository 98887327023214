import { useEffect } from 'react';

interface ICustomScrollProps {
  handleScroll: (isActive: boolean) => void;
  threshold: number;
  deps?: any;
  isOnce?: boolean;
  condition?: boolean;
}

const useCustomScroll = ({
  handleScroll,
  threshold = 10,
  deps,
  isOnce = true,
  condition = true,
}: ICustomScrollProps) => {
  useEffect(() => {
    if (condition === false) return;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      const gap = Math.abs(window.innerHeight - document.documentElement.scrollHeight);
      //const newThreshold = threshold > gap ? gap : threshold;

      if (Math.abs(scrollY - lastScrollY) < 10) {
        ticking = false;

        return;
      }

      const bool = scrollY >= (isOnce ? threshold : lastScrollY);

      if (deps !== bool) handleScroll(bool);

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [deps, condition]);
};

export default useCustomScroll;
