export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
export const NODE_ENV = process.env.NODE_ENV;
export const WWN_HOST = process.env.NEXT_PUBLIC_WWN_HOST;
export const HMG_ENV = process.env.NEXT_PUBLIC_HMG_ENV;

export function routePath(api_path: { path: string }) {
  return API_HOST + api_path.path;
}

export function isDevelopment() {
  return NODE_ENV === 'development';
}
export function isProduction() {
  return NODE_ENV === 'production';
}

export function isMock() {
  return process.env.npm_lifecycle_event === 'mock';
}

export function isProdOffConsole(message: any) {
  // remove console.log = PROD env
  if (HMG_ENV === 'PROD') {
    console.log = function () {
      return null;
    };
  } else {
    console.log(`::${HMG_ENV}:: `, message);
  }
}
