import { css } from '@emotion/react';

const fontStyle = css`
  /* webFont Insert */
  @font-face {
    font-family: 'HyundaiSansHeadKR';
    font-weight: 700;
    src: local('HyundaiHeadBold'), url('/fonts/HyundaiHeadBold.eot'),
      url('/fonts/HyundaiHeadBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiHeadBold.woff') format('woff');
  }

  @font-face {
    font-family: 'HyundaiSansHeadKR';
    font-weight: 500;
    src: local('HyundaiHeadMedium'), url('/fonts/HyundaiHeadMedium.eot'),
      url('/fonts/HyundaiHeadMedium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiHeadMedium.woff') format('woff'), url('/fonts/HyundaiHeadMedium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'HyundaiSansHeadKR';
    font-weight: 400;
    src: local('HyundaiHeadReg'), url('/fonts/HyundaiHeadReg.eot'),
      url('/fonts/HyundaiHeadReg.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiHeadReg.woff') format('woff'), url('/fonts/HyundaiHeadReg.woff2') format('woff2');
  }

  @font-face {
    font-family: 'HyundaiSansHeadKR';
    font-weight: 100;
    src: local('HyundaiHeadLight'), url('/fonts/HyundaiHeadLight.eot'),
      url('/fonts/HyundaiHeadLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiHeadLight.woff') format('woff'), url('/fonts/HyundaiHeadLight.woff2') format('woff2');
  }

  @font-face {
    font-family: 'HyundaiSansTextKR';
    font-weight: 700;
    src: local('HyundaiTextBold'), url('/fonts/HyundaiTextBold.eot'),
      url('/fonts/HyundaiTextBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiTextBold.woff') format('woff'), url('/fonts/HyundaiTextBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'HyundaiSansTextKR';
    font-weight: 500;
    src: local('HyundaiTextMedium'), url('/fonts/HyundaiTextMedium.eot'),
      url('/fonts/HyundaiTextMedium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiTextMedium.woff') format('woff'), url('/fonts/HyundaiTextMedium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'HyundaiSansTextKR';
    font-weight: 400;
    src: local('HyundaiTextReg'), url('/fonts/HyundaiTextReg.eot'),
      url('/fonts/HyundaiTextReg.eot?#iefix') format('embedded-opentype'),
      url('/fonts/HyundaiTextReg.woff') format('woff'), url('/fonts/HyundaiTextReg.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Kanit';
    font-weight: 300;
    font-style: normal;
    src: local('kanit-light-webfont'), url('/kanit-light-webfont.eot'),
      url('/fonts/kanit/kanit-light-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/kanit/kanit-light-webfont.woff') format('woff'),
      url('/fonts/kanit/kanit-light-webfont.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Kanit';
    font-weight: 500;
    font-style: normal;
    src: local('kanit-light'), url('/fonts/kanit/Kanit-Medium.eot'),
      url('/fonts/kanit/Kanit-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/kanit/Kanit-Medium.woff') format('woff'),
      url('/fonts/kanit/kanit-light-webfont.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Kanit';
    font-weight: 400;
    font-style: normal;
    src: local('kanit-Regular'), url('/fonts/kanit/Kanit-Regular.eot'),
      url('/fonts/kanit/Kanit-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/kanit/Kanit-Regular.woff') format('woff'), url('/fonts/kanit/Kanit-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Kanit';
    font-weight: 700;
    font-style: normal;
    src: local('kanit-Bold'), url('/fonts/kanit/Kanit-Bold.eot'),
      url('/fonts/kanit/Kanit-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/kanit/Kanit-Bold.woff') format('woff'), url('/fonts/kanit/Kanit-Bold.woff2') format('woff2');
  }

  /* webFont Insert */
`;

export default fontStyle;
