import { css } from '@emotion/react';

// Color Palette
export const colors = {
  bgA10: '#000000',
  bgA20: '#181818',

  $primary: '#002C5F',
  $secondary1: '#00AAD2',
  $secondary2: '#E63312',

  $primaryBlack: '#09141E',

  $color_000: '#000000',
  $color_1B1: '#1B1B1B',
  $color_333: '#333333',
  $color_767: '#767676',
  $color_666: '#666666',
  $color_888: '#888888',
  $color_999: '#999999',
  $color_ccc: '#cccccc',
  $color_ddd: '#dddddd',
  $color_d9d: '#d9d9d9',
  $color_ede: '#ededed',
  $color_e1e: '#e1e1e1',
  $color_e4e: '#e4e4e4',
  $color_f1f: '#f1f1f1',
  $color_f4f: '#f4f4f4',
  $color_f7f: '#f7f7f7',
  $color_f8f: '#f8f8f8',
  $color_fff: '#ffffff',
  $color_cbced1: '#cbced1',
  $color_ced0d2: '#ced0d2',
  $color_9FA5A9: '#9FA5A9',
  $color_264C77: '#264C77',

  $color_0A_6: 'rgba(0, 0, 0, 0.6)',
  $color_0A_4: 'rgba(0, 0, 0, 0.4)',
  $color_0A_3: 'rgba(0, 0, 0, 0.3)',
  $color_0A_0: 'rgba(0, 0, 0, 0)',

  $line: '#08BF5B',
};

export const layout = {
  // Layout
  $content_size: '112rem',

  // Responsive
  $tablet: 'screen and (max-width: 1120px)',
  $mobile: 'screen and (max-width: 768px)',
};

export const ellipsis = () => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
};

// 여러 줄(멀티 라인) 말줄임
// @param $toggle-bool - 텍스트 생략 여부
// @param $line-number - 노출할 텍스트의 줄 수

export const multiEllipsis = (toggleBool: boolean, lineNumber: number) => {
  return css`
    ${toggleBool
      ? `
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: ${lineNumber};
    -webkit-box-orient: vertical;
    `
      : `
    display: inherit;
    overflow: visible;
    -webkit-line-clamp: none;
    -webkit-box-orient: unset;
    `}
  `;
};
export const font = (size: string | number, weight: number, color: string, height: string | number = 'normal') => {
  return css`
    font-size: ${size};
    font-weight: ${weight};
    color: ${color};
    line-height: ${height};
  `;
};
export const flex = (direction = 'row', justify = 'flex-start', align = 'flex-start', flexType = '') => {
  return css`
    ${flexType === 'inline-flex'
      ? `
      display: inline-flex;`
      : `display: -webkit-box;
    display: -ms-flexbox;
    display: flex;`}
    ${direction === 'row' &&
    `
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    `}
    ${direction === 'row-reverse' &&
    `
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    `}
    ${direction === 'column' &&
    `
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    `}   
    ${direction === 'column-reverse' &&
    `
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    `}   
    /* set justify-content */
    ${justify === 'flex-start' &&
    `
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    `} 
    ${justify === 'flex-end' &&
    `
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    `} 
    ${justify === 'center' &&
    `
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    `} 
    ${justify === 'space-between' &&
    `
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    `} 
    ${justify === 'space-around' &&
    `
    -webkit-box-pack: distribute;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    `} 
    /* set align-items */
    ${align === 'flex-start' &&
    `
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    `} 
    ${align === 'flex-end' &&
    `
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    `} 
    ${align === 'center' &&
    `
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    `} 
    ${align === 'stretch' &&
    `
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    `} 
    ${align === 'baseline' &&
    `
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    `}
  `;
};

export function flexGrow(grow: string) {
  return css`
    -webkit-box-flex: ${grow};
    -ms-flex: ${grow};
    flex-grow: ${grow};
  `;
}

export function clearfix() {
  return css`
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  `;
}

export function rm_scroll() {
  return css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  `;
}
