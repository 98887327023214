import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const ToastModalStyle = css`
  width: 100% !important;

  .modal-content {
    ${flex('row', 'center', 'center')};
    padding: 1.4rem 0;
    .icon-box {
      margin-right: 0.8rem;
    }
    .text-box {
      p {
        ${font('1.4rem', 400, `${colors.$color_fff} !important`, '2rem')};
        text-align: center;
      }
    }
    .button-box {
      margin-left: 4rem;
      .btn-text {
        color: ${colors.$secondary1};
      }
    }
  }
`;

export default ToastModalStyle;
