const getCharByteSize = (ch: string) => {
  if (ch === null || ch.length === 0) {
    return 0;
  }

  const charCode = ch.charCodeAt(0);

  if (charCode <= 0x00007f) {
    return 1;
  } else if (charCode <= 0x0007ff) {
    return 2;
  } else if (charCode <= 0x00ffff) {
    return 4; //기존 3바이트로 처리하던 범위(특히 한글)이 서버 측 인코딩 타입으로 인해서 4바이트로 처리해 달라는 요청이 있어서 수정. 추후 다시 변경 사항 있을 경우 대비해 조건 삭제처리는 하지 않음.
  } else {
    return 4;
  }
};

export default getCharByteSize;
