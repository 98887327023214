import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Form, Checkbox } from '@components/haeLibraryRefactored';
import agreementBoxStyle from './style';

interface IAgreementBoxProps<T = any> {
  agreeKeys: ('policy' | 'receive' | 'booking' | 'offline')[];
  handleChange: (value: T) => void;
}

function AgreementBox({
  agreeKeys = ['policy', 'receive', 'booking', 'offline'],
  handleChange: handleChangeProps,
}: IAgreementBoxProps) {
  const { t } = useTranslation();
  const router = useRouter();

  const form = Form.useForm();

  useEffect(() => {
    return () => {
      form.setFieldsValue(null);
    };
  }, []);

  const agreementItem = {
    policy: (
      <div className="agreement">
        <Form.Item name="policy">
          <Checkbox
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('Agreement.IAcceptTheTerms', {
                    termsLink: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/utility/terms-conditions`, //TODO: 영문 주소 나오면 처리
                    policyLink: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/utility/privacy-policy`,
                  }),
                }}
              />
            }
          />
        </Form.Item>
      </div>
    ),
    receive: (
      <div className="agreement">
        <Form.Item name="receive">
          <Checkbox label={t('Agreement.IAcceptToReceiveAboutProducts')} />
        </Form.Item>
      </div>
    ),
    booking: (
      <div className="agreement">
        <Form.Item name="booking">
          <Checkbox
            label={
              <>
                {t('Agreement.AgreementBooking')}
                <a href="#" className="view-detail">
                  {t('Label.ViewDetail')}
                </a>
              </>
            }
          />
        </Form.Item>
      </div>
    ),
    offline: (
      <div className="agreement">
        <Form.Item name="offline">
          <Checkbox label={<>{t('Agreement.AgreementOfflineSync')}</>} />
        </Form.Item>
      </div>
    ),
  };

  const handleChange = (changedValue: any, mountedValues: any, allValues: any) => {
    // true 키만 반환
    const data: { [key: string]: boolean } = {};

    Object.keys(mountedValues)?.forEach((key: string) => {
      if (mountedValues?.[key]) data[key] = true;
    });

    handleChangeProps(data);
  };

  return (
    <>
      <>
        <div className="required-noti-box">
          <span className="text">{t('Label.Required')}</span>
        </div>
        <div className="agreement-box" css={agreementBoxStyle}>
          <Form form={form} onChange={handleChange}>
            {agreeKeys.map(item => (
              <React.Fragment key={item}>{agreementItem[item]}</React.Fragment>
            ))}
          </Form>
        </div>
      </>
    </>
  );
}

export default AgreementBox;
