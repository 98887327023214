import React, { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useGetDealersWithLatLng } from '@/query/dealers/query';
import AlertModal from '../modals/alertModal';
import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';
import QuickMenuModalContext from '../quickMenu/components/modal/context';
import { FOR_SERVICE_APPOINTMENT_TYPE, QUICK_MODAL_TYPE } from '../quickMenu/components/modal/constants';
import { DEALER_RETAIL_TYPES } from '@/query/dealers/constants';

interface ILocationButtonProps {
  successCallback?: (data: Dealers.IDealersSearchRes[]) => void;
  positionSuccessCallback?: (position: GeolocationPosition) => void;
}

function LocationButton({ successCallback, positionSuccessCallback }: ILocationButtonProps) {
  const { t } = useTranslation();
  const { modalType } = useContext(QuickMenuModalContext);
  const { mutate: getDealerWithLatLng } = useGetDealersWithLatLng();
  const [alertModalState, setAlertModalState] = useState<{ visible: boolean; text?: string }>({ visible: false });

  const handleClick = () => {
    const successCallbackFunc = async (position: GeolocationPosition) => {
      const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
      // const latlng = { lat: 13.822665845529288, lng: 100.42838916540838 };

      await getDealerWithLatLng(
        {
          latitude: latlng.lat,
          longitude: latlng.lng,
          limit: 5,
          excludeDealershipRetailType:
            modalType === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? [DEALER_RETAIL_TYPES.AGENCY_DEALER] : null,
          dealerBusinessType:
            modalType === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? FOR_SERVICE_APPOINTMENT_TYPE.DEALER_BUSINESS : null,
        },
        {
          onSuccess: typeof successCallback === 'function' ? successCallback : null,
        },
      );
    };

    const errorCallbackFunc = (error: GeolocationPositionError) => {
      if (error.code === error.PERMISSION_DENIED) {
        setAlertModalState({ visible: true, text: t('Alert.AllowTheUseOfLocationInformation') });
      }
    };

    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      if (typeof positionSuccessCallback === 'function') positionSuccessCallback(position);

      successCallbackFunc(position);
    }, errorCallbackFunc);
  };

  return (
    <>
      <AlertModal
        closeModal={() => setAlertModalState({ visible: false })}
        visible={alertModalState?.visible}
        text={alertModalState?.text ?? ''}
      />
      <button
        type="button"
        className="btn-use-location"
        onClick={handleClick}
        css={css`
          &.btn-use-location {
            ${flex('row', 'center', 'center')};
            ${font('1.4rem', 400, colors.$color_000, '2.2rem')};
            .ic-location-b {
              margin-right: 0.4rem;
            }
            span {
              text-decoration-line: underline;
              text-underline-position: under;
            }
          }
        `}
      >
        <i className="ic ic-location-b"></i>
        <span>{t('Label.UseMyLocation')}</span>
      </button>
    </>
  );
}

export default LocationButton;
