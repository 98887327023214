import React from 'react';
import FooterStyle from './style';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FOOTER_LINK } from '@/constants/footer';

const Footer = () => {
  const router = useRouter();

  const { t } = useTranslation();

  return (
    <>
      {/* <div className="footer-container" css={FooterStyle}>
        <div className="footer-area">
          <div className="footer-top">
            <Link href="" className="footer-logo-box">
              <span className="logo">
                <Image src="/assets/images/hyundai-logo_w.svg" width={150} height={21} alt="" />
              </span>
            </Link>
          </div>
          <div className="footer-middle">
            <div className="footer-middle-box">
              <div className="footer-info-box">
                <i className="ic ic-location-ft"></i>
                <div className="info-text-box">
                  <span className="text">
                    12, Heolleung-ro, Seocho-gu, Seoul,
                    <br />
                    Republic of Korea, 06797
                  </span>
                </div>
              </div>
              <div className="footer-info-box">
                <i className="ic ic-call"></i>
                <div className="info-text-box">
                  <span className="text">+82 ) 000 - 0000</span>
                  <span className="text">+82 ) 1123 - 23451</span>
                </div>
              </div>
              <div className="footer-info-box">
                <i className="ic ic-email"></i>
                <div className="info-text-box">
                  <span className="text">Hyundai8282@hyundai.com</span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="top-box">
              <Link href="" className="link">
                <span>Privacy Policy</span>
              </Link>
              <Link href="" className="link">
                <span>Terms of Use</span>
              </Link>
            </div>
            <div className="middle-box">
              <Link href="" className="sns-box">
                <i className="ic ic-facebook"></i>
              </Link>
              <Link href="" className="sns-box">
                <i className="ic ic-google"></i>
              </Link>
              <Link href="" className="sns-box">
                <i className="ic ic-twitter"></i>
              </Link>
              <Link href="" className="sns-box">
                <i className="ic ic-linkedin"></i>
              </Link>
              <Link href="" className="sns-box">
                <i className="ic ic-instagram"></i>
              </Link>
              <Link href="" className="sns-box">
                <i className="ic ic-dribble"></i>
              </Link>
            </div>
            <div className="bottom-box">
              <span>ⓒ Copyright 2022 Hyundai Motor Company. All rights reserved</span>
            </div>
          </div>
        </div>
      </div> */}
      <div className="footerWrap" css={FooterStyle}>
        <div className="footerArea">
          <p className="logo">
            <Link
              href={{
                pathname: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}`,
              }}
              target="_blank"
            >
              <i className="ic ic-logo"></i>
            </Link>
          </p>
          <div className="footerMenu">
            {/* TODO: href 수정 */}
            <ul>
              <li>
                <Link href={`https://www.hyundai.com/worldwide`} target="_blank">
                  {t('Footer.Worldwide')}
                </Link>
              </li>
              {FOOTER_LINK.map((item, index) => (
                <li key={`footerMenu_${index}`}>
                  {/* TODO: WWN 영어 버전 오픈 시 lang 값 변경 필요 */}
                  <Link
                    href={{
                      pathname: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/${item.gnbMenu}`,
                    }}
                    target="_blank"
                  >
                    {t(item.key)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* TODO: href 수정 */}
          <div className="snsShare">
            {/* <ul>
              <li>
                <Link href="" className="sns-box">
                  <i className="ic ic-facebook"></i>
                </Link>
              </li>
              <li>
                <Link href="" className="sns-box">
                  <i className="ic ic-youtube"></i>
                </Link>
              </li>
              <li>
                <Link href="" className="sns-box">
                  <i className="ic ic-twitter"></i>
                </Link>
              </li>
              <li>
                <Link href="" className="sns-box">
                  <i className="ic ic-instagram"></i>
                </Link>
              </li>
            </ul> */}
          </div>
          <div className="tabC">
            <p className="copyright">{t('Footer.Copyright')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
