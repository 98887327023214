/**
 * 특정시간동안 발생된 이벤트를 한번에 처리 하기 위한 함수
 * @param callback 특정 시간이 지나서 실행할 함수
 * @param timer ms
 * @returns 특정 시간동안 발생된 이벤트 저장
 */
function useLazyCallback<T>(callback: (store: T[]) => void, timer = 300): (item: T) => void {
  let tid: any = 0;
  let store: T[] = [];

  return (item: T) => {
    store.push(item);
    if (tid) {
      clearTimeout(tid);
    }
    tid = setTimeout(() => {
      callback && callback(store);
      clearTimeout(tid);
      store = [];
    }, timer);
  };
}
export default useLazyCallback;
