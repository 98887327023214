import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { STATUS_CLASS } from '../constants/status';
import { RangeSliderProps } from './type';
import { getValidRangeValueBase } from './utils/getValidRangeValueBase';
import { getPxPositionBase } from './utils/getPxPositionBase';
import { getPositionFromEvent } from './utils/getPositionFromEvent';
import { isFloat32Array } from 'util/types';

function RangeSlider({
  value,
  defaultValue,
  onChange,
  onSlide,
  status,
  range = true,
  min = 0,
  max = 100,
  step = 1,
  displayMinMax = false,
  tooltip = false,
  unit = '',
}: RangeSliderProps) {
  const trackRef = useRef(null);
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const [minValue, setMinValue] = useState<number>(min);
  const [maxValue, setMaxValue] = useState<number>(max);
  const [leftValue, setLeftValue] = useState<number>(
    Array.isArray(defaultValue) ? Math.max(defaultValue?.[0] ?? min, min) : min,
  );
  const [rightValue, setRightValue] = useState<number>(
    Array.isArray(defaultValue) ? Math.min(defaultValue?.[1] ?? max, max) : Math.min(defaultValue ?? max, max),
  );
  const [trackWidth, setTrackWidth] = useState<number>(trackRef?.current?.getBoundingClientRect()?.width);
  const [isLeftDragging, setIsLeftDragging] = useState<boolean>(false);
  const [isRightDragging, setIsRightDragging] = useState<boolean>(false);

  const [dragStartValue, setDragStartValue] = useState<[number, number]>([leftValue, rightValue]);

  const valueRange = maxValue - minValue;
  const widthPerValue = trackWidth / valueRange;
  const widthPerStep = widthPerValue * step;
  const valuePerPx = valueRange / trackWidth;

  const getValidRangeValue = (value: number) => getValidRangeValueBase(value, valueRange, step, min, max);
  const pxToValue = (px: number) => px * valuePerPx + min;

  const getPxPosition = (position: number, isLeftChange: boolean, isRightChange: boolean) =>
    getPxPositionBase(
      position,
      isLeftChange,
      isRightChange,
      trackWidth,
      validLeftPercent,
      validRightPercent,
      widthPerStep,
      range,
    );

  const checkValueChanged = (value: number | [number, number]) => {
    if (Array.isArray(value)) return leftValue !== value[0] || rightValue !== value[1];
    else return rightValue !== value;
  };

  const getValidRangeValueFromPx = (newLeftPxValue: number, newRightPxValue: number) => {
    const [newLeftValue, newRightValue] = [
      getValidRangeValue(pxToValue(newLeftPxValue)),
      getValidRangeValue(pxToValue(newRightPxValue)),
    ];

    const newValue: number | [number, number] = range ? [newLeftValue, newRightValue] : newRightValue;

    return newValue;
  };

  const setNewRangeValue = (value: number | [number, number]) => {
    if (Array.isArray(value)) {
      setLeftValue(value[0]);
      setRightValue(value[1]);
    } else if (Number.isInteger(value)) {
      setRightValue(value);
    }
  };

  const validRangeValue = useMemo(() => {
    if (!value || isLeftDragging || isRightDragging) return [leftValue, rightValue];

    if (range && Array.isArray(value)) {
      const higherValue = Math.max(value[0], value[1]);
      const lowerValue = Math.min(value[0], value[1]);
      const newLeftValue = getValidRangeValue(lowerValue);
      const newRightValue = getValidRangeValue(higherValue);

      return [newLeftValue, newRightValue];
    } else if (!range && !Array.isArray(value)) {
      return [min, value];
    }

    return [min, max];
  }, [value, leftValue, rightValue, minValue, maxValue, step, max, min]);

  const [validLeftValue, validRightValue] = validRangeValue;
  const valueToPercent = (value: number) => ((value - min) / valueRange) * 100;
  const validLeftPercent = valueToPercent(validLeftValue);
  const validRightPercent = valueToPercent(validRightValue);

  const runOnChange = (newRangeValue: number | [number, number]) => {
    if (typeof onChange !== 'function') return;

    if (range) (onChange as (value: [number, number]) => void)(newRangeValue as [number, number]);
    else (onChange as (value: number) => void)(newRangeValue as number);
  };

  const handleClickRange = (e: MouseEvent) => {
    const { pageX } = getPositionFromEvent(e);
    const trackLeft = trackRef.current.getBoundingClientRect().left;
    const middleValue = (leftValue + rightValue) / 2;
    const newPosition = pageX - trackLeft;
    const clickValue = getValidRangeValue(pxToValue(newPosition));
    const isRightChange = !range || clickValue > middleValue;
    const [newLeftValue, newRightValue] = isRightChange ? [leftValue, clickValue] : [clickValue, rightValue];
    const newRangeValue: number | [number, number] = range ? [newLeftValue, newRightValue] : newRightValue;

    if (!checkValueChanged(newRangeValue)) return;

    setNewRangeValue(newRangeValue);

    runOnChange(newRangeValue);
  };

  const handleDragStart = (
    setDraggingState: React.Dispatch<React.SetStateAction<boolean>>,
    e?: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    const isRightButton = e && 'button' in e && e?.button === 2;

    setDragStartValue([leftValue, rightValue]);

    if (isRightButton) return;

    setDraggingState(true);
  };

  const statusClassName = STATUS_CLASS[status] ?? null;

  useEffect(() => {
    if (!isLeftDragging && !isRightDragging) return;

    const trackLeft = trackRef.current.getBoundingClientRect().left;

    const handleDrag = (e: globalThis.MouseEvent | TouchEvent) => {
      if (!isLeftDragging && !isRightDragging) return;

      const { pageX, pageY } = getPositionFromEvent(e);

      if (!document?.elementFromPoint(pageX, pageY)) return;

      const newPosition = pageX - trackLeft;
      const [newLeftPxValue, newRightPxValue] = getPxPosition(newPosition, isLeftDragging, isRightDragging);
      const newRangeValue: number | [number, number] = getValidRangeValueFromPx(newLeftPxValue, newRightPxValue);

      if (!checkValueChanged(newRangeValue)) return;

      setNewRangeValue(newRangeValue);

      if (typeof onSlide !== 'function') return;

      if (range) (onSlide as (value: [number, number]) => void)(newRangeValue as [number, number]);
      else (onSlide as (value: number) => void)(newRangeValue as number);
    };

    const handleDragEnd = () => {
      if (!isLeftDragging && !isRightDragging) return;

      isLeftDragging && setIsLeftDragging(false);
      isRightDragging && setIsRightDragging(false);

      const currentRangeValue: number | [number, number] = range ? [validLeftValue, validRightValue] : validRightValue;

      runOnChange(currentRangeValue);
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag);
    document.addEventListener('touchend', handleDragEnd);

    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDrag);
      document.removeEventListener('touchend', handleDragEnd);
    };
  }, [trackWidth, isLeftDragging, isRightDragging, validRangeValue, minValue, maxValue]);

  useEffect(() => {
    setTrackWidth(trackRef?.current?.getBoundingClientRect()?.width);

    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      return;
    }, 0);

    function handleResize() {
      clearTimeout(timer);

      timer = setTimeout(() => setTrackWidth(trackRef?.current?.getBoundingClientRect()?.width), 500);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!range) setLeftValue(min);
  }, [range]);

  useEffect(() => {
    setMinValue(min);
  }, [min]);

  useEffect(() => {
    setMaxValue(max);
  }, [max]);

  useEffect(() => {
    if (Array.isArray(value)) {
      if (dragStartValue[0] !== value[0] || dragStartValue[1] !== value[1]) setNewRangeValue(value);
    } else {
      if (dragStartValue[1] !== value) setNewRangeValue(value);
    }
  }, [value]);

  return (
    <div className={classNames('haefe-range-wrapper', statusClassName)}>
      <div ref={trackRef} className="haefe-range-track">
        {range && (
          <div
            style={{
              left: `${validLeftPercent}%`,
            }}
            ref={leftRef}
            onTouchStart={() => handleDragStart(setIsLeftDragging)}
            onMouseDown={e => handleDragStart(setIsLeftDragging, e)}
            className={classNames('haefe-range-left-handle', { 'haefe-range-upper-handle': validLeftPercent >= 50 })}
          >
            {tooltip && <div className="haefe-range-tooltip">{`${validLeftValue}${unit}`}</div>}
          </div>
        )}
        <div
          style={{ left: `${validLeftPercent}%`, width: `${validRightPercent - validLeftPercent}%` }}
          className="haefe-range-slider"
        />
        <div
          style={{ left: `${validRightPercent}%` }}
          ref={rightRef}
          onTouchStart={() => handleDragStart(setIsRightDragging)}
          onMouseDown={e => handleDragStart(setIsRightDragging, e)}
          className={classNames('haefe-range-right-handle', { 'haefe-range-upper-handle': validRightPercent > 50 })}
        >
          {tooltip && <div className="haefe-range-tooltip">{`${validRightValue}${unit}`}</div>}
        </div>
      </div>
      <div ref={trackRef} className="haefe-range-click-area" onMouseDown={handleClickRange} />
      {displayMinMax && (
        <>
          <div className="haefe-range-min-value">{minValue}</div>
          <div className="haefe-range-max-value">{maxValue}</div>
        </>
      )}
    </div>
  );
}

export default RangeSlider;
