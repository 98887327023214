import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import buttonStyle from './style';
import Spinner from '@/components/units/spinner';
import { IButtonProps } from './type';

// TODO: spinner 커스텀 영역 확장. 배치, 아이콘, 메시지 등등
function Button({
  children,
  onClick,
  buttonType = 'primary',
  size = 'm',
  className,
  refProps,
  spinner,
  ...otherProps
}: PropsWithChildren<IButtonProps>) {
  const spinnerInfo = typeof spinner === 'object' ? spinner : null;
  const spinnerPosition = spinnerInfo?.position;
  const showChildren = (spinnerInfo && !spinnerPosition) || spinnerInfo?.showChildren;

  return (
    <button
      css={buttonStyle}
      className={classNames(
        className,
        'btn',
        { 'btn-spinner': !!spinner },
        { 'primary-button': buttonType === 'primary' },
        { 'secondary-button': buttonType === 'secondary' },
        { 'tertiary-button': buttonType === 'tertiary' },
        { 'text-button': buttonType === 'text' },
        { 'secondary-2-button': buttonType === 'secondary2' },
        { 'linepay-button': buttonType === 'line' || buttonType === 'line-pay' },
        { 'button-2c2p': buttonType === '2c2p' || buttonType === '2c2p-pay' },
        { 'l-buttonSize': size === 'l' },
        { 'm-buttonSize': size === 'm' },
        { 's-buttonSize': size === 's' },
        { 'xs-buttonSize': size === 'xs' },
      )}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      ref={refProps}
      {...otherProps}
    >
      {spinner && spinnerPosition === 'left' && (
        <span className="spinner-container">
          <Spinner size={spinnerInfo?.size} />
        </span>
      )}
      {buttonType === 'line' && <i className="ic ic-line"></i>}
      {(!spinner || showChildren) && <span>{children}</span>}
      {buttonType === 'line-pay' && <i className="ic ic-line-pay"></i>}
      {buttonType === '2c2p-pay' && <i className="ic ic-2c2p-pay"></i>}
      {spinner && spinnerPosition === 'right' && (
        <span className="spinner-container">
          <Spinner size={spinnerInfo?.size} />
        </span>
      )}
    </button>
  );
}

export default Button;
