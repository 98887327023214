export const STATUS_CLASS = {
  error: 'error',
  warning: 'warning',
  success: 'success',
};

export const STATUS_TYPE_VALUE = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
} as const;
