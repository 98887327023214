import { css } from '@emotion/react';
import { colors } from './mixin';

const iconStyle = css`
  // 아이콘
  .ic {
    display: block;

    &.ic-notice {
      width: 4.8rem;
      height: 4.8rem;
      // TODO: 네트워크 단절시에도 쓰이는 이미지로 base64로 고정값 사용
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI0IiBjeT0iMjMuOTY0OCIgcj0iMjEuMiIgc3Ryb2tlPSIjOEY4RjhGIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8cGF0aCBkPSJNMjQgMTQuNzE2OEwyNCAyNi43MTY4IiBzdHJva2U9IiM4RjhGOEYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTIyLjUgMzMuMjEyOUwyNS41IDMzLjIxMjlMMjUuNSAzMC4yMTI5TDIyLjUgMzAuMjEyOUwyMi41IDMzLjIxMjlaIiBmaWxsPSIjOEY4RjhGIi8+DQo8L3N2Zz4NCg==)
        no-repeat center / 4.8rem;
    }
    &.ic-close {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_close.svg') no-repeat center / 2.4rem;
    }
    // 로그인 아이콘
    &.ic-login {
      position: relative;
      width: 2rem;
      height: 2rem;

      &::before,
      &::after {
        content: '';
        left: 50%;
        position: absolute;
        border: 0.2rem solid ${colors.$color_767};
        transform: translateX(-50%);
        transition: background 0.5s;
      }

      &::before {
        top: 0.1rem;
        width: 0.47rem;
        height: 0.47rem;
        border-radius: 1rem;
      }

      &::after {
        top: 1rem;
        width: 1.5rem;
        height: 0.54rem;
        border-radius: 0.8rem 0.8rem 0 0;
      }
      // 로그인 시
      &.ic-login-on {
        &::before,
        &::after {
          background-color: ${colors.$color_000};
          border-color: ${colors.$color_000};
        }
      }
    }
    // GNB > language
    &.ic-language {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_24_language.svg') no-repeat center / 2rem;
    }
    // GNB > Mobile link
    &.ic-gnb-link {
      width: 2.3rem;
      height: 2.3rem;
      background: url('/assets/images/ic_gnb_link.svg') no-repeat center / 2.3rem;
    }
    &.ic-language-arrow {
      width: 0.7rem;
      height: 0.5rem;
      background: url('/assets/images/ic_language_arrow.svg') no-repeat center / cover;
    }
    // GNB > hamburger
    &.ic-hamburger {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/Header_menu2.svg') no-repeat center / 2.4rem;
    }
    // tag component
    &.ic-tag-remove {
      width: 1.6rem;
      height: 1.6rem;
      background-color: brown;
      // background: url('/assets/images/.svg') no-repeat center / 1.6rem;
    }
    // Footer > location
    &.ic-location-ft {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/icon_location_ft.svg') no-repeat center / 1.6rem;
    }
    // Footer > call
    &.ic-call {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/icon_call_ft.svg') no-repeat center / 1.6rem;
    }
    // Footer > logo
    &.ic-logo {
      width: 60px;
      height: 32px;
      background: url('/assets/images/m_logo_Hyundai_white.svg') no-repeat center / 60px;
    }
    // Footer > email
    &.ic-email {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/icon_email_ft.svg') no-repeat center / 1.6rem;
    }
    // Footer > sns
    &.ic-facebook {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_facebook.svg') no-repeat center / 2.4rem;
    }
    &.ic-youtube {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_youtube.svg') no-repeat center / 2.4rem;
    }
    &.ic-google {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_google.svg') no-repeat center / 2.4rem;
    }
    &.ic-twitter {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_twitter.svg') no-repeat center / 2.4rem;
    }
    &.ic-linkedin {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_inlinked.svg') no-repeat center / 2.4rem;
    }
    &.ic-instagram {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_instagram.svg') no-repeat center / 2.4rem;
    }
    &.ic-dribble {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/icon_dribbble.svg') no-repeat center / 2.4rem;
    }

    // Cl!ck to Buy
    &.ic-compare {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_compare.svg') no-repeat center / 2.4rem;
    }
    &.ic-compare-s {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_compare_s.svg') no-repeat center / 2.4rem;
    }
    &.ic-filter {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_filter.svg') no-repeat center / 2.4rem;
    }
    &.ic-filter-s {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_filter_s.svg') no-repeat center / 2.4rem;
    }
    &.ic-close {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_close.svg') no-repeat center / 2.4rem;
    }

    // My Garage
    &.ic-no-contract {
      width: 4.8rem;
      height: 4.8rem;
      background: url('/assets/images/ic_no_contract.svg') no-repeat center / 4.8rem;
    }
    &.ic-arrow-18 {
      width: 1.8rem;
      height: 1.8rem;
      background: url('/assets/images/ic_arrow_18.svg') no-repeat center / 1.8rem;
    }
    &.ic-quote {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_quote.svg') no-repeat center / 2rem;
    }
    &.ic-drive {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_drive.svg') no-repeat center / 2rem;
    }
    &.ic-trade {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_trade.svg') no-repeat center / 2rem;
    }
    &.ic-contact {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_contact.svg') no-repeat center / 2rem;
    }
    &.ic-tag-arrow {
      width: 3.2rem;
      height: 3.2rem;
      background: url('/assets/images/ic_tag_arrow.svg') no-repeat center / 3.2rem;
    }
    &.ic-booking-playment-complete {
      width: 4.8rem;
      height: 4.8rem;
      background: url('/assets/images/ic_confirm.svg') no-repeat center / 4.8rem;
    }

    // component > 네비게이션 화살표
    &.ic-nav-arrow {
      width: 1.8rem;
      height: 1.8rem;
      background: url('/assets/images/ic_nav_arrow.svg') no-repeat center / 1.8rem;
    }
    // component > 라디오버튼 선택 안됨
    &.ic-radio {
      min-width: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_radio_normal.svg') no-repeat center / 2.4rem;
    }
    // component > 체크박스 선택 안됨
    &.ic-checkbox {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_checkbox_normal.svg') no-repeat center / 2.4rem;
    }
    // 상단 location
    &.ic-location {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_location.svg') no-repeat center / 1.6rem;
    }
    &.ic-location-w {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_location_w.svg') no-repeat center / 1.6rem;
    }
    &.ic-info {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_info.svg') no-repeat center / 2rem;
    }
    &.ic-location-b {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_location_b.svg') no-repeat center / 1.6rem;
    }
    // ctb > change dealer modal
    &.ic-location-14 {
      width: 1.4rem;
      height: 1.4rem;
      background: url('/assets/images/ic_location_14.svg') no-repeat center / 1.4rem;
    }
    &.ic-call-14 {
      width: 1.4rem;
      height: 1.4rem;
      background: url('/assets/images/ic_call_14.svg') no-repeat center / 1.4rem;
    }
    &.ic-time {
      width: 1.4rem;
      height: 1.4rem;
      background: url('/assets/images/ic_time.svg') no-repeat center / 1.4rem;
    }
    &.ic-delivery-ready {
      width: 1.4rem;
      height: 1.4rem;
      background: url('/assets/images/ic_delivery_ready.svg') no-repeat center / 1.4rem;
    }
    &.ic-delivery-not-ready {
      width: 1.4rem;
      height: 1.4rem;
      background: url('/assets/images/ic_delivery_not_ready.svg') no-repeat center / 1.4rem;
    }
    // mg > dealership info
    &.ic-personal {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_personal.svg') no-repeat center / 1.6rem;
    }
    &.ic-download {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_download.svg') no-repeat center / 2rem;
    }
    // mg > option detail
    &.ic-arrow-b-24 {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_arrow_b_24.svg') no-repeat center / 2.4rem;
    }
  }

  // component > 라디오버튼 선택 됨
  [type='radio'] {
    &:checked {
      + .ic-radio {
        background: url('/assets/images/ic_radio_selected.svg') no-repeat center / 2.4rem;
      }
    }
    &:disabled {
      + .ic-radio {
        background: url('/assets/images/ic_radio_dim.svg') no-repeat center / 2.4rem;
      }
    }
  }

  // component > 체크박스 선택 됨
  [type='checkbox'] {
    &:checked {
      + .ic-checkbox {
        background: url('/assets/images/ic_checkbox_selected.svg') no-repeat center / 2.4rem;
      }
    }
    &:disabled {
      + .ic-checkbox {
        background: url('/assets/images/ic_checkbox_dim.svg') no-repeat center / 2.4rem;
      }
    }
  }

  &.ic-arrow-back {
    width: 3.2rem;
    height: 3.2rem;
    background: url('/assets/images/icon_arrow.svg') no-repeat center / 100% auto;
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default iconStyle;
