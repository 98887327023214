import React, { useEffect, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import Checkbox from './index';
import { CheckboxGroupBasicProps, CheckboxValueType } from './type';

function CheckboxGroup({
  items = [],
  disabled = false,
  onChange = () => null,
  defaultValues = [],
  values,
  className,
  style,
  itemClassName,
  ...otherProps
}: CheckboxGroupBasicProps) {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultValues);

  const handleChange = useCallback(
    (checked: any, e: React.MouseEvent<HTMLInputElement>, checkValue: CheckboxValueType) => {
      const result = checkedList?.includes(checkValue);
      let newCheckedList = checkedList;

      if (checked && !result) newCheckedList = [...checkedList, checkValue];
      else if (!checked && result) newCheckedList = checkedList.filter(v => v !== checkValue);

      onChange(newCheckedList, e, checkValue);

      if (values) return;

      setCheckedList(newCheckedList);
    },
    [checkedList, onChange],
  );

  useEffect(() => {
    // 우선순위는 values가 더 높음
    if (values) setCheckedList(values);
    else if (values !== undefined) setCheckedList([]);
  }, [values]);

  return (
    <div
      className={classNames(className, 'haefe-checkboxGroup', disabled && 'haefe-checkboxGroup-disabled')}
      {...otherProps}
    >
      {items.map(item => {
        const checkedProps: any = {};

        if (values === undefined && defaultValues.includes(item.value)) checkedProps.defaultChecked = true;
        else if (values !== undefined) checkedProps.checked = checkedList.includes(item.value);

        return (
          <Checkbox
            key={item.value}
            label={item.label}
            value={item.value}
            disabled={disabled ? true : item?.disabled}
            onClick={handleChange}
            className={itemClassName}
            {...checkedProps}
          />
        );
      })}
    </div>
  );
}

export default CheckboxGroup;
