import { createContext } from 'react';
import { FormInstance } from './type';

const FormContext = createContext<{
  fields: { [key: string]: any };
  setFields: (values: any) => void;
  onValuesChange: (values: any) => void;
  form: FormInstance;
}>({
  fields: {},
  setFields: (values: any) => null,
  onValuesChange: (values: any) => null,
  form: null,
});

export default FormContext;
