import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import Radio from './index';
import { STATUS_CLASS } from '../constants/status';
import { RadioGroupBasicProps, RadioValueType } from './type';

function RadioGroup({
  items = [],
  disabled = false,
  onChange = () => null,
  defaultValue,
  value,
  name,
  status = null,
  className,
  style,
  ...otherProps
}: RadioGroupBasicProps) {
  const [checkedValue, setCheckedValue] = useState<RadioValueType>(defaultValue);
  const statusClassName = STATUS_CLASS[status] ?? null;

  const handleClick = useCallback(
    (checked: any, e: React.MouseEvent<HTMLInputElement>, checkValue: RadioValueType) => {
      onChange(checkValue, e);

      if (value) e.preventDefault();
      else {
        setCheckedValue(checkValue);
      }
    },
    [onChange, value],
  );

  useEffect(() => {
    if (value) setCheckedValue(value);
    else if (value !== undefined) setCheckedValue(null);
  }, [value]);

  return (
    <div
      className={classNames(
        className,
        'haefe-radioGroup',
        disabled && 'haefe-radioGroup-disabled',
        statusClassName ?? null,
      )}
      style={style}
      {...otherProps}
    >
      {items.map(item => {
        return (
          <Radio
            key={JSON.stringify(item.value)}
            label={item.label}
            value={item.value}
            name={name}
            disabled={disabled ? true : item?.disabled}
            onClick={handleClick}
            checked={item.value === checkedValue}
          />
        );
      })}
    </div>
  );
}

export default RadioGroup;
