import React from 'react';
import { ISelectedDealerConsultant } from '@components/modules/changeDealerModal';
import LocationStyle from './style';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';
import { currentDealerState, selfSelectedDealerState } from '@/stores/common/user';
import useUpdateUserDealerConsultant from '@/hooks/useUpdateUserDealerConsultant';
import useChangeDealer from '@/hooks/useChangeDealer';

interface ILocationInterface {
  onDealerConsultantChange?: ({ dealer, consultant }: ISelectedDealerConsultant) => void | boolean;
}

export function Location({ onDealerConsultantChange }: ILocationInterface) {
  const { t } = useTranslation();
  const currentDealer = useRecoilValue(currentDealerState);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerState);
  const { updateStoreCookieForDealerSC } = useUpdateUserDealerConsultant();
  const { openChangeDealerModal: openChangeDealer, openSuccessToast: openChangeToast } = useChangeDealer();

  const handleDealerConsultantChange = ({ dealer, consultant }: ISelectedDealerConsultant) => {
    if (onDealerConsultantChange && typeof onDealerConsultantChange === 'function') {
      const result = onDealerConsultantChange({ dealer, consultant });

      if (result === false) {
        return;
      }
    }

    updateStoreCookieForDealerSC({
      dealerId: dealer.id,
      consultantId: consultant?.id,
    });

    openChangeToast();
  };

  const openChangeDealerModal = () => {
    openChangeDealer({
      callback: handleDealerConsultantChange,
      hideConsultantSelect: true,
      requiredFields: ['province'],
    });
  };

  return (
    <div className="location-box" css={LocationStyle}>
      <div className="location">
        <i className="ic ic-location"></i>
        {selfSelectedDealer ? (
          <span>
            {currentDealer?.province} {currentDealer?.district && `& ${currentDealer.district}`}
          </span>
        ) : (
          <span>{t('NoDealerSelected')}</span>
        )}
      </div>

      <div className="change-dealer-box">
        <button className="change-dealer" onClick={openChangeDealerModal}>
          {selfSelectedDealer ? t('Label.ChangeDealer') : t('Label.SelectDealer')}
        </button>
      </div>
    </div>
  );
}
