import Button from '@components/units/button';
import Modal from '@/components/modules/modals/modal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AgreementBox from '../agreementBox';
import { useTranslation } from 'next-i18next';

interface IAgreementModalProps {
  displayCloseButton?: boolean;
  agreeKeys?: ('policy' | 'receive' | 'booking' | 'offline')[];
  onFinish?: (state: string[]) => void;
  onClose?: () => void;
}

const AgreementModal = forwardRef(function (
  {
    agreeKeys = ['policy', 'receive', 'booking', 'offline'],
    displayCloseButton = false,
    onFinish,
    onClose,
  }: IAgreementModalProps,
  ref,
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<string[]>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setIsOpen(true);
    },
    closeModal: () => {
      setIsOpen(false);
    },
  }));

  function handleChange(formData: { [key: string]: boolean }) {
    setIsDisabled(!formData?.policy);
    setFormState(Object.keys(formData));
  }

  function handleConfirm() {
    onFinish && onFinish(formState);
  }

  return (
    <Modal
      visible={isOpen}
      size="small-popup"
      backDropClose={false}
      closeModal={() => {
        setIsOpen(false);
        onClose && onClose();
      }}
      modalWrapperClassName={'modal-consultant-change'}
      displayCloseButton={displayCloseButton}
      modalTitle="Agreement"
      footer={
        <Button disabled={isDisabled} onClick={handleConfirm}>
          {t('Label.Confirm')}
        </Button>
      }
    >
      <AgreementBox agreeKeys={agreeKeys} handleChange={handleChange} />
    </Modal>
  );
});

export default AgreementModal;
