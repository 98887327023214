import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const changeDealerInfoMessageStyle = css`
  .dealer-info-message-top {
    ${flex('row', 'space-between', 'center')};
    .title {
      ${font('1.6rem', 500, colors.$color_000, '2.4rem')};
    }
    .count-box {
      ${font('1.4rem', 400, colors.$color_000, '1.8rem')};
      .num {
        margin-right: 0.4rem;
        font-weight: 500;
        color: ${colors.$secondary1};
      }
    }
  }

  .no-region-dealer-message {
    ${font('14px', 400, colors.$color_767, '20px')};
  }

  .dealer-info-message-bottom {
    display: none;
    margin: 1.3rem 0 0;

    &.visible {
      display: flex;
      margin: 0.8rem 0 1.9rem;
    }
  }

  @media ${layout.$mobile} {
    &.dealer-info-message {
      width: 100%;
      margin: 2.4rem 0;

      .no-region-dealer-message {
        ${font('13px', 400, colors.$color_767, '20px')};
      }

      .dealer-info-message-bottom.visible {
        margin-bottom: 0;
      }
    }
  }
`;

export default changeDealerInfoMessageStyle;
