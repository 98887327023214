import { useCustomCookies } from '@/hooks/useCustomCookie';
import { useRefreshToken } from '@/query/hmgId/query';

function useTokenRefresh(): void {
  const [cookies] = useCustomCookies(['cookie_BuyOnline_th']);

  useRefreshToken({
    enabled: !!cookies.cookie_BuyOnline_th,
    refetchInterval: 3600000, // 1시간 단위 인터벌 설정(1000 * 60 * 60 )
  });
}

export default useTokenRefresh;
