import AlertModal from '@/components/modules/modals/alertModal';
import dealerBlockBottomStyle from '@/components/modules/dealerBlock/components/bottom/style';
import { css } from '@emotion/react';
import Image from 'next/image';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import ToastModal from '@/components/modules/modals/toastModal';
import classNames from 'classnames';
import { isValidMarker } from '@/utils/validation';

interface IDealerBlockBottomProps {
  location?: string | React.ReactNode;
  call?: string;
  time?: string;
  noCallAction?: boolean;
  dealerCode?: string;
  dealerName?: string;
  readyToDelivery?: boolean;
  lat?: number;
  lng?: number;
}

function DealerBlockBottom({
  location,
  call,
  time,
  noCallAction = false,
  dealerCode,
  dealerName,
  lat,
  lng,
  readyToDelivery = null,
}: IDealerBlockBottomProps) {
  const { t } = useTranslation();

  const [toastShow, setToastShow] = useState<boolean>(false);
  const [copyToastShow, setCopyToastShow] = useState<boolean>(false);
  /**
   * TODO: THAIONSALE-1954
   * 요청 : Doty 로고 표출 (Dealer of the year)
   * 기간 : 23년 6월 1일 부터 24년 5월 31일까지 / 1년마다 재선정
   * 조건 : dealerCode: "A24AT01001", name: "Hyundai Rattanathibet-Rama 5"
   */
  const DOTY_DEALERCODE = 'A24AT01001';
  const DOTY_DEALERNAME = 'Hyundai Rattanathibet-Rama 5'; // TODO: my Garage 견적서 딜러 정보에서만 name으로 비교 (qutation 데이터에서 dealerCode가 없음)
  const DOTY_DEALERNAME_TH = 'ฮุนได รัตนาธิเบศร์-พระราม 5';

  const dotyStyle = css`
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: 95px;
    }
  `;
  const dotyInfoStyle = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: auto;
    }
    .doty-info-title {
      font-size: 1.6rem !important;
      font-weight: 500 !important;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .doty-info-content {
      font-size: 1.5rem;
      font-weight: 400;
    }
    img {
      width: 128px;
    }
  `;

  /**
   * 주소 복사 기능
   * @param address 복사할 텍스트
   */
  const handleAddressCopy = (address: string) => {
    const textArea = document.createElement('textarea');

    textArea.value = address;
    const textToCopy = textArea.value;

    if (navigator.clipboard) {
      navigator.clipboard?.writeText(textToCopy);
    }
    textArea.select();
    document.execCommand('copy');

    setCopyToastShow(true);
  };

  const isValidLatLng = isValidMarker(lat, lng);

  const handleClickCopy = () => {
    const googleMpaUrl = isValidLatLng ? `https://www.google.com/maps/search/?api=1&query=${lat},${lng}` : '';

    handleAddressCopy(googleMpaUrl);
  };

  return (
    <div className="dealer-block-bottom" css={dealerBlockBottomStyle}>
      <div className="info-box">
        <i className="ic ic-location-14"></i>
        <div
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{
            __html: `<img src="/assets/images/ic_16_info_location.png" alt="vehicle image" />`,
          }}
        ></div>

        <div className="info-inline">
          <span className="info">{location}</span>
          <button type="button" className="info-copy btn btn-text page-only" onClick={handleClickCopy}>
            {t('Copy')}
          </button>
        </div>
      </div>
      <div className="info-box">
        <i className="ic ic-call-14"></i>
        <div
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{ __html: `<img src="/assets/images/ic_16_info_call.png" alt="vehicle image" />` }}
        ></div>
        <span className="info">
          {/* TODO: 1차 태국 번호 +66 고정 */}
          Tel :{/* <a href={!noCallAction ? `tel:+66${call}` : null} style={{ color: 'inherit' }}> */}
          {call}
          {/* </a> */}
        </span>
      </div>
      <div className="info-box">
        <i className="ic ic-time"></i>
        <div
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{ __html: `<img src="/assets/images/ic_16_info_time.png" alt="vehicle image" />` }}
        ></div>
        <span className="info">{time}</span>
      </div>
      {/* 재고 임시 숨김 처리 */}
      {/* {readyToDelivery !== null && (
        <div className="info-box">
          <i
            className={classNames(
              'ic',
              { 'ic-delivery-ready': readyToDelivery },
              { 'ic-delivery-not-ready': !readyToDelivery },
            )}
          ></i>
          <span className={classNames('info', { ready: readyToDelivery }, { 'not-ready': !readyToDelivery })}>
            {readyToDelivery ? t('ReadyToDelivery') : t('UnableToDeliverImmediately')}
          </span>
        </div>
      )} */}
      {(dealerCode === DOTY_DEALERCODE || dealerName === DOTY_DEALERNAME || dealerName === DOTY_DEALERNAME_TH) && (
        <>
          <div
            className="doty-box pdf-doty-box"
            css={dotyStyle}
            dangerouslySetInnerHTML={{
              __html: `<img src="/assets/images/240601Doty/DOTY_2024_RGB_Hyundai_Blue.png" alt="HYUNDAI DOTY" />`,
            }}
            onClick={() => {
              setToastShow(true);
            }}
          />

          <AlertModal
            visible={toastShow}
            closeModal={() => {
              setToastShow(false);
            }}
            icon=""
            // modalTitle="HYUNDAI DOTY"
            text={
              <div className="doty-info" css={dotyInfoStyle}>
                <Image
                  src="/assets/images/240601Doty/DOTY_2024_RGB_Hyundai_Blue.png"
                  width={120}
                  height={60}
                  alt="HYUNDAI DOTY"
                />
                <p className="doty-info-title">HYUNDAI DOTY</p>
                <p className="doty-info-content">{t('DotyMessage')}</p>
              </div>
            }
          />
        </>
      )}

      <ToastModal
        visible={copyToastShow}
        icon={false}
        button={false}
        closeModal={() => {
          setCopyToastShow(false);
        }}
      >
        {isValidLatLng ? t('SuccessfullyCopiedTheURL') : t('FailedToCopyTheURL')}
      </ToastModal>
    </div>
  );
}

export default DealerBlockBottom;
