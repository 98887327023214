import React, { useEffect, useState } from 'react';
import DealerBlock from '@components/modules/dealerBlock';
import MyMap from '@components/modules/map';
import changeDealerContainerStyle from '@/components/modules/changeDealerModal/components/container/style';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import { Dropdown } from '@/components/haeLibraryRefactored';
import { useEmployeeList } from '@/query/users/query';
import ConsultantBlock from '@/components/modules/dealershipBlock/components/consultantBlock';
import { DropdownItem } from '@/components/haeLibraryRefactored/Dropdown/type';
import Empty from '@/components/units/empty';
import Loading from '@/components/modules/Loading';
import useDevice from '@/hooks/useDevice';
import DealerInfoMessage from './components/dealerInfoMessage';

export interface ChangeDealerContainerProps {
  dealerList: Dealers.TDealerEntity[];
  selectedDealerState: Dealers.TDealerEntity;
  handleChangeDealer: (dealer: Dealers.TDealerEntity) => void;
  handleChangeConsultant: (dealer: Global.IEmployeesEntity) => void;
  selectedConsultantState: Global.IEmployeesEntity;
  /** 컨설턴트 선택영역 비노출 여부 */
  hideConsultantSelect?: boolean;
  noDealersInRegion?: boolean;
  noRegionSelected?: boolean;
  isBasedOnRegion?: boolean;
  isLoading?: boolean;
}

type TConsultantId = number;

/**
 * sc response와 dropdownItem 합친 형태
 * 드롭다운 용 커스텀 타입
 */
export interface ICustomConsultantItem extends Users.IEmployeeListRes, DropdownItem {}

export interface IDefaultConsultant {
  value: number;
  label: string;
}

function ChangeDealerContainer({
  dealerList,
  selectedDealerState,
  selectedConsultantState,
  handleChangeDealer,
  handleChangeConsultant,
  hideConsultantSelect = false,
  noDealersInRegion = false,
  noRegionSelected = false,
  isBasedOnRegion,
  isLoading,
}: ChangeDealerContainerProps) {
  const [screen] = useDevice();
  const { t } = useTranslation();
  // 딜러 id
  const dealerId = selectedDealerState?.id ?? null;
  const isMobile = screen === 'M';

  // 기본 sc 선택값
  const defaultConsultant: IDefaultConsultant = {
    value: -1,
    label: t('ClickToBuy.AutoSelect'),
  } as const;

  // 선택한 sc id
  const selectedConsultantId = selectedConsultantState?.id ?? defaultConsultant.value;

  // 딜러 별 sc 리스트 - 드롭다운용
  const [consultantItems, setConsultantItems] = useState<DropdownItem[]>([]);
  const [isConsultantItemsLoading, setIsConsultantItemsLoading] = useState(true);

  const defaultConsultantItem = [
    selectedConsultantState?.id
      ? {
          value: selectedConsultantId,
          label: `${selectedConsultantState.firstName || ''} ${selectedConsultantState.lastName || ''}`,
        }
      : defaultConsultant,
  ];

  // 딜러 별 sc 리스트 - api
  const { data: consultantItemsResponse, isFetching } = useEmployeeList({
    active: true,
    salesConsultantOnly: true,
    dealerId,
  });

  /**
   * 딜러선택 변경 핸들러
   */
  const changeSelectedDealer = (dealer: Dealers.TDealerEntity) => {
    handleChangeDealer(dealer);
  };

  /**
   * sales consultant를 auto-select로 선택했는지 여부 타입가드
   */
  const isNotAutoSelected = (data: Global.IEmployeesEntity | IDefaultConsultant): data is ICustomConsultantItem => {
    return data?.hasOwnProperty('id');
  };

  /**
   * sc 변경 핸들러
   */
  const handleUpdateSelectedConsultant = (
    checkedId: TConsultantId,
    checkedData: ICustomConsultantItem | IDefaultConsultant,
  ) => {
    // 1. sc 선택한 경우
    if (isNotAutoSelected(checkedData)) {
      // dropdown용으로 커스텀했던 부분 제거하고 선택한 sc정보 전달
      const { label, value, ...consultantInfo } = { ...checkedData };

      handleChangeConsultant(consultantInfo);
    }
    // 2. auto-select 선택한 경우
    else {
      handleChangeConsultant(null);
    }
  };

  //#region sc 셀렉박스 추가
  useEffect(() => {
    setConsultantItems([
      defaultConsultant,
      ...(consultantItemsResponse || []).map(item => ({
        ...item,
        value: item.id,
        label: `${item.firstName ?? ''} ${item.lastName ?? ''}`,
      })),
    ]);

    setIsConsultantItemsLoading(isFetching);
  }, [consultantItemsResponse]);

  /**
   * sc dropbox 노출 조건
   */
  const showSCDropbox = (id: Dealers.TDealerEntity['id']) => {
    const selected = dealerId === id;

    return !hideConsultantSelect && selected;
  };

  //#endregion

  return (
    <div css={changeDealerContainerStyle} className="dealer-container">
      <div className="dealer-area">
        {/* {!isMobile && containerTop} */}
        {!isMobile && (
          <DealerInfoMessage
            dealerCount={dealerList?.length}
            noDealersInRegion={noDealersInRegion}
            noRegionSelected={noRegionSelected}
            isBasedOnRegion={isBasedOnRegion}
          />
        )}
        <div className="dealer-bottom">
          {isLoading && <Loading />}
          <div className="dealer-bottom-left">
            {dealerList?.length ? (
              dealerList.map(item => (
                <div
                  key={item.id}
                  tabIndex={0}
                  onClick={() => {
                    if (item.id !== selectedDealerState?.id) {
                      changeSelectedDealer(item);
                    }
                  }}
                  className={classNames('dealer-block-button', {
                    selected: dealerId === item.id,
                  })}
                >
                  <DealerBlock
                    title={item.name}
                    call={item.phoneNumber}
                    location={`${item.province ?? ''} ${item.district ?? ''} ${item.subDistrict ?? ''}`}
                    distance={item.distance ?? 0}
                    time={`${getDayjsObjWithoutYMD(item.openTime)?.format('hh:mmA')} - ${getDayjsObjWithoutYMD(
                      item.closeTime,
                    )?.format('hh:mmA')}`}
                    dealerCode={item?.dealerCode} // TODO: THAIONSALE-1954 DOTY 이미지 추가 건
                    readyToDelivery={item?.inventoryCount > 0 ? true : null}
                    lat={item.latitude}
                    lng={item.longitude}
                  />

                  {/* SC 영역 */}
                  {showSCDropbox(item.id) && (
                    <div className="dealer-sc">
                      {/* SC 선택 드롭박스 */}
                      <label className="dealer-sc-dropdown">
                        <span className="dealer-sc-label">{t('Label.SalesConsultant')}</span>
                        <div className="dealer-sc-form" onClick={e => e.stopPropagation()}>
                          <Dropdown
                            placeholder={defaultConsultant.label}
                            value={selectedConsultantId}
                            items={isConsultantItemsLoading ? defaultConsultantItem : consultantItems}
                            defaultValue={selectedConsultantId}
                            onChange={(checkedId: TConsultantId, checkedData: unknown) =>
                              handleUpdateSelectedConsultant(
                                checkedId,
                                checkedData as ICustomConsultantItem | IDefaultConsultant,
                              )
                            }
                          />
                        </div>
                      </label>

                      {/* 선택한 SC 상세 정보 */}
                      {selectedConsultantId && isNotAutoSelected(selectedConsultantState) && (
                        <div className="dealer-sc-info">
                          <ConsultantBlock
                            title={null}
                            dealerId={dealerId}
                            name={`${selectedConsultantState.firstName || ''} ${
                              selectedConsultantState.lastName || ''
                            }`}
                            call={selectedConsultantState.mobileNumber}
                            email={selectedConsultantState.emailAddress}
                            hasChange={false}
                            lineId={selectedConsultantState.lineId}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <Empty>
                <Empty.Bold>{t('NoDealerSelected')}</Empty.Bold>
                {t('Label.PleaseSelectADealer')}
              </Empty>
            )}
          </div>
          {isMobile && (
            <DealerInfoMessage
              dealerCount={dealerList?.length}
              noDealersInRegion={noDealersInRegion}
              noRegionSelected={noRegionSelected}
              isBasedOnRegion={isBasedOnRegion}
            />
          )}
          <div className="dealer-bottom-right">
            <div className="map-container">
              <MyMap
                center={
                  selectedDealerState
                    ? { lat: selectedDealerState?.latitude, lng: selectedDealerState?.longitude }
                    : null
                }
                dealerLocations={dealerList ?? []}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeDealerContainer;
