import React from 'react';
import ConsultantBlockBottom from './components/bottom';
import ConsultantBlockTop from './components/top';
import consultantBlockStyle from './style';

interface IConsultantBlockProps {
  title: string;
  call: string;
  name: string;
  email: string;
  lineId: string;
  dealerId: number;
  defaultEmployeeId?: number;
  hasChange?: boolean;
  handleChange?: (input: Users.IEmployeeListRes) => void;
}

function ConsultantBlock({
  title,
  call = '-',
  name = '-',
  email = '-',
  dealerId,
  hasChange = false,
  handleChange,
  defaultEmployeeId,
  lineId,
  ...otherProps
}: IConsultantBlockProps) {
  return (
    <div css={consultantBlockStyle} className="dealer-block" {...otherProps}>
      <ConsultantBlockTop
        title={title}
        hasChange={hasChange}
        handleChange={handleChange}
        dealerId={dealerId}
        defaultEmployeeId={defaultEmployeeId}
      />
      <ConsultantBlockBottom call={call} name={name} email={email} lineId={lineId} />
    </div>
  );
}

export default ConsultantBlock;
