import React from 'react';
import DealerBlockBottom from '@/components/modules/dealerBlock/components/bottom';
import DealerBlockTop from '@/components/modules/dealerBlock/components/top';
import dealerBlockStyle from '@/components/modules/dealerBlock/style';

interface IDealerBlockProps {
  title: string;
  isSelected?: boolean;
  distance?: string | number;
  location?: string | React.ReactNode;
  call?: string;
  time?: string;
  noCallAction?: boolean;
  readyToDelivery?: boolean;
  dealerCode?: string;
  lat?: number;
  lng?: number;
}

function DealerBlock({
  title,
  distance,
  location,
  call,
  time,
  noCallAction = false,
  dealerCode,
  readyToDelivery = null,
  lat,
  lng,
  ...otherProps
}: IDealerBlockProps) {
  return (
    <div css={dealerBlockStyle} className="dealer-block" {...otherProps}>
      <DealerBlockTop title={title} distance={distance} />
      <DealerBlockBottom
        location={location}
        call={call}
        time={time}
        noCallAction={noCallAction}
        dealerCode={dealerCode}
        dealerName={title} //TODO: THAIONSALE-1954 추가사항
        readyToDelivery={readyToDelivery}
        lat={lat}
        lng={lng}
      />
    </div>
  );
}

export default DealerBlock;
