import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const consultantBlockTopStyle = css`
  &.dealer-block-top {
    ${flex('row', 'space-between', 'center')};
    width: 100%;

    .consultant-title {
      ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
    }

    .dealer-location {
      ${font('1.4rem', 400, colors.$secondary1, '2rem')};
      .distance {
        margin: 0 0.3rem;
      }
    }
  }
`;

export default consultantBlockTopStyle;
