import useMenuLists from '@/hooks/useMenuList';
import Button from '@components/units/button';
import { showMobileMenuState } from '@/stores/common';
import { i18nLocaleState, i18nTenantState } from '@/stores/common/i18n';
import { compareSelector } from '@/stores/compare';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';
import headerStyle from './style';
import { gaAttributeName, languageText } from '@/constants/common';
import { useTranslation } from 'next-i18next';
import useMember from '@/hooks/useIsMeber';
import useCustomScroll from '@/hooks/useCustomScroll';
import { useMediaQuery } from 'usehooks-ts';
import { WWN_HOST } from '@/config';
import useLogout from '@/hooks/useLogout';
import { userSelector } from '@/stores/common/user';
import { omit } from 'lodash-es';
import Dim from '@/components/modules/dim';
import { getGaPageType } from '@/utils/gaCommon';

const Header = () => {
  const router = useRouter();
  const [isLogin] = useMember();
  const curPath = router.pathname === '/' || router.pathname === '/compare' ? 'ctb' : router.pathname?.split('/')[1];
  const [i18nLanguage, setI18nLanguage] = useRecoilState(i18nLocaleState);
  const [i18nTenant, setI18nTenant] = useRecoilState(i18nTenantState);

  const [compareStore, setCompareStore] = useRecoilState(compareSelector);
  const menuLists = useMenuLists();
  const [showMobileMenu, setShowMobileMenu] = useRecoilState(showMobileMenuState);
  const [showUserBox, setShowUserBox] = useState(false);
  const [showLangBox, setShowLangBox] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const matches = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const logout = useLogout();
  const sortedLocales = Array.from(router.locales).sort();
  const userStore = useRecoilValue(userSelector);

  const handleScroll = (isActive: boolean) => {
    setHideHeader(isActive);
    setShowUserBox(false);
    setShowLangBox(false);
  };

  useCustomScroll({
    handleScroll,
    threshold: matches ? 56 : 70,
    deps: hideHeader,
    // condition: router.pathname !== '/compare',
    isOnce: false,
  });

  const [cookies, setCookie] = useCookies(['lang', 'compareList_th']);
  const changeLanguageQuery = (lang?: string) => {
    const changeLang = lang ?? (router.query?.lang === 'en' ? 'th' : 'en');

    setI18nLanguage(changeLang);
    router.replace({
      query: {
        ...router.query,
        lang: changeLang,
      },
    });
    setCookie('lang', changeLang, {
      path: '/',
    });

    setShowLangBox(false);
  };

  const handleLogout = () => {
    logout();
    router.push({ pathname: '/', query: { lang: router?.query?.lang } });
  };

  useEffect(() => {
    if (router.query?.lang)
      setCookie('lang', router.query?.lang?.toString()?.startsWith('th') ? 'th' : 'en', { path: '/' });
  }, [router.query?.lang]);

  useEffect(() => {
    const initCartItems = cookies?.compareList_th ?? compareStore?.cartItems;

    setCompareStore({ vehicleArr: initCartItems, cartItems: initCartItems });
  }, []);

  useEffect(() => {
    const customWindow = window as Global.IWindow;

    const HMG_COMMON_TAG = omit(customWindow.HMG_COMMON_TAG, [
      'user_no',
      'login_type',
      'age_group',
      'gender',
      'birthday',
    ]);
    const gaPageType = getGaPageType(curPath) || null;

    customWindow.HMG_COMMON_TAG = {
      ...HMG_COMMON_TAG,
      login_status: isLogin ? 'Y' : 'N',
      country_code: i18nTenant,
      language_code: i18nLanguage || 'en',
      ...(gaPageType && {
        page_type: gaPageType,
      }),
      ...(isLogin && {
        email: userStore?.profile?.email || '',
        user_no: '', // 23.11.06 user_no 의 경우 빈string으로 보내기로 협의
        gender: userStore?.profile?.gender || '',
      }),
    };
  }, [isLogin, curPath]);

  return (
    <header className={classNames('header', { 'hide-header': hideHeader, open: showMobileMenu })} css={headerStyle}>
      <div className="header-container">
        <div className="header-area">
          {/* [D:added] */}
          {/*
            Class : use-pc
            Comment : pc element
          */}
          <Dim onClick={() => setShowMobileMenu(false)} openDim={showMobileMenu} />
          <div className="left-box use-pc">
            <h1 className="logo">
              <Link
                href={{
                  pathname: `${WWN_HOST}/${router.query.lang}`,
                }}
                className="logo-box"
                title="Hyundai logo"
              >
                <span className="hyundai-logo">
                  <Image width={150} height={21} src="/assets/images/hyundai-logo.svg" alt="hyundai logo" />
                </span>
              </Link>
            </h1>
          </div>
          {/* [D:added] */}
          {/*
            Class : use-mobile
            Comment : mobile element
          */}
          <div className="left-box use-mobile">
            <h1 className="logo">
              <Link
                href={{
                  pathname: `${WWN_HOST}/${router.query.lang}`,
                }}
                className="logo-box"
                title="Hyundai logo"
              >
                <span className="hyundai-logo">
                  <Image width={40} height={40} src="/assets/images/m_logo_Hyundai.svg" alt="hyundai logo" />
                </span>
              </Link>
              <span className="menu-name">{`${curPath}` in menuLists && menuLists[curPath]?.name}</span>
            </h1>
          </div>
          <div className="gnb-container">
            <div className="mobile-gnb-area use-mobile">
              <div className="mobile-gnb-box">
                {/* [D] */}
                {/*
                  Comment : log in 시 .login-button-box 제거
                */}
                {!isLogin && (
                  <div className="login-button-box">
                    <Button buttonType="primary" size="m" onClick={() => router.push(menuLists.login.path)}>
                      {t('Label.LogIn')}
                    </Button>
                  </div>
                )}
                {/* [D:added] */}
                {/*
                  Class   : active
                  Comment : 언어 옵션 펼치기, 확장 시 aria-expanded="true"
                */}
                <div className={classNames('language-box', { active: showLangBox })} aria-expanded={showLangBox}>
                  <button type="button" className="btn-select-language" onClick={() => setShowLangBox(!showLangBox)}>
                    <span className="selected">{languageText[i18nLanguage]}</span>
                    <i className="ic ic-language-arrow"></i>
                  </button>
                  <ul className="language-option">
                    {sortedLocales.map((lang: string) => {
                      return (
                        lang !== 'default' &&
                        lang !== i18nLanguage && (
                          <li className="option" key={lang}>
                            <button
                              type="button"
                              className="btn-language-option"
                              onClick={() => changeLanguageQuery(lang)}
                            >
                              <span>{languageText[lang]}</span>
                            </button>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* PC */}
            <div className="gnb-area">
              <ul className="gnb-box">
                <li className="gnb-list">
                  <Link href={`${WWN_HOST}/${router.query.lang}?gnbMenu=find-a-car`} className="gnb-link">
                    <span>{t('GNB.FindACar')}</span>
                    {/* [D:added] */}
                    {/*
                      Class : use-mobile
                      Comment : mobile element
                    */}
                    <i className="ic ic-gnb-link use-mobile"></i>
                  </Link>
                </li>
                <li className="gnb-list">
                  <Link href={`${WWN_HOST}/${router.query.lang}?gnbMenu=ioniq`} className="gnb-link">
                    <span>IONIQ</span>

                    <i className="ic ic-gnb-link use-mobile"></i>
                  </Link>
                </li>
                {/*[D]*/}
                {/*
                  Class   : active
                  Comment : gnb list 활성화 시
                */}
                <li className={classNames('gnb-list', { active: curPath === 'ctb' || curPath === '' })}>
                  <Link href={{ pathname: '/', query: { lang: router.query.lang } }} className="gnb-link">
                    <span>{t('GNB.ClickToBuy')}</span>
                  </Link>
                </li>
                <li className="gnb-list">
                  <Link href={`${WWN_HOST}/${router.query.lang}?gnbMenu=build-a-car`} className="gnb-link">
                    <span>{t('GNB.BuildACar')}</span>
                    {/* [D:added] */}
                    {/*
                      Class : use-mobile
                      Comment : mobile element
                    */}
                    <i className="ic ic-gnb-link use-mobile"></i>
                  </Link>
                </li>
                <li className="gnb-list">
                  <Link href={`${WWN_HOST}/${router.query.lang}?gnbMenu=service`} className="gnb-link">
                    <span>{t('GNB.ConnectToService')}</span>
                    {/* [D:added] */}
                    {/*
                      Class : use-mobile
                      Comment : mobile element
                    */}
                    <i className="ic ic-gnb-link use-mobile"></i>
                  </Link>
                </li>
                <li className="gnb-list">
                  <Link href={`${WWN_HOST}/${router.query.lang}?gnbMenu=hyundai-story`} className="gnb-link">
                    <span>{t('GNB.HyundaiStory')}</span>
                    {/* [D:added] */}
                    {/*
                      Class : use-mobile
                      Comment : mobile element
                    */}
                    <i className="ic ic-gnb-link use-mobile"></i>
                  </Link>
                </li>
                <li className={classNames('gnb-list', { active: curPath === 'mygarage' })}>
                  <Link href={menuLists.mygarage.path} className="gnb-link">
                    <span>{t('GNB.MyGarage')}</span>
                  </Link>
                </li>
                {/* 24.6.19 HMGC-2279 - wwn직관성 헤더 추가 */}
                <li className={'gnb-list'}>
                  <div className="gnb-BeaDealer">
                    <a href={`${WWN_HOST}/${router.query.lang}/utility/be-a-dealer`}>{t('GNB.BeAHyundaiDealer')}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* [D:added] */}
          {/* right-box 안의 요소 추가 및 태그 변경 */}
          <div className="right-box">
            {/* [D:added] */}
            {/*
              Comment : .btn-compare 담을 시 .count 노출, 없을 시 .count 제거
            */}
            <button type="button" className="btn-compare" onClick={() => router.push(menuLists.compare.path)}>
              <i className="ic ic-compare"></i>
              {compareStore?.cartItems?.some((d: ComparePage.ICartItems) => d?.productNo) && (
                <span className="count">
                  {compareStore?.cartItems?.filter((d: ComparePage.ICartItems) => d?.productNo)?.length}
                </span>
              )}
            </button>
            {/* [D:added] */}
            {/*
              Class : use-pc
              Comment : pc element
            */}

            <div className={classNames('user-box use-pc', { active: showUserBox })} aria-expanded={showUserBox}>
              <button
                type="button"
                className="btn-login use-pc"
                onClick={() =>
                  isLogin ? (setShowLangBox(false), setShowUserBox(!showUserBox)) : router.push(menuLists.login.path)
                }
              >
                <i className={`ic ic-login ${isLogin ? ' ic-login-on' : ''}`}></i>
              </button>
              <div className="user-option-box">
                <ul className="option-list">
                  {/* [D] */}
                  {/*
                    Class   : selected
                    Comment : selected만 진하게
                      클릭 시 user-box가 닫히게
                  */}
                  <li className={classNames('option', { selected: true })}>
                    <button
                      type="button"
                      className="btn-option"
                      onClick={() => {
                        router.push(menuLists.mygarage.path);
                        setShowUserBox(false);
                      }}
                    >
                      <span>My Garage</span>
                    </button>
                  </li>
                  <li className={classNames('option')}>
                    <button
                      type="button"
                      className="btn-option"
                      onClick={() => {
                        handleLogout();
                        setShowUserBox(false);
                      }}
                    >
                      <span>Log out</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/* [D] */}
            {/*
              Class   : active
              Comment : 언어 옵션 말풍선 노출
              Class : use-pc
              Comment : pc element
            */}
            <div className={classNames('language-box use-pc', { active: showLangBox })} aria-expanded={showLangBox}>
              <button
                type="button"
                className="btn-language"
                onClick={() => {
                  setShowUserBox(false);
                  setShowLangBox(!showLangBox);
                }}
              >
                <i className="ic ic-language"></i>
              </button>
              <div className="language-option-box">
                <ul className="option-list">
                  {/* [D] */}
                  {/*
                    Class   : selected
                    Comment : 언어 선택
                  */}
                  {sortedLocales.map((lang: string) => {
                    return (
                      lang !== 'default' && (
                        <li className={classNames('option', { selected: lang === i18nLanguage })} key={lang}>
                          <button type="button" className="btn-option" onClick={() => changeLanguageQuery(lang)}>
                            <span>{languageText[lang]}</span>
                          </button>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* [D:added] */}
            {/*
              Class : use-mobile
              Comment : mobile element
            */}
            <button
              type="button"
              className="btn-hamburger use-mobile"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <i className="ic ic-hamburger"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
