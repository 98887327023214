import getByteLengthOfString from '../../utils/getByteLengthOfString';
import React from 'react';
import { InputCountProps } from '../type';

function InputCount({ isByte, value, maxSize, type }: InputCountProps) {
  const count = isByte ? getByteLengthOfString(value) : value?.length ?? 0;
  const unit = isByte ? 'byte' : '';

  return (
    <div className={type === 'textarea' ? 'haefe-textarea-count' : 'haefe-input-count'}>
      ({`${count}/${maxSize} ${unit}`})
    </div>
  );
}

export default InputCount;
