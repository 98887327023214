import { DefaultValue, atom, selector } from 'recoil';
import { Cookies } from 'react-cookie';
import { getDealersDetail } from '@/query/dealers/api';

const cookies = new Cookies();

interface IUserStore {
  device?: Global.TDevice;
  profile?: null | Global.IUserProfile;
}

export const userState = atom<IUserStore>({
  key: 'stores.common.user.atom.user',
  default: {
    device: null,
    profile: null,
  },
});

export const userSelector = selector<IUserStore>({
  key: 'stores.common.user.selector.user',

  get: ({ get }) => {
    return get(userState);
  },

  set: ({ get, set }, value) => {
    const store = get(userState);
    const isSelectedValue = !(value instanceof DefaultValue);

    if (isSelectedValue && value.profile) {
      const { dealerId: userDealerId, employeeId: userEmployeeId } = value.profile.userAssociatedDealer;
      const cookieDealerId = cookies.get('buyOnline_curDealerId');
      const cookieConsultantId = cookies.get('buyOnline_consultantId');

      // users/me에서 받은 데이터에 dealer, sc정보 있는지 체크
      const hasPrevUserData = !!(userDealerId && userEmployeeId);
      // cookie에 있는 데이터에 dealer, sc정보 있는지 체크
      const hasPrevCookieData = !!(cookieDealerId && cookieConsultantId);

      // users/me 또는 cookies 둘중 하나라도 있으면 selfSelectedDealer 쿠키 true로 세팅
      const hasPrev = hasPrevUserData || hasPrevCookieData;

      // 기존 선택한 dealer, sc 정보 있는 경우에만 self 선택 여부 true로 업데이트
      cookies.set('buyOnline_selfSelectedDealer', hasPrev, {
        path: '/',
      });
      set(selfSelectedDealerState, hasPrev);
    }

    set(userState, { ...store, ...value });
  },
});

export const currentDealerState = atom<Dealers.TDealerEntity>({
  key: 'stores.common.user.atom.currentDealer',
  default: null,
});

export const currentDealerSelector = selector<Dealers.TDealerEntity>({
  key: 'stores.common.user.selector.currentDealer',
  get: ({ get }) => {
    return get(currentDealerState);
  },
  set: ({ set }, value) => {
    const id = value instanceof DefaultValue ? null : value.id;

    cookies.set('buyOnline_curDealerId', id, { path: '/' });
    set(currentDealerState, value);
  },
});
//#endregion

//#region 딜러 선택 여부
export const selfSelectedDealerState = atom<Dealers.TSelfSelectedDealer>({
  key: 'stores.common.user.atom.selfSelectedDealer',
  default: cookies.get('buyOnline_selfSelectedDealer'),
});

export const selfSelectedDealerSelector = selector<Dealers.TSelfSelectedDealer>({
  key: 'stores.common.user.selector.selfSelectedDealer',
  get: ({ get }) => {
    return get(selfSelectedDealerState);
  },
  set: ({ set }, value) => {
    cookies.set('buyOnline_selfSelectedDealer', value, { path: '/' });

    set(selfSelectedDealerState, value);
  },
});
//#endregion

export const currentConsultantState = atom<Global.IEmployeesEntity | null>({
  key: 'stores.common.user.atom.currentConsultantState',
  default: null,
});

export const currentConsultantSelector = selector<Global.IEmployeesEntity>({
  key: 'stores.common.user.selector.currentConsultant',
  get: ({ get }) => {
    return get(currentConsultantState);
  },
  set: ({ set }, value) => {
    const id = value instanceof DefaultValue ? null : value?.id;

    if (id) {
      cookies.set('buyOnline_consultantId', id, { path: '/' });
    }
    set(currentConsultantState, value);
  },
});

export const memberCheckState = atom({
  key: 'stores.common.user.atom.memberCheckState',
  default: false,
});
