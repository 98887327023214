import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const consultantBlockStyle = css`
  &.dealer-block {
    width: 45.6rem;
    padding: 1.6rem;
  }

  &.dealer-block {
    display: flex;
    flex-direction: column;
  }
  .dealer-block-bottom {
    ${flex('column', 'space-between', 'flex-start')};
  }

  @media ${layout.$mobile} {
    .dealership-info-bottom-right {
      margin-top: 1.6rem;
      padding-top: 1.6rem;
      border-top: 1px dashed ${colors.$color_999};
      .dealer-block {
        border-left: 0;
      }
    }

    .dealer-block {
      padding: 0;
      .consultant-title {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }

      .dealer-block-bottom {
        margin-top: 0.8rem;
      }

      .info-box {
        .info {
          font-size: 1.3rem;
          line-height: 2rem;
        }
      }
    }
  }
`;

export default consultantBlockStyle;
