import { css } from '@emotion/react';
import { font, colors } from 'styles/mixin';

const alertModalStyle = css`
  .modal-area {
    .text-box {
      p {
        text-align: center;
      }
    }
    .content-title {
      padding-bottom: 2rem;
      ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
      font-weight: 500 !important;
    }

    .modal-middle {
      .text-box {
        p {
          span {
            display: block;
          }
        }
      }
    }
  }
`;

export default alertModalStyle;
