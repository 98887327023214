import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const LocationStyle = css`
  ${flex('row', 'flex-start', 'center')};
  .location {
    ${flex('row', 'flex-start', 'center')};
    ${font('1.3rem', 400, colors.$color_000, '2rem')};
    .ic {
      margin-right: 0.8rem;
    }
  }
  .change-dealer {
    position: relative;
    margin-left: 1.6rem;
    ${font('1.3rem', 400, colors.$secondary1, '2rem')};
    letter-spacing: -0.025rem;
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${colors.$secondary1};
    }
  }

  @media ${layout.$mobile} {
    ${flex('row', 'space-between', 'center')};
    height: 100%;
    .location {
      color: ${colors.$color_fff};
    }
    .change-dealer {
      margin: 0;
    }
  }
`;

export default LocationStyle;
