export const getPxPositionBase = (
  position: number,
  isLeftChange: boolean,
  isRightChange: boolean,
  trackWidth: number,
  validLeftPercent: number,
  validRightPercent: number,
  widthPerStep: number,
  isRange: boolean,
) => {
  const percentToPx = (percentValue: number) => (percentValue * trackWidth) / 100;

  const leftPxValue = isLeftChange ? position : percentToPx(validLeftPercent);
  const rightPxValue = isRightChange ? position : percentToPx(validRightPercent);

  const valueDifference = rightPxValue - leftPxValue;

  if (valueDifference <= widthPerStep && !isRange) return [leftPxValue, rightPxValue];
  if (valueDifference <= widthPerStep)
    return isLeftChange ? [rightPxValue - widthPerStep, rightPxValue] : [leftPxValue, leftPxValue + widthPerStep];
  if (position <= 0) return isLeftChange ? [0, rightPxValue] : [leftPxValue, 0];
  if (position >= trackWidth) return isLeftChange ? [trackWidth, rightPxValue] : [leftPxValue, trackWidth];

  return isLeftChange ? [position, rightPxValue] : [leftPxValue, position];
};
