import React, { useContext } from 'react';
import FormContext from '../FormContext';
import { FormInstance } from '../type';

const useFormInstance = <T = unknown>(): FormInstance<T> => {
  const { form } = useContext(FormContext);

  return form as FormInstance<T>;
};

export default useFormInstance;
