export enum PATH {
  CLICK_TO_BUY = '/configuration',
  CART = '/cart',
  LOGIN = '/login',
  SIGN_UP = '/signup',
  MY_GARAGE = '/mygarage',
  LOGOUT = '/logout',
  COMPARE = '/compare',

  // My Garage 메뉴
  MYCTB = '/mygarage/myctb',
  BOOKING = '/mygarage/myctb/booking',
  QUOTATION = '/mygarage/myctb/quotation',
  PAYMENT = '/mygarage/myctb/quotation/payment',
  MY_ACCOUNT = '/mygarage/myaccount',
  MY_ACCOUNT_EDIT = '/mygarage/myaccount/edit',

  // 퀵 메뉴
  TEST_DRIVE = '/quick/test-drive',
  TRADE_IN = '/quick/trade-in',
  QUOTE = '/quick/quote',
  BUILD_PRICE = '/quick/build-price',
  CONTACT_US = '/quick/contact-us',

  // Select variant
  SELECT_VARIANT = '/selectVariant',
}
