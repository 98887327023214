import React from 'react';
import { useTranslation } from 'next-i18next';
import consultantBottomStyle from './style';
import classNames from 'classnames';

function ConsultantBlockBottom({ name, call, email, lineId }: IConsultantBlockBottomProps) {
  const { t } = useTranslation();

  const handleClickLine = () => {
    !!lineId && navigator?.clipboard?.writeText(lineId);
  };

  return (
    <div className="dealer-block-bottom" css={consultantBottomStyle}>
      <div className="info-box">
        <i className="ic ic-personal"></i>
        <span
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{ __html: `<img src="/assets/images/ic_16_info_seller.png" alt="ic_personal" />` }}
        ></span>
        <span className="info">{name}</span>
      </div>
      <div className="info-box">
        <i className="ic ic-call-14"></i>
        <span
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{ __html: `<img src="/assets/images/ic_16_info_call.png" alt="ic_call_14" />` }}
        ></span>
        <span className="info">
          {/* <a href={`tel:+66${call}`} style={{ color: 'inherit' }}> */}
          {call}
          {/* </a> */}
        </span>
      </div>
      <div className="info-box">
        <i className="ic ic-email"></i>
        <span
          className="pdf-icon-wrapper pdf-only"
          dangerouslySetInnerHTML={{ __html: `<img src="/assets/images/ic_16_email.png" alt="icon_email_ft" />` }}
        ></span>
        <span className="info">
          <a href={`mailto:${email}`} style={{ color: 'inherit', wordBreak: 'break-all' }}>
            {email}
          </a>
        </span>
      </div>
      <div className={classNames('info-box', 'line')}>
        <span className="info">
          {t('LineId')}
          <span onClick={handleClickLine} className={classNames({ 'line-id': !!lineId })}>
            {lineId ?? '-'}
          </span>
        </span>
      </div>
    </div>
  );
}

export default ConsultantBlockBottom;
