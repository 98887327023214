import React, { createContext } from 'react';

const QuickMenuModalContext = createContext<{
  modalType: TQuickModalType;
  dealerList: (Partial<Dealers.TDealerEntity> & { value: number; label: string })[];
  dealerListType: string;
  consultantList: {
    value: number;
    label: string;
  }[];
  provinceList?: string[];
  districtList?: string[];
  subDistrictList?: string[];
}>({
  modalType: null,
  dealerList: null,
  consultantList: null,
  dealerListType: 'region',
  provinceList: null,
  districtList: null,
  subDistrictList: null,
});

export default QuickMenuModalContext;
