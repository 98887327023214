import { useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';
import { DeviceList } from '@/constants/common';

type TDevice = Global.TDevice;

function useDevice(): TDevice {
  const getScreen = (): TDevice => {
    if (typeof window !== 'undefined' && window.screen) {
      /*
        PC/Pad(tablet)/Mobile 로 구성
        mobile : min 320px max 768px
        최적화는 360pxPad(tablet) : min 768px max 992px
        PC : 1280px - statCounter 데이터 FE B/O 가이드
      */
      return window.innerWidth >= 1280
        ? DeviceList.DESKTOP
        : window.innerWidth >= 768
        ? DeviceList.TABLET
        : DeviceList.MOBILE;
    }

    return DeviceList.MOBILE;
  };

  const [screen, setScreen] = useState<TDevice>(DeviceList.MOBILE);

  useIsomorphicLayoutEffect(() => {
    setScreen(getScreen());
  }, []);

  useEventListener('resize', () => {
    setScreen(getScreen());
  });

  return screen;
}

export default useDevice;
