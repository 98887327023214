import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import changeDealerInfoMessageStyle from './style';

interface IDealerInfoMessageProps {
  isBasedOnRegion: boolean;
  dealerCount: number;
  noDealersInRegion: boolean;
  noRegionSelected: boolean;
}

function DealerInfoMessage({
  isBasedOnRegion,
  dealerCount,
  noDealersInRegion,
  noRegionSelected,
}: IDealerInfoMessageProps) {
  const { t } = useTranslation();

  return (
    <div className="dealer-info-message" css={changeDealerInfoMessageStyle}>
      <div className="dealer-info-message-top">
        <div className="dealer-info-message-left">
          <div className="title-box">
            <span className="title">{t('Label.Dealer')}</span>
          </div>
        </div>
        <div className="dealer-info-message-right">
          <div className="count-box">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  isBasedOnRegion && !noRegionSelected && !noDealersInRegion
                    ? t('Label.N_DealersAreInThisLocation', { total: dealerCount })
                    : t('Label.N_DealersAvailable', { total: dealerCount }),
              }}
            ></span>
          </div>
        </div>
      </div>
      <div className={classNames('dealer-info-message-bottom', { visible: noDealersInRegion })}>
        {noDealersInRegion && (
          <>
            <i className="ic ic-info" />
            <div className="no-region-dealer-message">{t('Label.NoDealersInSelectedRegion')}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default DealerInfoMessage;
