import { css, Global } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import commonStyle from './common';
import fontStyle from './font';
import iconStyle from './icon';
import icon2Style from './icon2';
import resetStyle from './reset';
import formStyle from './form';
import badgeStyle from './badge';
import buttonStyle from './button';

const globalStyle = () => (
  <Global
    styles={css`
      ${emotionNormalize}
      ${resetStyle}
      ${fontStyle}
      ${commonStyle}
      ${iconStyle}
      ${icon2Style}
      ${formStyle}
      ${badgeStyle}
      ${buttonStyle}
    `}
  />
);

export default globalStyle;
