import Router from 'next/router';
import { scrollToField } from './scrollToField';
import { CONFIGURATION_STATUS } from 'pages/configuration/constants';
import { SetterOrUpdater } from 'recoil';
import { TFunction } from 'next-i18next';

export interface ICustomErrorProps {
  message: string;
  code: string;
  status: number;
  errors?: string[];
  url?: string;

  [otherProps: string]: any;
}

export class CustomError extends Error {
  message: string;
  code: string;
  status: number;

  constructor({ message, code, status, errors }: ICustomErrorProps) {
    super();

    this.message = message; // 오류 코드
    this.code = code; // 오류 코드
    this.status = status; // HTTP 상태 코드
  }
}

export function handleError(err: ICustomErrorProps, setGlobalAlertStore: SetterOrUpdater<Global.IAlert>, t: TFunction) {
  const errMessage = err?.message ?? '';
  const errOption: Global.IAlert = {
    message: errMessage,
    icon: 'ic_notice',
    handlerOk: () => {
      setGlobalAlertStore(null);

      return;
    },
  };

  // 에러 케이스별 처리
  switch (err.code) {
    case 'Application Error':
      // json이 포함된 string으로 내려와서 선 파싱 후 JSON.parse 사용
      const jsonStartIndex = errMessage.indexOf('{');
      const jsonEndIndex = errMessage.lastIndexOf('}') + 1;

      if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
        const jsonString = errMessage.substring(jsonStartIndex, jsonEndIndex);

        try {
          errOption.message = JSON.parse(jsonString).errMsg;
        } catch {
          errOption.message = errMessage;
        }
      }

      break;
    case 'Bad Request':
      if (err?.errors?.length > 0) {
        const [fieldKey, ...restString] = err?.errors[0].split(' ') || [];

        scrollToField(fieldKey, 70);
        errOption.message = restString.join(' ');
      } else {
        errOption.message = errMessage;
      }

      break;
    case 'API Error':
      //네트워크 에러 및 기타등등

      errOption.title = t('Label.RequestFailed');
      errOption.message = t('Alert.RequestFailedTryAgain');

      break;

    default:
      errOption.message = errMessage;
      errOption.handlerOk = () => {
        setGlobalAlertStore(null);
      };

      break;
  }

  // alert 노출
  setGlobalAlertStore(errOption);
}
