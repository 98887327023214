import { useGetDealerDetailMutation } from '@/query/dealers/query';
import { currentConsultantSelector, currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import { useSetRecoilState } from 'recoil';

type TDealer = Dealers.IDealersDetailRes['dealer'];

interface IProps {
  dealerId: number;
  consultantId?: number;
}

function useUpdateUserDealerConsultant() {
  const setCurrentDealer = useSetRecoilState(currentDealerSelector);
  const setCurrentConsultant = useSetRecoilState(currentConsultantSelector);
  const setSelfSelectedDealer = useSetRecoilState(selfSelectedDealerSelector);
  const { mutate: getDealerDetail } = useGetDealerDetailMutation();

  /**
   * 딜러, 컨설턴트 정보 업데이트
   *
   * @param dealerId store, cookie에 설정할 dealerId. 필수.
   * @param consultantId store, cookie에 설정할 consultantId. 옵셔널.
   */
  const updateStoreCookieForDealerSC = ({ dealerId, consultantId }: IProps) => {
    /**
     * 컨설턴트 정보 업데이트
     */
    const updateStoreConsultant = (dealer: TDealer) => {
      const selectedConsultant = dealer?.employees?.find(employee => employee.id === consultantId);

      if (selectedConsultant) setCurrentConsultant(selectedConsultant);
    };

    /**
     * dealer store 정보 업데이트
     */
    const updateStoreDealer = (response: Dealers.IDealersDetailRes) => {
      if (!response) return;
      const { dealer } = response;

      setSelfSelectedDealer(true);
      setCurrentDealer(dealer);

      if (consultantId) {
        updateStoreConsultant(dealer);
      }
    };

    /**
     * user dealer 정보 조회 api 연동
     */
    const updateUserDealerDetail = () => {
      getDealerDetail({ id: dealerId }, { onSuccess: updateStoreDealer });
    };

    updateUserDealerDetail();
  };

  return {
    updateStoreCookieForDealerSC,
  };
}

export default useUpdateUserDealerConsultant;
