import { ILogger, TLoginfo } from './interface';
import UAParser from 'ua-parser-js';
import os from 'os';
import { request } from 'http';

const consoleProxy: any = {
  log: console.log.bind(this, 'LOGGER:::LOG'),
  info: console.info.bind(this, 'LOGGER:::INFO'),
  warn: console.warn.bind(this, 'LOGGER:::WARN'),
  error: console.error.bind(this, 'LOGGER:::ERROR'),
  debug: console.debug.bind(this, 'LOGGER:::DEBUG'),
};

function isWindow() {
  return 'window' in globalThis;
}
function getCallStack() {
  const stacks = new Error('').stack;

  return stacks;
}

function getDeviceInfo() {
  const parser = new UAParser(Logger?.defaultInfo?.ua);
  const { device, os, browser, ua } = parser.getResult();

  return {
    ua: ua || '-',
    deviceType: device.type || (ua ? 'pc' : '-'),
    deviceModel: device.model || '-',
    browserName: browser.name || '-',
    browserVersion: browser.version || '-',
    osName: os.name || '-',
    osVersion: os.version || '-',
  };
}
function getServerInfo() {
  if (isWindow()) {
    return {};
  }

  return {
    pid: process.pid,
    hostName: os.hostname(),
    userIp: Logger?.defaultInfo?.userIp || '',
  };
}
function getCurrentUrl() {
  if (isWindow()) {
    return location.href;
  }

  return Logger?.defaultInfo?.url || __filename || '';
}
function getUserAgent(req: any) {
  if (req.headers?.['user-agent']) {
    return req.headers['user-agent'];
  }
  if (req?.rawHeaders) {
    const userAgentPoint = req?.rawHeaders.findIndex((v: any) => v === 'User-Agent');

    return req?.rawHeaders[userAgentPoint + 1];
  }
}

function onRuntimeError() {
  window.onerror = (message, source, line, col, error) => {
    const stack = `${source}:${line}:${col}`;

    if (/react\-dom/.test(stack)) {
      return false;
    }
    const logInfo = makeLogInfoFacade();

    logInfo.type = 'RUNTIME';
    logInfo.message = message.toString();
    logInfo.line = stack;
    logInfo.level = 'error';

    consoleProxy.error(error);
    Logger?.opt?.hook && Logger.opt.hook(logInfo);

    return true;
  };
}
function makeLogInfoFacade(option?: Partial<TLoginfo>): TLoginfo {
  const stack = getCallStack();
  const deviceInfo = getDeviceInfo();
  const serverInfo = getServerInfo();
  const url = getCurrentUrl();
  const timestamp = new Date().toJSON();
  const defaultOption = {
    ...Logger.defaultInfo,
    ...option,
  };

  return {
    applicationName: Logger?.opt?.applicationName || 'applicationName',
    env: Logger?.opt?.env || 'DEV',
    timestamp: timestamp,
    type: isWindow() === true ? 'WEB' : 'SERVER',
    line: stack.toString(),
    level: 'log',
    url: url,
    message: '',
    ...deviceInfo,
    ...serverInfo,
    ...defaultOption,
  };
}

const Logger: ILogger = {
  defaultInfo: {
    ua: '',
  },
  opt: {
    level: ['log', 'info', 'warn', 'debug', 'error'],
  },
  init(option) {
    this.opt = {
      ...this.opt,
      ...option,
    };
    if (option?.hook) {
      this.setHook(option?.hook);
    }
    if (isWindow()) {
      this.defaultInfo.ua = navigator.userAgent;
    }
  },
  setHook(hook) {
    this.opt.level?.forEach(level => {
      this[level] = new Proxy(this[level], {
        apply(target, that, args) {
          const logInfo = makeLogInfoFacade();

          hook &&
            hook({
              ...logInfo,
              level: level,
              message: typeof args === 'object' ? JSON.stringify(args) : args,
            });

          return target.apply(
            this,
            args.map(arg => arg?.message || arg),
          );
        },
      });
    });
    isWindow() && onRuntimeError();
  },
  api(error) {
    const url = getCurrentUrl();
    const stack = getCallStack();
    const { request, config, response } = error;
    const logInfo = {
      level: 'error',
      type: 'API',
      api: {
        url: request?.responseURL,
        data: config?.data,
        method: config?.method.toUpperCase(),
        headers: config?.headers,
        response: response?.data,
      },
      message: error.message,
      line: stack,
      url,
    };

    this?.opt?.hook && this?.opt?.hook(logInfo);
  },
  hook(data) {
    const logInfo = makeLogInfoFacade(data);

    this?.opt?.hook && this?.opt?.hook(logInfo);
  },
  setLogInfo(params) {
    this.defaultInfo = {
      ...this.defaultInfo,
      ...params,
    };

    return this;
  },
  setRequest(request) {
    const clientIP = request.headers?.['x-forwarded-for'] || request?.connection?.remoteAddress || '';

    this.defaultInfo = {
      ua: getUserAgent(request),
      userIp: clientIP,
      url: request.url,
    };

    return this;
  },
  ...consoleProxy,
};

export default Logger;
