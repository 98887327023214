import { css } from '@emotion/react';
import { colors, flex, font, layout } from './mixin';

const commonStyle = css`
  * {
    box-sizing: border-box !important;
    font-family: 'HyundaiSansHeadKR', 'Kanit', 'sans-serif' !important;
  }

  .use-pc {
    display: block !important;
  }
  .use-mobile {
    display: none !important;
  }

  .body-lock {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  html,
  body {
    font-size: 10px;
    -webkit-tap-highlight-color: transparent !important;
    min-height: -webkit-fill-available;
  }

  .scrollLock {
    overflow: hidden !important;
  }

  .scrollHide {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
  }

  .flex {
    ${flex('row', 'flex-start', 'center')};
    &.flex-column {
      ${flex('column', 'flex-start', 'flex-start')};
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @media ${layout.$tablet} {
      min-height: 100dvh;
    }
  }

  .header {
    + .content {
      // min-height: calc(100vh - 26rem);
      padding-top: 7rem;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .content {
    flex: 1;
    // min-height: calc(100vh - 7rem - 26rem);
  }

  .breadcrumb-container {
    width: 100%;
    padding: 2.1rem 2rem;
    margin-top: 5rem;
    border-bottom: 1px solid ${colors.$color_d9d};
    .breadcrumb-area {
      ${flex('row', 'space-between', 'center')};
      max-width: ${layout.$content_size};
      margin: 0 auto;
    }
  }
  .swiper-pagination-bullet:only-child {
    visibility: hidden;
    display: none;
  }
  .swiper-pagination-container {
    .swiper-pagination {
      position: static;
      .swiper-pagination-bullet {
        opacity: 1;
        width: 1rem;
        height: 1rem;
        display: inline-block;
        border-radius: 100%;
        background-color: ${colors.$color_ccc};
        &:not(:first-of-type) {
          margin-left: 1.2rem;
        }
      }
      .swiper-pagination-bullet-active {
        background-color: ${colors.$secondary1};
      }
    }
  }

  .price-box {
    ${flex('row', 'flex-start', 'center')};
    .price {
      ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
    }
    .unit {
      margin-left: 0.4rem;
      ${font('1.4rem', 500, colors.$color_000, '2rem')};
    }
  }

  .hidden {
    display: none;
  }

  .pdf-only {
    display: none;
  }

  div {
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.2rem;
    }
    &::-webkit-scrollbar-track {
      // 전체
      // background: ${colors.$color_fff};
      // border-radius: 0.2rem;
    }
  }

  fieldset {
    min-width: unset;
  }

  *[tabindex] {
    outline: none;
    -webkit-tap-highlight-color: ${colors.$color_0A_0};
  }

  @media ${layout.$tablet} {
    .breadcrumb-container {
      padding: 2.5rem 2rem;
      margin-top: 0;
    }
  }

  @media ${layout.$mobile} {
    * {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      &::-webkit-scrollbar {
        display: none !important; /* Chrome, Safari, Opera*/
      }
    }
    .use-pc {
      display: none !important;
    }
    .use-mobile {
      display: block !important;
    }
    .header {
      + .content {
        min-height: auto;
        padding-top: 5.6rem;
      }
    }
    .page-title-container {
      border-bottom: 1px solid ${colors.$color_d9d};
      .page-title-area {
        ${flex('row', 'flex-start', 'center')};
        padding: 1.6rem 2rem;
        background-color: ${colors.$color_fff};
        .title-box {
          ${font('1.8rem', 400, colors.$color_000, '2.4rem')};
        }
      }
      + .breadcrumb-container {
        border: 0;
        .breadcrumb-right {
          display: none;
        }
      }
    }
    .breadcrumb-container {
      padding: 0;
      .breadcrumb-area {
        display: block;
      }
      .breadcrumb-right {
        height: 4.8rem;
        padding: 0 2rem;
        background-color: ${colors.$color_264C77};
      }
    }
  }
`;

export default commonStyle;
