import dayjs from 'dayjs';

export const getDayjsObjWithoutYMD = (dateString: string): dayjs.Dayjs => {
  const YMD = dayjs()?.format('YYYY-MM-DD');

  if (typeof dayjs(`${YMD} ${dateString}`, 'YYYY-MM-DD HH:mmZ')?.tz !== 'function')
    return dayjs(`${YMD} ${dateString}`, 'YYYY-MM-DD HH:mmZ');

  return dayjs(`${YMD} ${dateString}`, 'YYYY-MM-DD HH:mmZ')?.tz();
};
