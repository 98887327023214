import React, { useEffect, useRef } from 'react';
import FormItem from './FormItem';
import FormContext from './FormContext';
import { FormProps } from './type';
import useForm from './hooks/useForm';
import classNames from 'classnames';

function Form<T extends object>({
  children,
  form: formProps,
  onSubmit,
  onChange,
  initialValues,
  ref,
  className,
}: FormProps<T>) {
  const mountRef = useRef(null);
  const formInstance = useForm<T>();
  const form = formProps ?? formInstance;

  const { setInitialValues, checkValid } = form?.getInternalProperty() ?? {};

  useEffect(() => {
    setInitialValues(initialValues, !mountRef.current);

    if (!mountRef.current) {
      mountRef.current = true;
    }
  }, [form]);

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const valid = checkValid();

    onSubmit && (await onSubmit(form.getFieldsValue(), form.getFieldsValueAll(), valid));
  };

  form.submit = handleSubmit;

  const handleReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // form.resetFields(initialValues);

    form.setFieldsValue(initialValues ?? {});
  };

  const onValuesChange = (value: any) => {
    onChange && onChange(value, form.getFieldsValue(), form.getFieldsValueAll());
  };

  return (
    <FormContext.Provider
      value={{
        fields: form.getFieldsValueAll(),
        setFields: form.setFieldsValue,
        form,
        onValuesChange,
      }}
    >
      <form ref={ref} onSubmit={handleSubmit} onReset={handleReset} className={classNames('haefe-form', className)}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.Item = FormItem;

Form.useForm = useForm;

export default Form;
