import { css } from '@emotion/react';
import { colors, flex, font, layout } from './mixin';

const badgeStyle = css`
  .badge-box {
    ${flex('row', 'flex-start', 'flex-start')};
    .badge {
      ${flex('row', 'center', 'center')};
      width: fit-content;
      height: 2.4rem;
      padding: 0 0.8rem;
      background-color: ${colors.$primary};
      ${font('1.2rem', 400, '$color_fff', '1.8rem')};
      &.secondary {
        background-color: ${colors.$secondary1};
      }
      &:not(:first-of-type) {
        margin-left: 0.2rem;
      }
    }
  }
`;

export default badgeStyle;
