import { css } from '@emotion/react';
import { colors, flex, font, layout } from './mixin';

const formStyle = css`
  legend {
    .title-box {
      ${flex('column', 'flex-start', 'flex-start')};
      ${font('1.6rem', 500, colors.$color_000, '2.4rem')};
    }
    .desc {
      margin-top: 0.8rem;
      ${font('1.4rem', 400, colors.$color_767, '2rem')};
    }
  }
  .field-box {
    ${flex('row', 'space-between', 'flex-end')};
    flex-wrap: wrap;
    margin-top: 3.2rem;
    &:first-of-type {
      margin-top: 0;
    }
    &.col-2 {
      &:first-of-type {
        margin-top: 0;
      }
    }
    &.col-3 {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .field-label {
    display: block;
  }

  .required {
    .field-label {
      &::after {
        content: '*';
        padding-left: 0.4rem;
        color: ${colors.$secondary1};
      }
    }
  }

  .form-top-box {
    ${flex('row', 'space-between', 'flex-start')};
    + fieldset {
      margin-top: 2.8rem;
    }
  }
  .required-noti-box {
    display: block;
    width: 100%;
    ${font('1.4rem', 400, colors.$color_000, '2rem')};
    text-align: right;
    &::after {
      content: '*';
      padding-left: 0.4rem;
      color: ${colors.$secondary1};
    }
  }
  @media ${layout.$tablet} {
    .field-box {
      &.col-2 {
      }
      &.col-3 {
        margin: -1.6rem -0.8rem;
      }
    }
  }

  @media ${layout.$mobile} {
    .field-box {
      margin-top: 3.6rem;
      &.col-2 {
      }
      &.col-3 {
        margin: 3.6rem 0 0 0;
      }

      .dropdown-dealer {
        .dropdown-lists {
          z-index: 13;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          top: auto;
          max-height: 93%;
          padding: 0;
          border: 0;
          border-top-left-radius: 1.2rem;
          border-top-right-radius: 1.2rem;
          &::before {
            content: '';
            z-index: 12;
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
          }
          .option {
            z-index: 14;
            position: relative;
            margin: 0;
            padding: 1.2rem 0 0;
            background-color: ${colors.$color_fff};
            border: 0;
            &:first-of-type {
              padding-top: 4rem;
            }
            &:last-child {
              padding-bottom: 4rem;
            }
            &.selected {
              .dealer-block-button {
                .dealer-block {
                  border: 0.2rem solid ${colors.$secondary1};
                }
              }
              button {
                position: relative;
                &::after {
                  display: none;
                }
              }
            }

            .dealer-block-button {
              padding: 0 2rem;
              border: 0;
              .dealer-block {
                width: 100%;
                border: 1px solid ${colors.$color_ddd};
              }
            }
          }
        }
      }
    }

    .form-top-box {
      position: relative;
      + fieldset {
        margin-top: 3rem;
      }
      .location-button-box,
      .location-box {
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
      }
    }

    legend {
      .desc {
        margin-top: 1.2rem;
      }
    }
  }
`;

export default formStyle;
