import { css } from '@emotion/react';
import { colors } from 'styles/mixin';

const emptyStyle = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 0;

  .empty-text {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    color: ${colors.$color_000};
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    line-height: 2rem;

    em {
      margin-bottom: 1.2rem;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;
    }
  }
`;

export default emptyStyle;
