import { gaAttributeName } from '@/constants/common';

const { BOOKING_PAYMENT, CTB, CONFIGURATION, STATUS, MYGARAGE } = gaAttributeName;

/** HMG_COMMON */
const getGaPageType = (propsMenu: string) => {
  if (!propsMenu) return;

  const menu = propsMenu?.toUpperCase() || null;

  switch (menu) {
    case STATUS.CTB:
      return CTB.PAGE_TYPE;
    case STATUS.CONFIGURATION:
      return CONFIGURATION.PAGE_TYPE;
    case STATUS.MYGARAGE:
      return MYGARAGE.PAGE_TYPE;
    case STATUS.QUOTATION:
      return;
    default:
      return null;
  }
};

/**
 * Component Attribute에 GA에 맞춰 Setting
 */
const gaAttributes = ({ category = CTB.CATEGORY, action, label = '' }: Global.IGaAttributes) => {
  if (typeof label === 'object') {
    label = label?.categoryDisplayName ?? 'ALL';
  }

  return {
    [`data-link-category`]: category,
    [`data-link-action`]: action,
    [`data-link-label`]: label || [''],
  };
};

/**
 * 배너 GA Attribute setting
 */
const gaAttributeBanner = ({ category = CTB.CATEGORY, bannerTitle = '', bannerNo = null }: Global.IGaAttributes) => {
  return {
    [`data-link-category`]: category,
    [`data-promo-name`]: bannerTitle,
    [`data-promo-promo-id`]: bannerNo,
  };
};

/**
 * handleClickGA
 * @param: data: any, type: string
 * CTB Base : code [차량코드], name [차량영문명]
 * VARIANT : variant [선택Variant], price [선택된가격]
 * Color : exterior_color [선택된 외장색상], interior_color [선택된 내장색상]
 * Summary : dealership [선택된 Dealership]
 */
const handleClickGA = (data: any, type: string) => {
  if (!data && !type) return;

  const { variant, variants, exterior, interior, dealerInfo } = data; // configuration
  const { vehicleVariant, exteriorColor, interiorColor, dealership } = data; // My garage
  const customWindow = window as Global.IWindow;

  /** configuration 진입단계 */
  const configurationInit = {
    event: CONFIGURATION.BYO_STEP_INIT,
    vehicle_code: data?.modelCode || variants?.modelCode || variants?.[0].modelCode,
    vehicle_name: data?.modelDisplayName || variants?.modelDisplayName || variants?.[0].modelDisplayName,
  };

  /** configuration내 variant 선택 */
  const configurationVariant = {
    event: CONFIGURATION.BYO_STEP_STEP_1,
    vehicle_code: data?.modelCode,
    vehicle_name: data?.modelDisplayName,
    vehicle_variant: data?.variantDisplayName || variant?.variantDisplayName,
    vehicle_price: data?.productPrice || variant?.productPrice,
  };

  /** configuration내 color 선택 */
  const configurationColor = {
    event: CONFIGURATION.BYO_STEP_STEP_2,
    vehicle_code: data?.vehicleCode,
    vehicle_name: data?.vehicleName,
    vehicle_variant: data?.vehicleVariant,
    vehicle_price: data?.vehiclePrice,
    vehicle_exterior_color: data?.exteriorColor,
    vehicle_interior_color: data?.interiorColor,
  };

  /** configuration내 dealer 선택 */
  const configurationDealer = {
    event: CONFIGURATION.SELECT_A_DEALER,
    province: data?.province,
    district: data?.district,
    [`sub-district`]: data?.subDistrict,
  };

  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    /* configuration 최초 진입 단계 */
    if (type === STATUS.CONFIGURATION) {
      customWindow.HMG_COMMON_TAG.page_type = CONFIGURATION.PAGE_TYPE;
      customWindow.dataLayer.push({ ...configurationInit });
    }

    /* step1. variant 선택 */
    if (type === CONFIGURATION.VARIANT) {
      customWindow.dataLayer.push({ ...configurationVariant });
    }

    /* step2. color 선택 */
    if (type === CONFIGURATION.COLOR) {
      customWindow.dataLayer.push({ ...configurationColor });
    }

    // 견적 시 or 견적후 로드 페이지
    if ([STATUS.QUOTATION, STATUS.QUOTATION_DETAIL].includes(type)) {
      const isQuation = type === STATUS.QUOTATION;

      customWindow.HMG_COMMON_TAG.page_type = type === STATUS.QUOTATION ? CONFIGURATION.PAGE_TYPE : MYGARAGE.PAGE_TYPE;
      /**
       * 1. QUOTATION :  configuration에서 Next단계 (Quotation 성공시) 진입시 수행
       * 2. QUOTATION_DETAIL : QUOTATION_DETAIL 단계*/
      customWindow.dataLayer.push({
        event: isQuation ? CONFIGURATION.BYO_STEP_STEP_3 : CONFIGURATION.BYO_STEP_COMPLETE,
        vehicle_code: isQuation ? variant?.modelCode : vehicleVariant?.modelCode || '', //차량코드
        vehicle_name: isQuation ? variant?.modelDisplayName : vehicleVariant?.modelName, //차량영문명
        vehicle_variant: isQuation ? variant?.variantDisplayName : vehicleVariant?.variantName, //선택된 variant
        vehicle_price: isQuation ? variant?.productPrice : vehicleVariant?.price, // 선택된가격
        vehicle_exterior_color: isQuation ? exterior?.colorDisplayName : exteriorColor?.name,
        vehicle_interior_color: isQuation ? interior?.colorDisplayName : interiorColor?.name,
        vehicle_dealership: isQuation ? dealerInfo?.dealer?.district : dealership?.address?.district,
      });
    }

    if (type === CONFIGURATION.SELECT_A_DEALER) {
      customWindow.dataLayer.push({ ...configurationDealer });
    }

    if (type === STATUS.BOOKING_PAYMENT) {
      // BOOKING_PAYMENT 페이지 진입
      customWindow.dataLayer.push({
        event: BOOKING_PAYMENT.BEGIN_CHECKOUT,
        ecommerce: {
          currency: BOOKING_PAYMENT.CURRENCY, //ex.THB {{통화코드}}
          value: data?.totalAmount || '', //ex.2399000 금액
          items: [
            {
              item_id: data?.vehicleVariant?.modelCode || '', //ex.SKU3230 차량코드
              item_name: data?.vehicleVariant?.modelName || '', //ex.STARIA 차량명
              affiliation: process.env.NEXT_PUBLIC_GA_BRAND, //고정 hyundai
              currency: BOOKING_PAYMENT.CURRENCY, //ex.THB 통화코드
              discount: 0, //ex.0 할인금액
              item_brand: process.env.NEXT_PUBLIC_GA_BRAND, //ex.hyundai 브랜드명
              // item_category: '', //ex.MPV 차종
              item_variant: data?.exteriorColor?.name || '', //ex.Black 색상
              price: data?.totalAmount || '', //ex.2399000 금액
              quantity: 1, //ex.1 수량
            },
          ],
        },
      });
    }
  }
};

/**
 * QuickMenu Modal 진입후 Submit시 dataLayer Push (현재 : test drive)
 */
const handleQuickMenuGA = (data: Global.IGaDataLayer, title: string, category: Global.TGaCategory) => {
  if (!data && !title) return;
  const customWindow = window as Global.IWindow;

  const quickMenuTitle = (title: string) => {
    return title
      .replace(/([A-Z])/g, '_$1')
      .replace(/^-/, '')
      .toLowerCase();
  };

  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    customWindow.dataLayer.push({
      event: `request_${quickMenuTitle(title)}_${category}`,
      model: data?.modelDisplayName || '',
      ...(category === 'complete' && { dealer: data?.district || '', province: data?.province || '' }),
    });
  }
};

/* Booking Payment 예약 완료 */
const handleBookingCompleteGA = (data: Quotation.IQuotationDetailRes) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    const customWindow = window as Global.IWindow;

    customWindow.dataLayer.push({
      event: BOOKING_PAYMENT.PURCHASE,
      payment: data?.payInfo?.booking?.paymentType || '', //ex.  CreditCard, Cash, LinePay 결제수단
      ecommerce: {
        transaction_id: data?.payInfo?.booking?.transactionId || '', // 주문번호
        currency: BOOKING_PAYMENT.CURRENCY, //ex.THB 통화코드
        value: data?.bookingPrice || 0, // 금액
        items: [
          {
            item_id: data?.vehicleVariant?.modelCode || '', // 차량코드
            item_name: data?.vehicleVariant?.modelName || '', // 차량명
            affiliation: process.env.NEXT_PUBLIC_GA_BRAND, //고정 hyundai
            currency: BOOKING_PAYMENT.CURRENCY, // 통화코드
            discount: 0, // 할인
            item_brand: process.env.NEXT_PUBLIC_GA_BRAND, //ex.hyundai 브랜드명
            // item_category: '', // 차종 현재 차종구분이 어려우므로 주석처리
            item_variant: data?.exteriorColor?.name || '', // 색상
            price: data?.totalAmount || 0, // 금액
            quantity: 1, // 수량
          },
        ],
      },
    });
  }
};

export { gaAttributes, handleClickGA, handleQuickMenuGA, handleBookingCompleteGA, getGaPageType, gaAttributeBanner };
