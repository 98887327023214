import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const HeaderStyle = css`
  position: fixed;
  width: 100%;
  z-index: 98;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  &.hide-header:not(.open) {
    transform: translateY(-100%);
  }

  .header-container {
    position: relative;
    width: 100%;
    height: 12.5rem;
    padding: 0;
    border-bottom: 1px solid ${colors.$color_d9d};
    background-color: ${colors.$color_fff};
    z-index: 98;
  }
  .header-area {
    ${flex('row', 'space-between', 'center')};
    position: relative;
    max-width: ${layout.$content_size};
    height: 70%;
    margin: 0 auto;
    .haefe-modal-bg,
    .dim {
      z-index: 0;
      // display: none;
    }
    .left-box {
      &.use-mobile {
        padding: 0.8rem 2rem;
        .logo {
          ${flex('row', 'flex-start', 'center')};
        }
        .logo-box {
          ${flex('row', 'flex-start', 'center')};
          width: fit-content;
          .hyundai-logo {
            width: 4rem;
          }
        }
        .menu-name {
          margin-left: 0.8rem;
          ${font('1.8rem', 400, colors.$color_000, '2.7rem')};
        }
      }
      .logo-box {
        display: block;
        .hyundai-logo {
          display: block;
          width: 15rem;
          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .right-box {
      ${flex('row', 'flex-start', 'center')};
      button {
        & + button {
          margin-left: 2rem;
        }
      }
      .btn-compare {
        position: relative;
        .count {
          ${flex('row', 'center', 'center')};
          position: absolute;
          top: 0.1rem;
          right: -0.2rem;
          width: 1.6rem;
          height: 1.6rem;
          padding-top: 0.1rem;
          border-radius: 50%;
          background-color: ${colors.$secondary1};
          ${font('0.8rem', 500, colors.$color_fff, '0.9rem')};
        }
      }
      .user-box {
        position: relative;
        margin-left: 2rem;
        &.active {
          .user-option-box {
            display: block;
          }
        }
      }
      .user-option-box {
        &::before {
          content: '';
          position: absolute;
          top: -1rem;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1rem solid ${colors.$color_fff};
          border-left: 0.6rem solid transparent;
          border-right: 0.6rem solid transparent;
          filter: drop-shadow(0 -0.3rem 0.2rem ${colors.$color_ede});
        }
        display: none;
        position: absolute;
        top: 3.625rem;
        left: 50%;
        transform: translateX(-50%);
        width: 10rem;
        height: 7.5rem;
        z-index: 99;
        background-color: ${colors.$color_fff};
        box-shadow: 0 0 0.7rem 0.2rem ${colors.$color_ede};
        .option-list {
          ${flex('column', 'center', 'center')};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          .option {
            &.selected {
              .btn-option {
                color: ${colors.$color_666};
              }
            }
          }
          .btn-option {
            width: auto;
            height: auto;
            ${font('1.2rem', 500, colors.$color_999, '2rem')};
          }
        }
      }
      .language-box {
        position: relative;
        margin-left: 2rem;
        &.active {
          .language-option-box {
            display: block;
          }
        }
      }
      .language-option-box {
        &::before {
          content: '';
          position: absolute;
          top: -1rem;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1rem solid ${colors.$color_fff};
          border-left: 0.6rem solid transparent;
          border-right: 0.6rem solid transparent;
          filter: drop-shadow(0 -0.3rem 0.2rem ${colors.$color_ede});
        }
        display: none;
        position: absolute;
        top: 3.625rem;
        left: 50%;
        transform: translateX(-50%);
        width: 10rem;
        height: 7.5rem;
        background-color: ${colors.$color_fff};
        box-shadow: 0 0 0.7rem 0.2rem ${colors.$color_ede};
        z-index: 99;
        .option-list {
          ${flex('column', 'center', 'center')};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .option {
            &.selected {
              .btn-option {
                color: ${colors.$color_666};
              }
            }
          }
          .btn-option {
            width: auto;
            height: auto;
            ${font('1.2rem', 500, colors.$color_999, '2rem')};
          }
        }
      }
    }
  }
  .gnb-container {
    flex: 1;
    z-index: 11;
    width: 100%;
    height: 100%;
    padding-right: 6rem;
    .mobile-gnb-area {
      display: none;
      position: relative;
      &::before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.7rem;
        background: linear-gradient(180deg, rgba(235, 233, 229, 0.8) 0%, rgba(217, 217, 217, 0) 42.71%);
      }
      .mobile-gnb-box {
        ${flex('column', 'flex-start', 'center')};
        .login-button-box {
          ${flex('column', 'flex-start', 'center')};
          width: 100%;
          padding: 2rem 1rem 0;
          button {
            max-width: 32rem;
            width: 100%;
          }
        }
      }
      .language-box {
        width: 100%;
        &.active {
          background-color: ${colors.$color_f4f};
          .btn-select-language {
            background-color: transparent;
            .ic-language-arrow {
              transform: rotate(180deg);
            }
          }
          .language-option {
            display: block;
          }
        }
        .btn-select-language {
          ${flex('row', 'center', 'center')};
          position: relative;
          width: 100%;
          height: 3.9rem;
          ${font('1.3rem', 400, colors.$color_000, '2.4rem')};
          background-color: ${colors.$color_fff};
          .ic-language-arrow {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
          }
        }
        .language-option {
          display: none;
        }
        .btn-language-option {
          width: 100%;
          height: 3.9rem;
          ${font('1.3rem', 400, colors.$color_666, '2.4rem')};
        }
      }
    }
    .gnb-area {
      height: 100%;
    }
    .gnb-box {
      ${flex('row', 'center', 'center')};
      height: 100%;
    }
    .gnb-list {
      position: relative;
      height: 100%;
      &.active {
        .gnb-link {
          color: ${colors.$color_000};
          &::after {
            display: block;
          }
        }
      }
      .gnb-link {
        ${flex('row', 'space-between', 'center')};
        padding: 0 1rem;
        margin: 0 0.8rem;
        height: 100%;
        ${font('1.4rem', 400, colors.$color_666, 'normal')};
        &::after {
          content: '';
          display: none;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0.4rem;
          background-color: ${colors.$secondary1};
        }
      }
    }
  }

  .gnb-BeaDealer {
    position: absolute;
    right: -21rem;
    bottom: -2.7rem;
    > a {
      padding: 0.6rem 1.1rem 0.6rem 4.4rem;
      display: block;
      ${font('1.4rem', 400, colors.$color_000, 'normal')};
      font-family: 'HyundaiHRegular';
      border: 1px solid #d9d9d9;
      background: url('/assets/images/ico_be_a_dealer.png') 1.1rem 50% no-repeat;
    }
  }

  @media ${layout.$tablet} {
    &.open {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        background-color: rgba(${colors.$color_000}, 0.7);
      }
      .header-container {
        height: 100%;
        border: none;
        background-color: transparent;
      }
      .header-area {
        .left-box {
          &.use-mobile {
            border-bottom: 1px solid ${colors.$color_d9d};
            background-color: ${colors.$color_fff};
          }
        }
      }
      .gnb-container {
        right: 0;
        padding: 0;
        padding-right: env(safe-area-inset-right);
        background-color: ${colors.$color_fff};
        .mobile-gnb-area {
          display: block !important;
        }
      }
      .gnb-BeaDealer {
        position: static;
        height: 6rem;
        margin: 0;
        ${font('1.6rem', 400, colors.$color_666, '2.4rem')};
        > a {
          padding: 2.1rem 1.1rem 2.1rem 5rem;
          width: 100%;
          border: 0;
          background-position: 1.8rem 50%;
        }
      }
    }
    .header-area {
      display: block;
      max-width: unset;
      .left-box {
        position: relative;
        &.use-pc {
          display: none !important;
        }
        &.use-mobile {
          display: block !important;
          padding: 1.5rem 2rem;
          .logo-box {
            .menu-name {
              display: block;
            }
          }
        }
      }
      .right-box {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        .use-pc {
          display: none !important;
        }
        .use-mobile {
          display: block !important;
        }
        .btn-hamburger {
          margin-left: 1.6rem;
        }
      }
    }
    .header-container {
      height: 7rem;
      padding: 0;
    }
    .gnb-container {
      position: relative;
      right: -100%;
      width: calc(100% - 3rem);
      max-width: 35rem;
      height: calc(100vh - 5.6rem);
      margin-left: auto;
      background-color: ${colors.$color_f4f};
      transition: 0.3s ease;
      .gnb-box {
        ${flex('column', 'flex-start', 'flex-start')};
        height: fit-content;
        background-color: ${colors.$color_fff};
      }
      .gnb-list {
        width: 100%;
        height: fit-content;
        border-top: 1px solid ${colors.$color_e4e};
        &:last-of-type {
          border-bottom: 1px solid ${colors.$color_e4e};
        }
        .gnb-link {
          height: 6rem;
          margin: 0;
          padding: 0 1.1rem 0 1.6rem;
          ${font('1.6rem', 400, colors.$color_666, '2.4rem')};
          .use-mobile {
            display: block !important;
          }
        }
        &.active {
          .gnb-link {
            color: ${colors.$color_666};
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1270px) {
    // 언어 선택 박스 위치 수정
    .header-area {
      .right-box {
        .language-option-box {
          left: auto;
          right: 0rem;
          transform: none;
          &::before {
            left: auto;
            right: 0.4rem;
            transform: none;
          }
        }
      }
    }
  }
  @media ${layout.$mobile} {
    &.open {
      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: 20;
      //   width: 100%;
      //   height: 100%;
      //   background-color: rgba(0, 0, 0, 0.7);
      // }
      .header-container {
        height: 100%;
        border: none;
        background-color: transparent;
      }
      .header-area {
        .left-box {
          &.use-mobile {
            border-bottom: 1px solid ${colors.$color_d9d};
            background-color: ${colors.$color_fff};
          }
        }
      }
      .gnb-container {
        display: block;
      }
    }
    .header-container {
      height: 5.6rem;
      padding: 0;
    }
    .header-area {
      .left-box {
        &.use-mobile {
          padding: 0.8rem 2rem;
        }
      }
      .right-box {
        top: 1.8rem;
      }
    }
  }
`;

export default HeaderStyle;
