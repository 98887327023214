import React, { useCallback, useRef } from 'react';
import { IModalProps } from '../type';
import classNames from 'classnames';
import ModalWrapper from '@/components/haeLibraryRefactored/Modal/components/modalWrapper';

function Modal({
  className,
  visible,
  closeModal = () => null,
  backDropClose = true,
  displayCloseButton = true,
  modalTitle = '',
  size = 'small-popup',
  modalWrapperClassName = '',
  modalWrapperStyle,
  children,
  footer,
  displayNoneFooter = true,
  css,
  backgroundScroll,
  ...otherProps
}: IModalProps) {
  const modalRef = useRef(null);

  const handleFocusCloseButton = useCallback((e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
    }
  }, []);

  return (
    <ModalWrapper
      backDropClose={backDropClose}
      open={visible}
      closeModal={() => closeModal()}
      wrapperClassName={`${size} ${modalWrapperClassName}`}
      style={modalWrapperStyle}
      backgroundScroll={backgroundScroll}
    >
      <div
        className={classNames(className, `modal-wrap-class  modal-inner `)}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-label"
        css={css}
        {...otherProps}
      >
        <div className="modal-area">
          <div className={`modal-top modal-header `}>
            <div
              className="modal-title modal-title-class"
              id="modal-label"
              style={{ opacity: modalTitle ? 1 : 0 }}
              tabIndex={0}
              ref={modalRef}
              onKeyDown={handleFocusCloseButton}
            >
              {modalTitle}
            </div>
            {displayCloseButton && (
              <button type="button" onClick={() => closeModal()} className="btn-close modal-closeButton" />
            )}
          </div>
          <div className="modal-middle modal-content" tabIndex={0}>
            {children}
          </div>
          {displayNoneFooter && (
            <div className="modal-bottom modal-footer" tabIndex={0}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

export default Modal;
