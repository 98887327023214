import getCharByteSize from './getCharByteSize';

const getByteLengthOfString = (string: string): number => {
  if (!string?.length) return 0;

  let charBytes = 0;

  for (let i = 0; i < string.length; i++) {
    charBytes += getCharByteSize(string.charAt(i));
  }

  return charBytes;
};

export default getByteLengthOfString;
