import ConsultantChangeModal from '@/components/modules/consultantChangeModal';
import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import consultantBlockTopStyle from './style';

interface IConsultantBlockTopProps {
  title: string;
  dealerId: number;
  defaultEmployeeId?: number;
  hasChange: boolean;
  handleChange: (input: Users.IEmployeeListRes) => void;
}

function ConsultantBlockTop({ title, hasChange, handleChange, dealerId, defaultEmployeeId }: IConsultantBlockTopProps) {
  const ref = useRef(null);
  const { t } = useTranslation();

  const handleClickChange = () => {
    ref?.current?.openConsultantChangeModal();
  };

  return (
    <div className="dealer-block-top" css={consultantBlockTopStyle}>
      <div className="dealer-block-top-left">
        <div className="title-box">
          <span className="consultant-title">{title}</span>
        </div>
      </div>
      {hasChange && (
        <div className="dealer-block-top-right">
          <div className="change-button-box">
            <button onClick={handleClickChange} type="button" className="btn btn-text">
              <span>{t('Label.Change')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsultantBlockTop;
