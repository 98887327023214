import { changeDealerModalPropsSelector, globalToastModalPropsSelector } from '@/stores/common';
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useSetRecoilState } from 'recoil';
import { IChangeDealerModalProps } from '@/components/modules/changeDealerModal';

type TOpenChangeDealerModal = (params: Omit<IChangeDealerModalProps, 'visible'>) => void;

/**
 * Change Dealer Modal의 딜러 변경 메시지 Toast open 함수
 */
type TOpenChangeSuccessToast = () => void;

const useChangeDealer = (): {
  openChangeDealerModal: TOpenChangeDealerModal;
  openSuccessToast: TOpenChangeSuccessToast;
} => {
  const setChangeDealerProps = useSetRecoilState(changeDealerModalPropsSelector);
  const setToastProps = useSetRecoilState(globalToastModalPropsSelector);
  const { t } = useTranslation();

  const isChangedOnlySc = useRef(null);

  const openChangeDealerModal = (params: Omit<IChangeDealerModalProps, 'visible'>) => {
    const callback = (info: {
      dealer?: Dealers.TDealerEntity;
      consultant?: Global.IEmployeesEntity;
      isChangedOnlySc?: boolean;
    }) => {
      isChangedOnlySc.current = info.isChangedOnlySc;

      if (typeof params.callback === 'function') params.callback(info);
    };

    setChangeDealerProps({ ...params, callback: callback });
  };

  const openSuccessToast = () => {
    setToastProps({
      button: false,
      icon: false,
      message: isChangedOnlySc.current ? t('Alert.ToastChangeOfSC') : t('Alert.ToastChangeOfDealer'),
    });
  };

  return { openChangeDealerModal, openSuccessToast };
};

export default useChangeDealer;
