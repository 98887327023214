import { IChangeDealerModalProps } from '@/components/modules/changeDealerModal';
import { atom, selector } from 'recoil';

interface IToastModal {
  className?: string;
  icon: boolean;
  button: boolean;
  modalWrapperClassName?: string;
  message: React.ReactNode;
  closeModal?: () => void;
}

type TChangeDealerModal = Omit<IChangeDealerModalProps, 'visible'>;

//#region layout
export const showLayoutState = atom({
  key: `stores.common.atom.showLayout`,
  default: true,
});

export const showLayoutSelector = selector({
  key: `stores.common.selector.showLayout`,

  get: ({ get }) => get(showLayoutState),
  set: ({ set }, value) => set(showLayoutState, value),
});
//#endregion

//#region globalSpinner
export const globalSpinnerState = atom<boolean>({
  key: 'stores.common.atom.globalSpinnerState',
  default: false,
});
//#endregion

//#region globalAlert
export const globalAlertPropsState = atom<Global.IAlert>({
  key: 'stores.common.atom.globalAlertState',
  default: null,
});

export const globalAlertPropsSelect = selector<Global.IAlert>({
  key: 'stores.common.selector.globalAlertPropsSelect',
  set: ({ set }, value) => set(globalAlertPropsState, value),
  get: ({ get }) => {
    const state = get(globalAlertPropsState);

    if (!state) {
      return null;
    }

    return {
      ...state,
      text: state?.message,
    };
  },
});
//#endregion

//#region MobileMenu
export const showMobileMenuState = atom({
  key: 'stores.common.atom.showMobileMenu',
  default: false,
});

export const showMobileMenuSelector = selector({
  key: `stores.common.selector.showMobileMenu`,

  get: ({ get }) => get(showMobileMenuState),
  set: ({ set }, value) => set(showMobileMenuState, value),
});
//#endregion

//#region DealerModal
export const showDealerModalState = atom({
  key: 'stores.configuration.atom.showDealerModal',
  default: false,
});
//#endregion

export const globalToastModalPropsState = atom<IToastModal>({
  key: 'stores.common.atom.globalToastModalState',
  default: null,
});
export const globalToastModalPropsSelector = selector<IToastModal>({
  key: 'stores.common.selector.globalToastModalPropsSelector',
  set: ({ set }, value) => set(globalToastModalPropsState, value),
  get: ({ get }) => {
    const state = get(globalToastModalPropsState);

    if (!state) {
      return null;
    }

    return state;
  },
});

export const changeDealerModalPropsState = atom<TChangeDealerModal>({
  key: 'stores.common.atom.changeDealerModalPropsState',
  default: null,
});
export const changeDealerModalPropsSelector = selector<TChangeDealerModal>({
  key: 'stores.common.selector.changeDealerModalPropsSelector',
  set: ({ set }, value) => set(changeDealerModalPropsState, value),
  get: ({ get }) => {
    const state = get(changeDealerModalPropsState);

    if (!state) {
      return null;
    }

    return state;
  },
});
