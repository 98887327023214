import { css } from '@emotion/react';

const spinnerStyle = (size: string) => css`
  &.ic.ic-payment-progress {
    animation: rotate 1s linear infinite;
    width: ${size};
    height: ${size};
    background-size: ${size};
  }
`;

export default spinnerStyle;
