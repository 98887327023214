import React, { useEffect, useState } from 'react';
import Button from '@components/units/button';
import Modal from '@/components/modules/modals/modal';
import AlertModal from '@/components/modules/modals/alertModal';
import ChangeDealerContainer, {
  ChangeDealerContainerProps,
} from '@/components/modules/changeDealerModal/components/container';
import changeDealerModalStyle from '@/components/modules/changeDealerModal/style';
import RegionSelectForm from '@/components/modules/regionSelectForm';
import { changeDealerModalPropsSelector, globalAlertPropsState } from '@/stores/common';
import { useDealersListByType, useGetDealerListByRegion } from '@/query/dealers/query';
import { getKmFromLatAndLon } from '@/utils/convert';
import {
  currentConsultantSelector,
  currentDealerSelector,
  selfSelectedDealerSelector,
  userSelector,
} from '@/stores/common/user';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { useAutoAssign } from '@/query/employee/query';
import ChangeDealerModalHeader from './components/header';
import { DEALER_BASE_TYPE, DEALER_LIST_TYPE } from './constants';
import { handleClickGA } from '@/utils/gaCommon';
import { gaAttributeName } from '@/constants/common';
import { useUpdateAssociatedDealer } from '@/query/users/query';
import useMember from '@/hooks/useIsMeber';

export interface ISelectedDealerConsultant {
  dealer?: Dealers.TDealerEntity;
  consultant?: Global.IEmployeesEntity;
  isChangedOnlySc?: boolean;
}

export interface IChangeDealerModalProps {
  /**
   * 컨설턴트 선택 드롭다운 비노출 여부
   */
  hideConsultantSelect?: ChangeDealerContainerProps['hideConsultantSelect'];
  /**
   * 딜러선택 완료 후 callback
   */
  callback?: (info: ISelectedDealerConsultant) => void;
  /**
   * 딜러 목록 표출을 위한 필수 필드 목록
   */
  requiredFields?: ('province' | 'district' | 'subDistrict')[];
  closeModal?: () => void;
  isVisible?: boolean;
  /**
   * 모달 상단 Nearby, Fast Delivery 탭 노출 여부
   */
  showTab?: boolean;
  /**
   * Nearby, Fast Delivery 초기 활성화 탭 선택
   */
  defaultActiveTab?: 'nearby' | 'fastDelivery';
  tabType?: TDealerBaseType;
  /**
   * 재고 여부 데이터 표출을 원할 경우 or fastDelivery 탭 표출 원할 경우
   * productNo, baseExteriorColorId, baseInteriorColorId 필수
   */
  productNo?: number;
  baseExteriorColorId?: number;
  baseInteriorColorId?: number;
  validDealerIds?: number[];
}

/**
 * 딜러정보 변경 모달
 */
function ChangeDealerModal({
  isVisible = false,
  callback,
  hideConsultantSelect = false,
  // 재고 임시 숨김 처리
  showTab: showTabProps = false,
  requiredFields,
  defaultActiveTab,
  closeModal,
  tabType: tabTypeProp,
  productNo,
  baseExteriorColorId,
  baseInteriorColorId,
  validDealerIds,
}: IChangeDealerModalProps) {
  const { t } = useTranslation();

  const [isLogin] = useMember();
  const [dealerList, setDealerList] = useState<Dealers.TDealerEntity[]>([]);
  const [isProceedConfirmOpen, setIsProceedConfirmOpen] = useState(false);
  const [tabType, setTabType] = useState<TDealerBaseType>(defaultActiveTab || DEALER_BASE_TYPE.NEARBY);

  const setChangeDealerProps = useSetRecoilState(changeDealerModalPropsSelector);
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const currentConsultantState = useRecoilValue(currentConsultantSelector);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);
  const { mutateAsync: mutateUpdateAssociatedDealer } = useUpdateAssociatedDealer();

  const [currentRegion, setCurrentRegion] = useState<IRegionState>(null);
  const [currentModel, setCurrentModel] = useState<any>(null);

  const [currentPosition, setCurrentPosition] = useState(null);
  const [selectedDealerState, setSelectedDealerState] = useState<Dealers.TDealerEntity>(null);
  const [listType, setListType] = useState<TDealerListType>(DEALER_LIST_TYPE.REGION);
  const [selectedConsultantState, setSelectedConsultantState] = useState<Global.IEmployeesEntity>(null);
  const { mutateAsync: mutateAutoAssignAsync } = useAutoAssign();
  const setGlobalAlertStore = useSetRecoilState<Global.IAlert>(globalAlertPropsState);
  const [userStore, setUserStore] = useRecoilState(userSelector);

  const isDealerListEnabled =
    listType === DEALER_LIST_TYPE.POSITION
      ? currentPosition?.lat && currentPosition?.lng
      : (requiredFields ?? []).every(fieldName => !!currentRegion?.[fieldName]);

  const { data, isFetched, isLoading } = useDealersListByType({
    isPosition: listType === DEALER_LIST_TYPE.POSITION,
    region: currentRegion ?? {},
    position: {
      latitude: currentPosition?.lat,
      longitude: currentPosition?.lng,
      limit: validDealerIds ? null : 5,
    },
    model: currentModel,
    enabled: isVisible && isDealerListEnabled,
  });

  // 재고 임시 숨김 처리
  // const isInventoryEnabled =
  //   showTab && !!currentModel?.productNo && !!currentModel?.baseExteriorColorId && !!currentModel?.baseInteriorColorId;
  const isInventoryEnabled = false;
  const showTab = false;

  // 재고 표시를 해야되는 경우(tab 표출되는 경우)에만 동작
  const {
    data: allDealerList,
    isFetched: isAllDealerFetched,
    isLoading: isAllDealerLoading,
  } = useGetDealerListByRegion(currentModel, {
    enabled: true,
  });
  const {
    data: inventoryDealerList,
    isFetched: isInventoryDealerFetched,
    isLoading: isInventoryDealerLoading,
  } = useGetDealerListByRegion(
    { stockDealerOnly: true, ...currentModel },
    {
      enabled: isInventoryEnabled,
    },
  );

  const isBasedOnRegion = tabType === DEALER_BASE_TYPE.NEARBY && listType === DEALER_LIST_TYPE.REGION;
  const noRegionSelected = !currentRegion?.province && !currentRegion?.district && !currentRegion?.subDistrict;
  // const noDealersInRegion = listType === DEALER_LIST_TYPE.REGION && isFetched && data?.length === 0;
  const noDealersInRegion =
    isFetched && data?.filter(dealer => (!validDealerIds ? true : validDealerIds.includes(dealer.id)))?.length === 0;
  const isDealerListLoading =
    (isLoading && isDealerListEnabled) ||
    (showTab && isAllDealerLoading) ||
    (isInventoryDealerLoading && isInventoryEnabled);

  // sc 만 변경했는지 여부 (딜러 & sc 변경 중)
  const [isChangedOnlySc, setIsChangedOnlySc] = useState(false);

  const handleClose = () => {
    if (typeof closeModal === 'function') closeModal();

    resetModal();
  };

  useEffect(() => {
    if (!isVisible) return;

    setTabType(tabTypeProp || defaultActiveTab || DEALER_BASE_TYPE.NEARBY);

    productNo &&
      baseExteriorColorId &&
      baseInteriorColorId &&
      setCurrentModel({ productNo, baseExteriorColorId, baseInteriorColorId });

    if (selfSelectedDealer) {
      setSelectedDealerState(currentDealerState);
      setSelectedConsultantState(currentConsultantState);
      setCurrentRegion(_.pick(currentDealerState, ['province', 'district', 'subDistrict']));
    }

    return () => {
      resetModal();
    };
  }, [isVisible]);

  const updateDealerList = () => {
    const getActiveDealerList = () => {
      if (showTab && tabType === 'fastDelivery') return inventoryDealerList;
      // else if (showTab && data?.length === 0) return allDealerList;
      else if (noDealersInRegion) return allDealerList;
      else return listType === DEALER_LIST_TYPE.POSITION ? data?.slice(0, 5) : data;
    };

    const targetData =
      (validDealerIds
        ? getActiveDealerList()?.filter(dealer => validDealerIds.includes(dealer.id))
        : getActiveDealerList()) ?? [];

    if (!targetData.some(dealer => dealer.id === selectedDealerState?.id)) {
      setSelectedDealerState(null);
      setSelectedConsultantState(null);
    }

    if (navigator?.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        if (permissionStatus.state === 'prompt') setDealerList(targetData);
      });
    }

    navigator?.geolocation?.getCurrentPosition(
      (position: GeolocationPosition) => {
        const myLocation = { lat: position.coords.latitude, lng: position.coords.longitude };

        const newData = targetData
          ?.map(data => ({
            ...data,
            distance: getKmFromLatAndLon(data.latitude, data.longitude, myLocation.lat, myLocation.lng),
          }))
          .sort((o1, o2) => o1.distance - o2.distance);

        setDealerList(newData);
      },
      () => {
        setDealerList(targetData);
      },
    );
  };

  useEffect(() => {
    if (
      isFetched &&
      (!isInventoryEnabled || isInventoryDealerFetched) &&
      (!showTab || (showTab && isAllDealerFetched))
    ) {
      isInventoryEnabled &&
        isInventoryDealerFetched &&
        !inventoryDealerList?.length &&
        setTabType(DEALER_BASE_TYPE.NEARBY);

      updateDealerList();
    }
  }, [isFetched, data, isAllDealerFetched, isInventoryDealerFetched, tabType]);

  useEffect(() => {
    if (listType === DEALER_LIST_TYPE.POSITION) {
      setSelectedDealerState(dealerList[0] ?? null);
      setSelectedConsultantState(null);
    }
  }, [dealerList]);

  const positionSuccessCallback = (position: GeolocationPosition) => {
    const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };

    setCurrentPosition(latlng);
    setListType(DEALER_LIST_TYPE.POSITION);
  };

  const handleChangeDealer = (data: Dealers.TDealerEntity) => {
    setSelectedDealerState(data);
    setSelectedConsultantState(null);
  };

  /**
   * sales consultant 업데이트 이벤트
   */
  const handleChangeConsultant = (data: Global.IEmployeesEntity | null) => {
    setSelectedConsultantState(data);
  };

  /**
   * 모달 리셋 이벤트
   */
  const resetModal = () => {
    setCurrentModel(null);
    setIsProceedConfirmOpen(false);
    setDealerList([]);
    setSelectedDealerState(null);
    setSelectedConsultantState(null);
    setCurrentRegion(null);
    setChangeDealerProps(null);
    setListType(DEALER_LIST_TYPE.REGION);
  };

  /**
   * sc만 선택했는지 확인 & 업데이트
   */
  const updateIsChangedOnlySc = () => {
    const isDealerChanged = selectedDealerState?.id !== currentDealerState?.id;
    const isScChanged = selectedConsultantState?.id !== currentConsultantState?.id;

    setIsChangedOnlySc(!isDealerChanged && isScChanged);
  };

  /**
   * Select a dealer 이벤트
   */
  const handleSubmit = async () => {
    if (!selectedDealerState) {
      setGlobalAlertStore({
        message: 'Select Dealer Error',
        handlerOk: () => setGlobalAlertStore(null),
      });

      return;
    }

    const consultant = selectedConsultantState ?? (await mutateAutoAssignAsync({ dealerId: selectedDealerState.id }));

    if (isLogin) {
      mutateUpdateAssociatedDealer(
        { dealerId: selectedDealerState.id, employeeId: consultant.id },
        {
          onSuccess: () => {
            setUserStore({
              profile: {
                ...userStore.profile,
                userAssociatedDealer: { dealerId: selectedDealerState.id, employeeId: consultant.id },
              },
            });
          },
        },
      );
    }

    // 여기서는 직접 업데이트 x (사용하는 곳마다 flow가 다르기 때문에 선택한 dealer, consultant 정보만 보냄)
    if (callback && typeof callback === 'function') {
      callback({
        dealer: selectedDealerState,
        consultant: consultant,
        isChangedOnlySc,
      });
    }

    // 모달 리셋
    resetModal();
  };

  /**
   * select a dealer시 케이스별 알럿메세지
   */
  const getProceedConfirmMessage = () => {
    if (!selfSelectedDealer) {
      return t('Alert.DealershipSelectConfirm');
    }

    if (isChangedOnlySc) {
      return t('Alert.DoYouWantToChangeTheSC');
    }

    return t('Alert.DealershipChangeConfirm');
  };

  const handleSelectADealer = () => {
    updateIsChangedOnlySc();

    // 현재 딜러 선택된 경우
    if (currentDealerState) {
      setIsProceedConfirmOpen(true);
      handleClickGA(selectedDealerState, gaAttributeName.CONFIGURATION.SELECT_A_DEALER);
    }
    // 딜러 선택 되지 않은 경우
    else {
      handleSubmit();
    }
  };

  return (
    <>
      <Modal
        id="change-dealer-modal"
        backDropClose={false}
        css={changeDealerModalStyle}
        visible={isVisible}
        closeModal={handleClose}
        modalTitle={
          <ChangeDealerModalHeader
            type={tabType}
            onTabChange={key => setTabType(key as TDealerBaseType)}
            showTab={showTab && !!inventoryDealerList?.length}
          />
        }
        className="modal-change-dealer"
        size="large-popup"
        footer={
          <Button
            disabled={!selectedDealerState || isDealerListLoading}
            className="modal-button"
            onClick={handleSelectADealer}
          >
            {t('Label.SelectADealer')}
          </Button>
        }
      >
        {tabType === DEALER_BASE_TYPE.NEARBY && (
          <RegionSelectForm
            formData={currentRegion}
            positionSuccessCallback={positionSuccessCallback}
            handleChange={newData => {
              setCurrentRegion(newData);
              setListType(DEALER_LIST_TYPE.REGION);
            }}
            requiredFields={requiredFields}
          />
        )}

        <ChangeDealerContainer
          dealerList={dealerList}
          handleChangeDealer={handleChangeDealer}
          handleChangeConsultant={handleChangeConsultant}
          selectedDealerState={selectedDealerState}
          selectedConsultantState={selectedConsultantState}
          hideConsultantSelect={hideConsultantSelect}
          isBasedOnRegion={isBasedOnRegion}
          noDealersInRegion={noDealersInRegion}
          noRegionSelected={noRegionSelected}
          isLoading={isDealerListLoading}
        />
      </Modal>
      <AlertModal
        visible={isProceedConfirmOpen}
        closeModal={() => setIsProceedConfirmOpen(false)}
        handlerOk={() => {
          handleSubmit();
        }}
        handlerCancel={() => {
          setIsProceedConfirmOpen(false);
        }}
        text={getProceedConfirmMessage()}
      />
    </>
  );
}

ChangeDealerModal.displayName = 'ChangeDealerModal';

export default ChangeDealerModal;
