import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const buttonStyle = css`
  &.btn-spinner {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  &.l-buttonSize {
    width: 36rem;
    height: 5.6rem;
  }
  &.m-buttonSize {
    width: 32rem;
    height: 4.8rem;
  }
  &.s-buttonSize {
    width: 20rem;
    height: 4rem;
  }
  &.s-buttonSize {
    width: 8rem;
    height: 3.2rem;
  }
  &.primary-button {
    background-color: ${colors.$primary};
    ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};
    &:disabled {
      background-color: #767676;
      color: ${colors.$color_999};
    }
  }
  &.secondary-button {
    border: 1px solid ${colors.$primary};
    background-color: ${colors.$color_fff};
    ${font('1.6rem', 400, colors.$primary, '2.4rem')};
    &:disabled {
      border-color: ${colors.$color_999};
      color: ${colors.$color_999};
    }
  }
  &.tertiary-button {
    border: 1px solid ${colors.$color_d9d};
    background-color: ${colors.$color_fff};
    ${font('1.6rem', 400, colors.$color_000, '2.4rem')};
    &:disabled {
      border-color: ${colors.$color_d9d};
      color: ${colors.$color_d9d};
      box-shadow: none;
    }
    &:hover {
      box-shadow: 0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    }
  }
  &.secondary-2-button {
    border: 1px solid ${colors.$color_e4e};
    background-color: ${colors.$color_fff};
    ${font('1.6rem', 400, colors.$color_767, '2.4rem')};
    &:disabled {
      border-color: ${colors.$color_ddd};
      color: ${colors.$color_ddd};
      &:hover {
        border-color: ${colors.$color_ddd};
        color: ${colors.$color_ddd};
      }
    }
    &:hover {
      border-color: ${colors.$color_ccc};
    }
  }
  &.linepay-button {
    ${flex('row', 'center', 'center')};
    background-color: ${colors.$line};
    ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};
    &:disabled {
      background-color: ${colors.$color_767};
      color: ${colors.$color_999};
      .ic-line-pay {
        background: url('/assets/images/ic_line_pay_dis.svg') no-repeat center / 7.6rem 2.2rem;
      }
    }

    .ic-line {
      margin-right: 0.8rem;
    }
    .ic-line-pay {
      margin-left: 0.8rem;
    }
  }
  &.button-2c2p {
    ${flex('row', 'center', 'center')};
    background-color: #0a8ade;
    ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};

    &:disabled {
      background-color: ${colors.$color_767};
      color: ${colors.$color_999};
    }
    .ic-2c2p-pay {
      margin-left: 0.8rem;
    }
  }

  &.text-button {
    ${font('1.3rem', 400, colors.$primary, '2rem')};
    text-decoration: underline;
    width: auto;
    height: auto;
  }
`;

export default buttonStyle;
