import useMenuLists from '@/hooks/useMenuList';
import { useRouter } from 'next/router';
import React from 'react';
import PageTitleStyle from './style';

const PageTitle = () => {
  const router = useRouter();
  const menuLists = useMenuLists();
  const pathAry = router.pathname?.split('/');
  const curMenuObj: Global.IMenuItem = pathAry?.reduce(
    (acc: any, cur: string) => (acc ? acc?.children[cur] : menuLists[cur]),
    null,
  );

  return (
    pathAry &&
    (pathAry.length > 2 || pathAry?.includes('compare')) && (
      <div className="page-title-container use-mobile" css={PageTitleStyle}>
        <div className="page-title-area">
          <div className="back-button-box">
            <button type="button" className="btn-back" title="go to back" onClick={() => router.back()}>
              <i className="ic ic_header_back"></i>
            </button>
          </div>
          <div className="title-box">
            <span>{curMenuObj?.name}</span>
          </div>
        </div>
      </div>
    )
  );
};

export default PageTitle;
