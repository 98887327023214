import getByteLengthOfString from './getByteLengthOfString';

const checkMaxSizeValidation = (value: string, isByte: boolean, maxSize: number) => {
  const inputSize = isByte ? getByteLengthOfString(value) : value?.length;
  const isValid = inputSize <= maxSize;

  return isValid;
};

export default checkMaxSizeValidation;
