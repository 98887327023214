import { getKmFromLatAndLon } from '@/utils/convert';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { convertAddressToGeocode } from '../googleMap/api';
import {
  getDealers,
  getDealersDetail,
  getDealersDistricts,
  getDealersProvinces,
  getDealersSearch,
  getDealersSubDistricts,
} from './api';

export function useDealersList() {
  return useQuery<{ data: Dealers.TDealerEntity[]; mapCenter: Dealers.IMapCenter }>(
    ['DEALERS_LIST'],
    (): { data: Dealers.TDealerEntity[]; mapCenter: Dealers.IMapCenter } => {
      return { data: null, mapCenter: null };
    },
  );
}

/**
 * 딜러 목록 호출 케이스
 * 1. 기존 호출 - 지역 기반
 *  1-1. 해당 지역에 딜러가 없을 경우 가능한 딜러 목록
 * 2. 기존 호출 - 좌표 기반
 * 3. 재고있는 딜러 리스트(Fast Delivery)
 * 4. 모델 기반(취급 여부) 딜러 목록
 *
 * 현재 누락: 1-1, 3
 */

// TODO: model 기반의 딜러 검색인지 여부에 따른 처리 추가, 타입 처리
export const useDealersListByType = ({
  isPosition,
  region = {},
  position,
  model = {},
  enabled,
  excludeDealershipRetailType,
  dealerBusinessType = null,
}: {
  isPosition: boolean;
  region: IRegionState;
  position: Dealers.IDealersLocationReq;
  model?: any;
  enabled?: boolean;
  excludeDealershipRetailType?: string[];
  dealerBusinessType?: string;
}): UseQueryResult<Dealers.IDealersRes[], unknown> => {
  const resultByRegion = useGetDealerListByRegion(
    { ...region, ...model, excludeDealershipRetailType, dealerBusinessType },
    { enabled: enabled && !isPosition },
  );
  const resultByPosition = useGetDealerListByPosition(
    { ...position, ...model, excludeDealershipRetailType, dealerBusinessType },
    enabled && isPosition,
  );

  return isPosition ? resultByPosition : resultByRegion;
};

export const useGetDealerListByRegion = (
  params: Dealers.IDealersReq,
  options?: { enabled?: boolean; keepPreviousData?: boolean },
) => {
  return useQuery<Dealers.IDealersRes[]>(['DEALERS_LIST_BY_REGION', params], () => getDealers(params), {
    enabled: options?.enabled ?? (!!params && !!params.province),
    keepPreviousData: options?.keepPreviousData,
  });
};

export const useGetDealerListByPosition = (params: Dealers.IDealersLocationReq, enabled?: boolean) => {
  return useQuery<Dealers.IDealersSearchRes[]>(['DEALERS_LIST_BY_REGION', params], () => getDealersSearch(params), {
    enabled: enabled ?? (!!params && !!params.latitude && !!params.longitude),
  });
};

export function useGetDealersWithAddress(useMapCenter = false) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: Dealers.IDealersReq) => {
      const dealerResult = await getDealers(params);
      const mapCenterResult = useMapCenter
        ? await convertAddressToGeocode(
            `${params.province ?? ''}` + ` ${params.district ?? ''}` + ` ${params.subDistrict ?? ''}`,
          )
        : {};

      return { data: dealerResult, mapCenter: mapCenterResult };
    },
    {
      onSuccess: async (result, variables, context) => {
        // 사용자 위치로부터 거리 계산
        queryClient.setQueryData(['DEALERS_LIST'], { data: result?.data, mapCenter: result.mapCenter });
        await navigator?.geolocation?.getCurrentPosition(async (position: GeolocationPosition) => {
          const myLocation = { lat: position.coords.latitude, lng: position.coords.longitude };

          const dataWithDistance = result.data.map(item => {
            const distance = getKmFromLatAndLon(item.latitude, item.longitude, myLocation.lat, myLocation.lng);

            return { ...item, distance };
          });

          queryClient.setQueryData(['DEALERS_LIST'], { data: dataWithDistance, mapCenter: result.mapCenter });
        });
      },
    },
  );
}

export function useGetDealersWithLatLng() {
  const queryClient = useQueryClient();

  return useMutation((params: Dealers.IDealersLocationReq) => getDealersSearch(params), {
    onSuccess: async (data, variables) => {
      queryClient.setQueryData(['DEALERS_LIST'], {
        data,
        mapCenter: { lat: variables.latitude, lng: variables.longitude },
      });
    },
  });
}

export function useGetDealerDetail(params: Dealers.IDealersDetailReq, options = {}) {
  return useQuery<Dealers.IDealersDetailRes, Error>(
    ['DEALERS_DETAIL', Number(params?.id)],
    () => getDealersDetail(params),
    {
      enabled: !!params.id,
      // keepPreviousData: true,
      ...options,
    },
  );
}

export function useGetDealersProvinces(params?: Dealers.IDealersProvincesReq) {
  return useQuery<Dealers.TDealersProvincesRes, Error>(['DEALERS_PROVINCES'], () => getDealersProvinces(params), {
    enabled: params?.enabled,
  });
}

export function useGetDealersDistricts(params: Dealers.IDealersDistrictsReq) {
  return useQuery<Dealers.TDealersDistrictsRes, Error>(
    ['DEALERS_DISTRICTS', params],
    () => getDealersDistricts(params),
    {
      enabled: !!params?.province,
    },
  );
}

export function useGetDealersSubDistricts(params: Dealers.IDealersSubDistrictsReq) {
  return useQuery<Dealers.TDealersSubDistrictsRes, Error>(
    ['DEALERS_SUB_DISTRICTS', params],
    () => getDealersSubDistricts(params),
    {
      enabled: !!params?.province && !!params?.district,
    },
  );
}

export const useGetDealerDetailMutation = () => {
  return useMutation((params: Dealers.IDealersDetailReq) => getDealersDetail(params));
};
