import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper';
import 'swiper/css';
import { TabItemProps, TabProps } from './type';
import { Swiper as SwiperType } from 'swiper/types';
import classNames from 'classnames';

function Tab({
  onChange = () => null,
  data: dataProps = [],
  defaultActiveKey,
  activeKey,
  vertical = false,
  className,
  ...otherProps
}: TabProps) {
  const [selectedTab, setSelectedTab] = useState<string | number>(activeKey ?? defaultActiveKey ?? null);
  const [selectedTabContent, setSelectedTabContent] = useState<React.ReactNode>();

  useEffect(() => {
    if (activeKey === undefined) return;

    setSelectedTab(activeKey);
  }, [activeKey]);

  useEffect(() => {
    if (!dataProps?.length) return;

    const result = dataProps.find(data => data.key === selectedTab);

    setSelectedTabContent(result?.content ?? <></>);
  }, [selectedTab, dataProps]);

  const onClickTab = (dataItem: TabItemProps) => {
    if (selectedTab === dataItem.key || !dataItem) return;

    onChange(dataItem?.key);
    setSelectedTab(dataItem.key);
    setSelectedTabContent(dataItem?.content ?? <></>);
  };

  const ref = useRef();

  const [isShadowBegin, setIsShadowBegin] = useState<boolean>(false);
  const [isShadowEnd, setIsShadowEnd] = useState<boolean>(true);

  const checkTabScrollPosition = (isBeginning: boolean, isEnd: boolean) => {
    setIsShadowBegin(!isBeginning);
    setIsShadowEnd(!isEnd);
  };

  if (!dataProps?.length) return <div className="tab"></div>;

  return (
    <div
      className={classNames(
        className,
        'haefe-tab',
        vertical ? 'haefe-tab-vertical' : 'haefe-tab-horizontal',
        isShadowBegin && 'haefe-tab-shadowBegin',
        isShadowEnd && 'haefe-tab-shadowEnd',
        'haefe-tab-border',
      )}
      ref={ref}
    >
      <Swiper
        spaceBetween={8} // TODO: 온판 추가
        breakpoints={{
          // when window width is >= 769px
          769: {
            // spaceBetween: 48,
            spaceBetween: 0,
          },
        }} // TODO: 온판 추가
        direction={vertical ? 'vertical' : 'horizontal'}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        onTouchMove={swiper => {
          checkTabScrollPosition(swiper?.isBeginning, swiper?.isEnd);
        }}
        onScroll={swiper => {
          checkTabScrollPosition(swiper?.isBeginning, swiper?.isEnd);
        }}
        onReachEnd={(swiper: SwiperType) => {
          checkTabScrollPosition(swiper?.isBeginning, swiper?.isEnd);
        }}
        onReachBeginning={(swiper: any) => {
          checkTabScrollPosition(swiper?.isBeginning, swiper?.isEnd);
        }}
        className={'haefe-tab-container'}
      >
        {dataProps?.map((data: TabItemProps, idx: number) => (
          <SwiperSlide
            key={idx}
            onClick={() => {
              if (!data?.disabled) onClickTab(data);
            }}
            className={`haefe-tab-item  ${selectedTab === data.key ? 'haefe-tab-selected' : ''} ${
              data?.disabled ? 'haefe-tab-disabled' : ''
            }
            `}
          >
            <div className="haefe-tab-label" {...otherProps}>
              {data?.label}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="haefe-tab-content">{selectedTabContent}</div>
    </div>
  );
}

export default Tab;
