import { atom, selector } from 'recoil';

export const i18nTenantState = atom<Global.TLanguages>({
  key: 'stores.common.i18n.atom.tenant',
  default: 'th',
});

export const i18nLocaleState = atom<Global.TLanguages>({
  key: 'stores.common.i18n.atom.locale',
  default: 'en',
});
