import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { DropdownItem, DropdownValue } from '../type';

interface DropdownItemProps extends Omit<React.LiHTMLAttributes<HTMLLIElement>, 'onClick'> {
  value: DropdownValue; // (string | number) [];
  disabled?: boolean;
  // TODO: onClick은 부모에서 넣어주기
  onClick: (value: DropdownValue, item: DropdownItem) => void;
  isSelected: boolean;
}

export default function Item({
  children,
  value,
  disabled = false,
  onClick = () => null,
  isSelected,
  ...otherProps
}: PropsWithChildren<DropdownItemProps>) {
  return (
    <li
      onClick={() => onClick(value, { value })}
      className={classNames('haefe-dropdown-item', {
        'haefe-dropdown-item-disabled': disabled,
        'haefe-dropdown-item-selected': isSelected,
      })}
      {...otherProps}
    >
      {children}
    </li>
  );
}
