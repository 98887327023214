import { Cookie, CookieSetOptions } from 'universal-cookie';
import { Cookies, useCookies } from 'react-cookie';
import { decodeJWT } from '@/utils/jwt';

type TUseCustomCookiesReturn<T, U> = [
  U,
  (name: T, value: Cookie, options?: CookieSetOptions) => void,
  (name: T, options?: CookieSetOptions) => void,
];

interface ICookies {
  [key: string]: any;
}

interface JWT {
  header: Header;
  payload: Payload;
}
interface Header {
  alg: string;
  typ: string;
}
interface Payload {
  pid: string;
  uid: string;
  sid: string;
  exp: number;
  iat: number;
  iss: string;
}

/**
 * LoginToken(cookie_BuyOnline_th)이 환경별 공유 되는 문제로 이를 해결하기 위해
 * 해당 Hook에서 비교하여 쿠키 사용 여부처리 함.
 *
 * @param : string[] 사용할 Cookie Key
 * @return : [cookies, setCookie, removeCookie]
 */

export function useCustomCookies<T extends string, U = { [K in T]?: any }>(
  dependencies?: T[],
): TUseCustomCookiesReturn<T, U> {
  const [orgCookie, setCookie, removeCookie] = useCookies(dependencies);
  const cookies = { ...orgCookie } as U & { cookie_BuyOnline_th?: any; refresh_BuyOnline_th?: any };

  if (cookies?.cookie_BuyOnline_th) {
    const jwt = cookies?.cookie_BuyOnline_th;

    cookies.cookie_BuyOnline_th = jwt?.env === process.env.NEXT_PUBLIC_HMG_ENV ? jwt?.token : null;
  }

  if (cookies?.refresh_BuyOnline_th) {
    const tokens = cookies?.refresh_BuyOnline_th;

    cookies.refresh_BuyOnline_th = tokens?.env === process.env.NEXT_PUBLIC_HMG_ENV ? tokens?.refresh : null;
  }

  return [cookies, setCookie, removeCookie];
}

export class BuyonlineCookies extends Cookies {
  get(name: string) {
    const cookies = new Cookies();

    if (name === 'cookie_BuyOnline_th') {
      const jwt = cookies.get(name);

      return jwt?.env === process.env.NEXT_PUBLIC_HMG_ENV ? jwt?.token : null;
    }

    if (name === 'refresh_BuyOnline_th') {
      const jwt = cookies.get(name);

      return jwt?.env === process.env.NEXT_PUBLIC_HMG_ENV ? jwt?.refresh : null;
    }

    return cookies.get(name);
  }

  getAccessToken(): JWT | any {
    try {
      const cookies = new Cookies();
      const name = 'cookie_BuyOnline_th';
      const jwt = cookies.get(name);

      if (!jwt) {
        throw new Error('Not Found Token');
      }
      const token = jwt?.env === process.env.NEXT_PUBLIC_HMG_ENV ? jwt?.token : null;

      return decodeJWT(token);
    } catch (err) {
      return null;
    }
  }
}

export function ssrCookies(req: any) {
  const cookies = req?.cookies;

  if (cookies?.cookie_BuyOnline_th) {
    const tokens = JSON.parse(cookies?.cookie_BuyOnline_th);

    cookies.cookie_BuyOnline_th = tokens?.env === process.env.NEXT_PUBLIC_HMG_ENV ? tokens?.token : null;
  }

  if (cookies?.refresh_BuyOnline_th) {
    const tokens = JSON.parse(cookies?.refresh_BuyOnline_th);

    cookies.refresh_BuyOnline_th = tokens?.env === process.env.NEXT_PUBLIC_HMG_ENV ? tokens?.refresh : null;
  }

  return cookies;
}
