import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const consultantBottomStyle = css`
  &.dealer-block-bottom {
    margin-top: 0.8rem;

    .info-box {
      ${flex('row', 'flex-start', 'flex-start')};
      &:not(:first-of-type) {
        margin-top: 1.2rem;
      }
      .ic {
        min-width: 1.4rem;
        margin-top: 0.3rem;
      }
      .info {
        margin-left: 0.6rem;
        ${font('1.3rem', 400, colors.$color_767, '2rem')};
        text-align: left;
      }
      &.line {
        .info {
          margin: 0;
          padding-left: 2.2rem;
        }
        .line-id {
          color: ${colors.$line};

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export default consultantBottomStyle;
