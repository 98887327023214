import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export const useDatadogRum = () => {
  useEffect(() => {
    const isInit = window?.DD_RUM.getInitConfiguration();

    if (isInit) {
      datadogRum.startSessionReplayRecording();

      return;
    }

    datadogRum.init({
      applicationId: '3fb588ba-06c7-4965-a3b0-63220ca25453',
      clientToken: 'pub7999b13028db8f829af14bc13293f8eb',
      site: 'datadoghq.com',
      service: 'hmgc-fo-fe-hmt',
      env: process.env.NEXT_PUBLIC_HMG_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  return;
};
