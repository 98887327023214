import React from 'react';
import classNames from 'classnames';
import emptyStyle from './style';
import { IEmptyProps } from './type';

const EmptyBoldText = ({ className, children }: IEmptyProps) => {
  return <em className={className}>{children}</em>;
};

const Empty = ({ className, iconClassName = 'ic_notice', children }: IEmptyProps) => {
  return (
    <div css={emptyStyle} className={classNames('empty', className)}>
      <i className={classNames('ic', iconClassName)} />

      <div className="empty-text">{children}</div>
    </div>
  );
};

export default Object.assign(Empty, {
  Bold: EmptyBoldText,
});
