import { isNumber, isString, isNil } from 'lodash-es';
import { PAYMENT_TYPE } from '@/constants/payments';

export const phoneNumberValidation = (input: string): boolean => {
  return isString(input) && isNumbericString(input) && input?.startsWith('0') && input?.length === 10;
};

export const isNumbericString = (input: string) => {
  const numberReg = /^[0-9]+$/;

  return numberReg.test(input);
};

export const isValidNumber = (value: string | number | undefined): boolean => {
  if (isNumber(value) || (isString(value) && isNumbericString(value))) return true;

  return false;
};

/** isFinanceValidation
 * 선택한 결제 타입이 FINANCE 이면서
 * 은행선택 콤보박스나 이자율을 선택하지 않았을 경우에 다음단계로 넘어갈 수 없도록 validation을 체크한다.
 * @param: type, bank, rate
 * @return: boolean
 */
export const isFinanceValidation = (type: string, bank: string | string[], rate: string) => {
  if (type === PAYMENT_TYPE.FINANCE && (!bank || !rate)) {
    return false;
  }

  return true;
};

export const validCitizenID = (value: string): boolean => {
  if (value.length !== 13) return false;
  let sum = 0;

  // STEP 1 - get only first 12 digits
  for (let i = 0; i < 12; i++) {
    // STEP 2 - multiply each digit with each index (reverse)
    // STEP 3 - sum multiply value together
    sum += parseInt(value.charAt(i)) * (13 - i);
  }

  // STEP 4 - mod sum with 11
  const mod = sum % 11;
  // STEP 5 - subtract 11 with mod, then mod 10 to get unit
  const check = (11 - mod) % 10;
  // STEP 6 - if check is match the digit 13th is correct

  if (check === parseInt(value.charAt(12))) {
    return true;
  }

  return false;
};

export const isFunction = (value: any) => {
  return value && typeof value === 'function';
};

/** isExpired
 * 날짜 expired여부 확인 함수
 * @param: closingTime
 * @return: boolean
 */
export const isExpired = (closingTime: string) => {
  const closingTimeMs = new Date(closingTime).getTime();
  const currentTimeMs = Date.now();

  return closingTimeMs <= currentTimeMs;
};

/** isValidMarker
 * 유효 좌표 검증하는 확인 유무
 * @param: lat, lng
 * @return: boolean
 */
export const isValidMarker = (lat: number | null, lng: number | null) => {
  return isNumber(lat) && isNumber(lng) ? true : false;
};
