import React, { useContext, useEffect, useState } from 'react';
import FormContext from './FormContext';
import { FormItemProps, FormValidation } from './type';
import classNames from 'classnames';
import { createObjectByKeys, getValueByKeys, setValueByKeys } from '@/components/haeLibraryRefactored/utils/form';
// import { cloneDeep } from '@/components/haeLibraryRefactored/utils/cloneDeep';
import _ from 'lodash-es';

function FormItem({
  children,
  name,
  label,
  valuePropsName = 'value',
  status,
  onChange,
  initialValue,
  className,
  required,
  validation,
  message,
  ...otherProps
}: FormItemProps) {
  const { fields, setFields, form, onValuesChange } = useContext(FormContext);

  const [valid, setValid] = useState<FormValidation | boolean>(true);

  const { registerField } = form?.getInternalProperty() ?? {};

  const getValue = () => {
    const value = getValueByKeys(name, fields);

    if (value) {
      return value;
    } else if (initialValue) {
      return initialValue;
    } else {
      return valuePropsName === 'checked' ? false : '';
    }
  };

  const validToStat = (val: FormValidation | boolean) =>
    typeof val === 'boolean' ? (val === false ? 'error' : null) : val?.status;

  const outMsg = message ?? (typeof valid !== 'boolean' && valid?.message) ?? undefined;
  const outStat = status ?? validToStat(valid);

  const checkValid = (val: any) => {
    let res = undefined;

    if (required && !val) {
      res = false;
    } else {
      validation && (res = validation(val, name));
    }

    return res;
  };

  const handleOnChange = (val: unknown) => {
    onChange && onChange(val);
    setValid(checkValid(val));

    if (typeof name === 'string') {
      setFields({ ...fields, [name]: val });
      onValuesChange({ [name]: val });
    } else if (Array.isArray(name)) {
      const newFields = _.cloneDeep(fields);

      setValueByKeys(val, name, newFields);

      setFields(newFields);

      onValuesChange(createObjectByKeys(name, val));
    }
  };

  const childProps = {
    [valuePropsName]: getValue(),
    name,
    id: Array.isArray(name) ? name.join('.') : name,
    status: outStat,
    onChange: handleOnChange,
  };

  useEffect(() => {
    const unRegisterField = registerField(
      {
        name,
        onCheckValid: (value: any) => {
          const res = checkValid(value);

          setValid(res);

          return validToStat(res);
        },
      },
      getValue(),
    );

    return () => {
      unRegisterField();
    };
  }, []);

  return (
    <div className={classNames(`haefe-formitem`, className, outStat, { required: required })} {...otherProps}>
      {label && <label className="field-label">{label}</label>}
      <div className="field-form">{React.cloneElement(children, childProps)}</div>
      {outMsg && <p className="message">{outMsg}</p>}
    </div>
  );
}

export default FormItem;
