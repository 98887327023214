import { axiosInstance as axios } from '@/query/axios';
import { getKmFromLatAndLon } from '@/utils/convert';
import { QueryClient } from '@tanstack/react-query';

export const getDefaultDealer = async (targetId: number, lang: string, queryClient: QueryClient) => {
  try {
    const { data: targetData }: { data: Dealers.IDealersDetailRes } = await axios.get(`/user_api/dealers/${targetId}`, {
      headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
        // Tenant: 'th',
        Locale: lang,
      },
    });

    if (!targetData?.dealer) {
      const { data: dealerList }: { data: Dealers.IDealersSearchRes[] } = await axios.get(`/user_api/dealers`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Tenant: 'th',
          Locale: lang,
        },
      });

      const response = await getDealersDetail({ id: dealerList?.[0]?.id });

      queryClient?.setQueryData(['DEALERS_DETAIL', Number(dealerList?.[0]?.id)], response);

      return response;
    }

    queryClient?.setQueryData(['DEALERS_DETAIL', Number(targetId)], targetData);

    return targetData;
  } catch (err) {
    throw err;
  }
};

export const getDealers = async (params?: Dealers.IDealersReq): Promise<Dealers.IDealersRes[]> => {
  try {
    const response = await axios.get(`/user_api/dealers`, {
      params: { ...params, excludeDealershipRetailType: params?.excludeDealershipRetailType?.join(',') },
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};

export const getDealersSearch = async (params?: Dealers.IDealersLocationReq): Promise<Dealers.IDealersSearchRes[]> => {
  try {
    const { data } = await axios.get(`/user_api/dealers/location`, {
      params: { ...params, excludeDealershipRetailType: params?.excludeDealershipRetailType?.join(',') },
    });

    // 사용자 위치로부터 거리 계산
    const myLocation = { lat: params?.latitude, lng: params?.longitude };

    const dataWithDistance = data.map((item: Dealers.IDealersSearchRes) => {
      const distance = getKmFromLatAndLon(item.latitude, item.longitude, myLocation.lat, myLocation.lng);

      return { ...item, distance };
    });

    return dataWithDistance || null;
  } catch (err) {
    throw err;
  }
};

export const getDealersDetail = async (params: Dealers.IDealersDetailReq): Promise<Dealers.IDealersDetailRes> => {
  const dealerBusinessType = params?.dealerBusinessType;

  try {
    const response = await axios.get(`/user_api/dealers/${params.id}`, {
      params: {
        salesConsultant: params.salesConsultant || true,
        activeEmployee: params.activeEmployee || true,
        dealerBusinessType: dealerBusinessType,
      },
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};

export const getDealersProvinces = async (
  params?: Dealers.IDealersProvincesReq,
): Promise<Dealers.TDealersProvincesRes> => {
  try {
    const response = await axios.get(`/user_api/dealers/provinces`, {
      params: { ...params },
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};

export const getDealersDistricts = async (
  params: Dealers.IDealersDistrictsReq,
): Promise<Dealers.TDealersDistrictsRes> => {
  try {
    const response = await axios.get(`/user_api/dealers/districts/${params.province}`, {
      params: { dealerBusinessType: params.dealerBusinessType },
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};

export const getDealersSubDistricts = async (
  params: Dealers.IDealersSubDistrictsReq,
): Promise<Dealers.TDealersSubDistrictsRes> => {
  try {
    const response = await axios.get(`/user_api/dealers/sub-districts/${params?.district}/${params?.province}`, {
      params: { dealerBusinessType: params?.dealerBusinessType },
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};
