export function getDevice() {
  const lowerUserAgent = window.navigator.userAgent.toLowerCase();

  //ipad 여부
  const isIPad =
    lowerUserAgent.indexOf('ipad') > -1 || (lowerUserAgent.indexOf('macintosh') > -1 && 'ontouchend' in document);

  //모바일 기기 여부 - tablet 포함
  const isMobile =
    isIPad || /mobile|ipad|iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(lowerUserAgent);

  // 모바일일 경우 width 값으로 태블릿 여부 구분
  // const isTablet =
  //   isMobile && document.body.getBoundingClientRect().width < 992 && document.body.getBoundingClientRect().width > 768
  //     ? true
  //     : false;

  // return isTablet ? 'TABLET' : isMobile ? 'MOBILE' : 'DESKTOP';
  return isMobile ? 'MOBILE' : 'DESKTOP';
}
