import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const convertGeocodeToAddress = async (params: { lat: number; lng: number }) => {
  try {
    const latlng = params.lat + ',' + params.lng;
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      // TODO: language 값 설정된 lang으로 변경 필요. google API key 정상 작동 확인 필요
      params: {
        latlng,
        key: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
        language: cookies.get('lang') || 'en',
        result_type: 'street_address',
      },
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const convertAddressToGeocode = async (address: string) => {
  try {
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        key: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
        address: address,
      },
    });

    return response?.data?.results[0]?.geometry?.location || null;
  } catch (err: any) {
    throw err;
  }
};
