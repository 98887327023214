import { compareThreshold, CookieDomainUrl } from '@/constants/common';
import { Cookies } from 'react-cookie';
import { atom, DefaultValue, selector } from 'recoil';

const cookies = new Cookies();

export const compareState = atom<ComparePage.ICompareState>({
  key: 'stores.compare.atom.compareVehicles',
  default: {
    cartItems: [null],
    vehicleArr: [null],
  },
  //effects: [initCart('compareList_th')],
});

export const compareSelector = selector<Partial<ComparePage.ICompareState>>({
  key: 'stores.compare.selector.compareVehicles',

  get: ({ get }) => {
    return get(compareState);
  },
  set: ({ get, set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(compareState);

      return;
    }

    const store = get(compareState);

    const newCartItems = value?.cartItems?.filter((d: ComparePage.ICartItems) => d);
    const isIncompleteOption = value?.cartItems?.some((d: ComparePage.ICartItems) => d && !d?.productNo);

    const cartItemsWithNull = () => {
      if (!newCartItems) return store?.cartItems;
      if (newCartItems?.length >= compareThreshold || isIncompleteOption) return newCartItems;

      return [...newCartItems, null];
    };

    const newStore = {
      cartItems: cartItemsWithNull(),
      vehicleArr: value?.vehicleArr?.length > 0 ? value?.vehicleArr : store?.vehicleArr,
    };

    const expires = new Date();

    expires.setDate(expires.getDate() + 30);
    const cookieConfig = {
      path: '/',
      domain: `${process.env.NODE_ENV === 'development' ? 'localhost' : CookieDomainUrl}`,
      expires,
    };

    if (newCartItems?.length === 0) {
      cookies.remove('BuyOnlineFavoritesCount_th', cookieConfig);
      cookies.remove('compareList_th', cookieConfig);
    } else if (newCartItems?.length > 0) {
      const filteredCartItems = newCartItems?.filter((d: ComparePage.ICartItems) => d?.productNo);

      cookies.set('BuyOnlineFavoritesCount_th', filteredCartItems?.length, cookieConfig);
      cookies.set('compareList_th', filteredCartItems, cookieConfig);
    }

    set(compareState, newStore);
  },
});
