export const DeviceList: Record<Global.TDevice, Global.TDevice> = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const languageText: { [key: Global.TLanguages]: string } = {
  en: 'English',
  th: 'Thailand',
};

export const nonMemberCookieKey = 'nonMemberCookie';

export const compareThreshold = 10;

export const DEFAULT_LAT_LNG = { lat: 13.7563309, lng: 100.5017651 };

/**
 * cookie 브랜드별 도메인 주소
 */
export const CookieDomainUrl = '.hyundai.com';

export const gaAttributeName = {
  /** STATUS : GA 현재 페이지 내 접근 상태 */
  STATUS: {
    CTB: 'CTB',
    CONFIGURATION: 'CONFIGURATION',
    MYGARAGE: 'MYGARAGE',
    QUOTATION: 'QUOTATION',
    QUOTATION_DETAIL: 'QUOTATION_DETAIL',
    BOOKING_PAYMENT: 'BOOKING_PAYMENT',
  },
  CTB: {
    PAGE_TYPE: 'click_to_buy', //click to buy 페이지 진입
    CATEGORY: 'click to buy',
  },
  CONFIGURATION: {
    VARIANT: 'variant',
    COLOR: 'color',
    SELECT_A_DEALER: 'select_a_dealer',
    BYO_STEP_INIT: 'byo_step_init',
    BYO_STEP_STEP_1: 'byo_step_step1',
    BYO_STEP_STEP_2: 'byo_step_step2',
    BYO_STEP_STEP_3: 'byo_step_step3',
    BYO_STEP_COMPLETE: 'byo_step_complete',
    PAGE_TYPE: 'build', //견적내기
  },
  MYGARAGE: {
    CATEGORY: 'My Garage',
    PAGE_TYPE: 'My Garage',
  },
  BOOKING_PAYMENT: {
    CURRENCY: 'THB',
    BEGIN_CHECKOUT: 'begin_checkout',
    PURCHASE: 'purchase',
  },
  QUOTATION_DETAIL: {
    CATEGORY: 'Quotation',
    DOWNLOAD: 'Download Quotation',
  },
};

/**
 * TBD 가격 변경 대응 (11/27)
 */
export const TBDPrice = 51111;
export const TBDBalancePrice = 1111;
export const IONIQ = 'IONIQ';
