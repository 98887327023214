import { css } from '@emotion/react';
import { colors } from 'styles/mixin';
import { IDimStyleProps } from './type';

const DimStyle = ({ transparent, openDim }: IDimStyleProps) =>
  css`
    visibility: ${openDim ? 'visible' : 'hidden'};
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: ${colors.$color_0A_0};
    background-color: ${transparent === true ? 'transparent' : colors.$color_0A_4};
    transition: background-color 0.3s;
  `;

export default DimStyle;
