export const QUICK_MENU_FORM_DEPENDENCIES: { [key: string]: readonly string[] } = {
  dealerId: ['model', 'productNo', 'employeeId'],
  brand: ['model', 'variant', 'fuelType', 'transmission'],
  variant: ['fuelType', 'transmission'],
  fuelType: ['transmission'],
  model: ['productNo', 'variant', 'fuelType', 'transmission'],
} as const;

export const QUICK_MENU_MODAL_TITLE = {
  testDrive: 'Label.RequestATestDrive',
  tradeIn: 'Label.RequestATradeIn',
  quote: 'Label.GetAQuote',
  contactUs: 'Label.ContactUs',
  contactDealer: 'Label.EnterYourInformation',
  serviceAppointment: 'Label.ServiceAppointment',
  ioniqBasic: 'RequestABasicTestDrive',
  ioniqOneDay: 'RequestAOneDayTestDrive',
  ioniqLongDistance: 'RequestALongDistanceTestDrive',
  ioniqLab: 'RequestAnIONIQCXLabWorkshop',
} as const;

export const EMPLOYEE_AUTO_SELECT_ID = -1;

export const QUICK_MODAL_TYPE = {
  TRADE_IN: 'tradeIn',
  TEST_DRIVE: 'testDrive',
  QUOTE: 'quote',
  CONTACT_US: 'contactUs',
  CONTACT_DEALER: 'contactDealer',
  SERVICE_APPOINTMENT: 'serviceAppointment',
  IONIQ_BASIC: 'ioniqBasic',
  IONIQ_ONE_DAY: 'ioniqOneDay',
  IONIQ_LONG_DISTANCE: 'ioniqLongDistance',
  IONIQ_LAB: 'ioniqLab',
} as const;

// TODO: 리팩토링 검토?
export const QUICK_MENU_FIELD_DATA: {
  [key in TQuickModalType]: {
    requiredFields: readonly (keyof IQuickMenuMergedFormData)[];
    relations: readonly { condition: string; targets: readonly string[] }[];
  };
} = {
  [QUICK_MODAL_TYPE.CONTACT_DEALER]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'model',
      'productNo',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [],
  },
  [QUICK_MODAL_TYPE.CONTACT_US]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'district',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [
      { condition: 'province', targets: ['district', 'subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'district', targets: ['subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'subDistrict', targets: ['dealerId', 'employeeId'] },
      { condition: 'dealerId', targets: ['employeeId'] },
    ],
  },
  [QUICK_MODAL_TYPE.QUOTE]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'district',
      'model',
      'productNo',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [
      { condition: 'province', targets: ['district', 'subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'district', targets: ['subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'subDistrict', targets: ['dealerId', 'employeeId'] },
      { condition: 'dealerId', targets: ['employeeId'] },
      { condition: 'model', targets: ['productNo'] },
    ],
  },
  [QUICK_MODAL_TYPE.SERVICE_APPOINTMENT]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'preferredDate',
      'vehicleModel',
      'serviceType',
      'preferredTime',
      'province',
      'district',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [
      { condition: 'province', targets: ['district', 'subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'district', targets: ['subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'subDistrict', targets: ['dealerId', 'employeeId'] },
      { condition: 'dealerId', targets: ['employeeId'] },
    ],
  },
  [QUICK_MODAL_TYPE.TEST_DRIVE]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'district',
      'model',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [
      { condition: 'province', targets: ['district', 'subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'district', targets: ['subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'subDistrict', targets: ['dealerId', 'employeeId'] },
      { condition: 'dealerId', targets: ['employeeId'] },
    ],
  },
  [QUICK_MODAL_TYPE.TRADE_IN]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'district',
      'dealerId',
      'employeeId',
      'brand',
      'tradeInModel',
      'variant',
      'fuelType',
      'transmission',
      'mileage',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [
      { condition: 'province', targets: ['district', 'subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'district', targets: ['subDistrict', 'dealerId', 'employeeId'] },
      { condition: 'subDistrict', targets: ['dealerId', 'employeeId'] },
      { condition: 'dealerId', targets: ['employeeId'] },
      { condition: 'brand', targets: ['tradeInModel', 'variant', 'fuelType', 'transmission'] },
      { condition: 'tradeInModel', targets: ['variant', 'fuelType', 'transmission'] },
      { condition: 'variant', targets: ['fuelType', 'transmission'] },
      { condition: 'fuelType', targets: ['transmission'] },
    ],
  },
  [QUICK_MODAL_TYPE.IONIQ_BASIC]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'dealerId',
      'employeeId',
      'preferredDate',
      'preferredTimeToCall',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [{ condition: 'dealerId', targets: ['employeeId'] }],
  },
  [QUICK_MODAL_TYPE.IONIQ_ONE_DAY]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'dealerId',
      'employeeId',
      'preferredDate',
      'preferredTimeToCall',
      'returnTime',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [{ condition: 'dealerId', targets: ['employeeId'] }],
  },
  [QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [{ condition: 'dealerId', targets: ['employeeId'] }],
  },
  [QUICK_MODAL_TYPE.IONIQ_LAB]: {
    requiredFields: [
      'firstName',
      'lastName',
      'mobile',
      'province',
      'dealerId',
      'employeeId',
      'tcpAgree',
      'marketingCommunicationAgree',
    ],
    relations: [{ condition: 'dealerId', targets: ['employeeId'] }],
  },
} as const;

export const MAX_COMMENT_BYTE_SIZE = 4000;

export const SERVICE_TYPE = [
  { value: 'GENERAL_JOB', label: 'General Job' },
  { value: 'PREVENTIVE_MAINTENANCE', label: 'Preventive Maintenance' },
  { value: 'BODY_REPAIR', label: 'Body Repair' },
];

export const FOR_SERVICE_APPOINTMENT_TYPE = {
  DEALER_BUSINESS: 'SERVICE',
  SUB_DISTRICT: 'subDistrict',
  DEALER_DETAIL: 'dealerDetail',
  DEALER_PROVINCES: 'dealersProvinces',
  DISTRICT: 'districts',
};
