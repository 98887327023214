import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const changeDealerContainerStyle = css`
  .dealer-block-button {
    border: 1px solid ${colors.$color_ddd};

    .dealer-block {
      padding: 1.6rem;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      border: 2px solid ${colors.$secondary1};
      .dealer-block {
        padding: 1.5rem;
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
  }

  .dealer-bottom-left {
    width: 100%;
  }

  &.dealer-container {
    margin-top: 5.6rem;

    .loading-comp {
      z-index: 2;
      position: absolute;
      height: 100% !important;
    }

    .dealer-bottom {
      ${flex('row', 'space-between', 'flex-start')};
      height: 44.3rem;
      position: relative;

      .dealer-bottom-left {
        overflow-y: auto;
        height: 100%;
        padding-right: 2.4rem;

        &::-webkit-scrollbar {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }
    }

    .map-container {
      width: 38rem;
      height: 44.3rem;
      background-color: ${colors.$color_f7f};
    }
  }

  // sc dropdown
  .dealer-sc {
    padding: 1.5rem;
    text-align: left;

    &-label {
      color: #000000;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
    }

    &-form {
      margin-top: 0.8rem;

      .haefe-dropdown-content {
        width: 100%;
        max-width: unset;
        margin: 0;
        padding: 1.6rem;
      }
    }

    &-info {
      .dealer-block {
        width: 100%;
      }
    }
  }

  @media ${layout.$tablet} {
    &.dealer-container {
      .dealer-area {
        .dealer-bottom {
          margin: 1.8rem -0.8rem 0;
          height: 46.1rem;
          .dealer-bottom-left {
            width: 50%;
            margin: 0 0.8rem;
            padding: 0;
          }
          .dealer-bottom-right {
            width: 50%;
            height: 100%;
            margin: 0 0.8rem;
          }
        }
      }
      .map-container {
        width: 100%;
        height: 100%;
      }
    }

    .dealer-block {
      .dealer-block-top {
        display: block;
        text-align: left;
        .dealer-block-top-right {
          margin-top: 0.8rem;
        }
      }
    }
  }

  @media ${layout.$mobile} {
    &.dealer-container {
      position: relative;
      margin-top: 2.4rem;
      .dealer-area {
        .dealer-bottom {
          display: flex;
          flex-direction: column-reverse;
          height: auto;
          margin: 0;
          .dealer-bottom-left {
            width: 100%;
            margin: 0;
          }
          .dealer-bottom-right {
            width: 100%;
            height: 55.55vw;
            margin: 0;
            .map-container {
              position: absolute;
              width: 100vw;
              left: -2rem;
            }
          }
        }
        .dealer-bottom-right {
          .map-container {
            height: 55.55vw;
            width: 100%;
          }
        }
      }
    }

    .dealer-block {
      .dealer-block-top {
        .dealer-block-top-right {
          margin-top: 0;
        }
      }
    }
  }
`;

export default changeDealerContainerStyle;
