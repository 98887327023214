/**
 * 대출계산기 > 지불 타입
 */
export const PAYMENT_TYPE: Calculators.IPaymentType = {
  CASH: 'CASH',
  FINANCE: 'FINANCE',
};

/**
 * 대출계산기 > BEGIN, END 옵션
 */
export const PAYMENT_TERM = {
  BEGIN: 'BEGIN',
  END: 'END',
};

/**
 * 대출계산기 > 선수금 비율, 기간
 */
export const DEFAULT_LOAN_OPTION = {
  DOWNPAYMENT_RATE: 10,
  FINANCING_TERM: 48,
};

/**
 * 대출계산기 은행 명칭 LIST
 */
export const DEFAULT_BANK = {
  OTHERS: 'Others',
  FULL_NAME: {
    KRUNGSRI: 'Krungsri',
    KLEASING: 'K Leasing',
    TISCO: 'Tisco',
  },
  DISPLAY_NAME: {
    KRUNGSRI: 'KRUNGSRI',
    KLEASING: 'KLEASING',
    TISCO: 'TISCO',
  },
};
