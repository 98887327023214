import useMember from '@/hooks/useIsMeber';
import { globalSpinnerState } from '@/stores/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { getAutoAssign, getAutoAssignGuest, getEmployeeDetail } from './api';

export const useAutoAssign = () => {
  const [isMember] = useMember();
  const setGlobalSpinnerState = useSetRecoilState(globalSpinnerState);

  return useMutation(
    async (params: Employee.IEmployeeAutoAssignReq) => {
      setGlobalSpinnerState(true);

      return isMember ? getAutoAssign(params) : getAutoAssignGuest(params);
    },
    { onSettled: () => setGlobalSpinnerState(false) },
  );
};

export const useEmployeeDetail = (employeeId: string | number) => {
  return useQuery(['EMPLOYEE_DETAIL', employeeId], () => getEmployeeDetail(employeeId));
};

export const useEmployeeDetailMutation = () => {
  return useMutation((employeeId: string | number) => getEmployeeDetail(employeeId));
};
