import { PATH } from './path';

export const KIA_MENU: Global.IMenuItems = {
  root: {
    name: 'root',
    path: '/',
    children: {},
  },
  cars: {
    name: 'Cars',
    path: '/cars',
    children: {},
  },
  service: {
    name: 'Service',
    path: '/service',
    children: {},
  },
  ctb: {
    name: 'Cl!ck to Buy',
    path: PATH.CLICK_TO_BUY,
    children: {},
  },
  cart: {
    name: 'Cart',
    path: PATH.CART,
    children: {},
  },
  login: {
    name: 'Login',
    path: PATH.LOGIN,
    children: {},
  },
  signup: {
    name: 'Sign Up',
    path: PATH.SIGN_UP,
    children: {},
  },
  mygarage: {
    name: 'My Garage',
    i18Key: 'MyGarage.Title',
    path: PATH.MY_GARAGE,
    children: {
      myctb: {
        name: 'My Cl!ck to Buy',
        path: PATH.MYCTB,
        children: {
          booking: { name: 'Pre-Booking Details', i18Key: 'Label.BookingDetails', path: PATH.BOOKING, children: {} },
          quotation: {
            name: 'Quotation Details',
            i18Key: 'Label.QuotationDetails',
            path: PATH.QUOTATION,
            remainQuery: true,
            children: {
              payment: {
                name: 'Pre-Booking Payment',
                i18Key: 'Label.BookingPayment',
                path: PATH.PAYMENT,
                children: {},
              },
            },
          },
        },
      },
      myaccount: {
        name: 'My Account',
        path: PATH.MY_ACCOUNT,
        children: {
          edit: {
            name: 'Edit Profile',
            path: '#',
            children: {},
          },
        },
      },
    },
  },
  logout: {
    name: 'Logout',
    path: PATH.LOGOUT,
    children: {},
  },
  compare: {
    name: 'Compare Vehicles',
    path: PATH.COMPARE,
  },
  requestTestDrive: {
    name: 'Request a Test Drive',
    path: PATH.TEST_DRIVE,
    children: {},
  },
  requestTradeIn: {
    name: 'Request a Trade-in',
    path: PATH.TRADE_IN,
    children: {},
  },
  requestQuote: {
    name: 'Request a Quote',
    path: PATH.QUOTE,
    children: {},
  },
  buildPrice: {
    name: 'Build & Price',
    path: PATH.BUILD_PRICE,
    children: {},
  },
  contactUs: {
    name: 'Contact Us',
    path: PATH.CONTACT_US,
    children: {},
  },
  selectVariant: {
    name: 'Select Variant',
    path: PATH.SELECT_VARIANT,
    children: {},
  },
};

export const MENU: Global.IMenuItems = KIA_MENU;
