import React, { useState } from 'react';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import myMapStyle from './style';
import { useCookies } from 'react-cookie';
import { DEFAULT_LAT_LNG } from '@/constants/common';
import { isNumber } from 'lodash-es';
import { isValidMarker } from '@/utils/validation';

interface IMyMapProps {
  containerStyle?: {
    width?: string;
    height?: string;
  };
  center?: {
    lat: number;
    lng: number;
  };
  dealerLocations?: {
    name: string;
    latitude: number;
    longitude: number;
    [key: string]: any;
  }[];
}

/**
 * [HISTORY]
 * 2024.7.18 HMGC-7164 - 경도/위도(lat,lng)가 null인 데이터로 내려올 경우 콘솔 에러로 Marker 미표기로 변경 / 연관 컴포넌트 : DealerBlockBottom
 */
function MyMap({
  containerStyle = {
    width: '100%',
    height: '100%',
  },
  center,
  dealerLocations = [],
}: IMyMapProps) {
  const [cookie] = useCookies(['lang']);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const isValidLatLng = center && isValidMarker(center.lat, center.lng);

  const coordinates = isValidLatLng ? center : DEFAULT_LAT_LNG;

  return (
    <div css={myMapStyle}>
      <LoadScriptNext googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY} language={cookie?.lang} region="kr">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={17}
          options={{ disableDefaultUI: true, clickableIcons: false, gestureHandling: 'cooperative' }}
          onTilesLoaded={() => {
            !isMapLoaded && setIsMapLoaded(true);
          }}
        >
          {isMapLoaded &&
            isValidLatLng &&
            dealerLocations.map(location => (
              <Marker
                key={location.id}
                position={{ lat: location.latitude ?? 0, lng: location.longitude ?? 0 }}
                zIndex={5}
                icon={{
                  url: '/assets/images/google-map-marker.png',
                  origin: { x: -13, y: -19, equals: null },
                  size: { width: 27, height: 37, equals: null },
                }}
              />
            ))}
        </GoogleMap>
      </LoadScriptNext>
    </div>
  );
}

export default MyMap;
