import React, { useEffect } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import { Form } from '@components/haeLibraryRefactored';
import RegionSelectFormContents from './components/contents';
import { USE_MY_LOCATION_AUTOFILL_DISTANCE } from './constants';
import { useGetDealersDistricts, useGetDealersProvinces, useGetDealersSubDistricts } from '@/query/dealers/query';

interface IRegionSelectProps {
  handleChange?: (values: IRegionFormData, name?: string) => void;
  formData?: Partial<IRegionFormData>;
  positionSuccessCallback?: (position: GeolocationPosition) => void;
  type?: TQuickModalType;
  requiredFields?: ('province' | 'district' | 'subDistrict')[];
}

function RegionSelectForm({
  formData,
  handleChange: handleChangeProps,
  positionSuccessCallback,
  type,
  requiredFields = ['province', 'district'],
}: IRegionSelectProps) {
  const form = Form.useForm<IRegionFormData>();
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  const { province, district } = form.getFieldsValueAll() ?? {};
  const { data: provinceData } = useGetDealersProvinces();
  const { data: districtData } = useGetDealersDistricts({ province: province });
  const { data: subDistrictData } = useGetDealersSubDistricts({
    province: province,
    district: district,
  });

  useEffect(() => {
    return () => {
      form.setFieldsValue(null);
    };
  }, []);

  useEffect(() => {
    if (formData !== undefined) form?.setFieldsValue(formData);
  }, [formData]);

  const setNewFormData = (newData: Partial<IRegionFormData>, name?: string) => {
    form.setFieldsValue(newData);

    if (typeof handleChangeProps === 'function') handleChangeProps(newData, name);
  };

  const handleChange = (changedValue: IRegionFormData, mountedValues: IRegionFormData) => {
    const name = Object.keys(changedValue)?.[0];
    const omitTargets =
      name === 'province' ? ['district', 'subDistrict'] : name === 'district' ? ['subDistrict'] : null;
    const newValue: IRegionFormData = _.omit(mountedValues, omitTargets);

    setNewFormData(newValue, name);
  };

  /**
   * currentDealerSelect 값으로 기본값 세팅하기
   * - 모달 재진입시 가장 마지막으로 세팅한 값으로 뜨도록
   * - 기본값에 대한 정의 필요
   */
  useEffect(() => {
    if (!!formData) return;

    // no dealer selected 인 경우 디폴트 선택된 지역 없음
    if (!selfSelectedDealer) {
      setNewFormData({
        province: null,
        district: null,
        subDistrict: null,
      });
    }
    // 현재 선택된 딜러가 있는 경우 해당 딜러 정보로 지역 세팅
    else if (currentDealerState) {
      setNewFormData({
        province: currentDealerState?.province,
        district: currentDealerState?.district,
        subDistrict: currentDealerState?.subDistrict,
      });
    }
  }, []);

  function findMyLocationSuccessCallback(dealerList: Dealers.TDealerEntity[]) {
    const closestDealer = dealerList?.[0];

    // 거리제한 때문에 지역 세팅이 안될 수도 있음 (100으로 제한)
    const isDealerCloseEnough = closestDealer?.distance < USE_MY_LOCATION_AUTOFILL_DISTANCE;

    const prevFormData = form.getFieldsValueAll();

    const newFormData = {
      ...prevFormData,
      province: isDealerCloseEnough ? closestDealer?.province : null,
      district: isDealerCloseEnough ? closestDealer?.district : null,
      subDistrict: isDealerCloseEnough ? closestDealer?.subDistrict : null,
    };

    form.setFieldsValue(newFormData);
  }

  return (
    <>
      <Form form={form} onChange={handleChange}>
        <RegionSelectFormContents
          findMyLocationSuccessCallback={findMyLocationSuccessCallback}
          positionSuccessCallback={positionSuccessCallback}
          type={type}
          provinceList={provinceData?.provinceList}
          districtList={districtData?.districtList}
          subDistrictList={subDistrictData?.subDistrictList}
          requiredFields={requiredFields}
        />
      </Form>
    </>
  );
}

export default RegionSelectForm;
