import { axiosInstance as axios } from '@/query/axios';

export const sendUserSms = async (params: HmgId.IUserSmsReq) => {
  try {
    const response = await axios.post(`/hmg-id/api/v1/user/sms`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export async function sendUserBrandSms(params: HmgId.IUserBrandSmsReq) {
  try {
    const response = await axios.put(`/hmg-id/api/v1/user/sms`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
}

export const refreshToken = async () => {
  try {
    const response = await axios.get('/gateway/users/token');

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const revokeToken = async () => {
  try {
    const response = await axios.get('/gateway/users/revoke');

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
