import { css } from '@emotion/react';
import { layout } from './mixin';

const icon2Style = css`
  // 아이콘
  .ic {
    // component > quick menu > test drive
    &.ic-test-drive {
      width: 3.1rem;
      height: 3.1rem;
      background: url('/assets/images/ic_test_drive.svg') no-repeat center / 3.1rem;
    }
    // component > quick menu > service Appointment
    &.ic-service-appointment {
      width: 3.1rem;
      height: 3.1rem;
      background: url('/assets/images/ic_reservation.svg') no-repeat center / 3.1rem;
    }
    // component > quick menu > trade in
    &.ic-trade-in {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_trade_in.svg') no-repeat center / 2.4rem;
    }
    // component > quick menu > get quote
    &.ic-get-quote {
      width: 3.1rem;
      height: 3.1rem;
      background: url('/assets/images/ic_get_quote.svg') no-repeat center / 3.1rem;
    }
    // component > quick menu > contact us
    &.ic-contact-us {
      width: 3.1rem;
      height: 3.1rem;
      background: url('/assets/images/ic_contact_us.svg') no-repeat center / 3.1rem;
    }
    // component > quick menu > line
    &.ic-quick-menu-line {
      width: 3.6rem;
      height: 3.4rem;
      background: url('/assets/images/ico_quick_menu_line.svg') no-repeat center / 3.6rem 3.4rem;
    }
    // component > quick menu > close
    &.ic-quick-menu-close {
      width: 1.5rem;
      height: 1.5rem;
      background: url('/assets/images/ic_quick_menu_close.svg') no-repeat center / 1.5rem;
    }
    // component > quick menu > bubble box tail
    &.ic-bubble-box-tail {
      width: 1rem;
      height: 0.9rem;
      background: url('/assets/images/ic_bubble_box_tail.svg') no-repeat center / 1rem 0.9rem;
    }
    // component > tooltip tail
    &.ic-tooltip-tail {
      width: 1.6rem;
      height: 0.8rem;
      background: url('/assets/images/ic_tooltip_tail.svg') no-repeat center / 1.6rem 0.8rem;
    }
    // component > tag close normal
    &.ic-tag-close {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_tag_close_nor.svg') no-repeat center / 1.6rem;
    }
    // component > button > line
    &.ic-line {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_line.svg') no-repeat center / 2.4rem;
    }
    // component > button > line play
    &.ic-line-pay {
      width: 7.6rem;
      height: 2.2rem;
      background: url('/assets/images/ic_line_pay.svg') no-repeat center / 7.6rem 2.2rem;
    }
    &.ic-2c2p-pay {
      width: 6rem;
      height: 1.8rem;
      background: url('/assets/images/ic_2c2p_pay.png') no-repeat center / 6rem 1.8rem;
    }
    // modal > Confirm icon
    &.ic-popup-confirm {
      width: 4.8rem;
      height: 4.8rem;
      background: url('/assets/images/ico_popup_confirm.svg') no-repeat center / 4.8rem;
    }
    // toast popup > Confirm icon
    &.ic-toast-popup-arrow {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ico_toast_popup_arrow.svg') no-repeat center / 1.6rem;
    }
    // tooltip > infomation icon
    &.ic-tooltip-info {
      width: 2rem;
      height: 2rem;
      background: url('/assets/images/ic_tooltip_info.svg') no-repeat center / 2rem;
    }
    // tooltip > close icon
    &.ic-tooltip-close {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/assets/images/ic_tooltip_close.svg') no-repeat center / 1.6rem;
    }
    // Variant Progress > arrow icon
    &.ic-progress-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_progress_arrow.svg') no-repeat center / 2.4rem;
    }
    // Variant Progress > modal close icon
    &.ic-modal-variant-close {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_modal_variant_close.svg') no-repeat center / 2.4rem;
    }
    // Variant > car rotation
    &.ic-rotation {
      width: 8rem;
      height: 8rem;
      background: url('/assets/images/ic_rotation.svg') no-repeat center / 8rem;
    }
    // Variant > select a trim compare button icon
    &.ic-trim-compare {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_trim_compare.svg') no-repeat center / 2.4rem;
    }
    // Variant > select a trim key feature arrow icon
    &.ic-key-feature-arrow {
      width: 1.8rem;
      height: 1.8rem;
      background: url('/assets/images/ic_key_feature_arrow.svg') no-repeat center / 1.8rem;
    }
    // Variant > payment information
    &.ic-payment-information-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_payment_information_arrow.svg') no-repeat center / 2.4rem;
    }
    // Variant > option detail arrow
    &.ic-option-summary-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_option_summary_arrow.svg') no-repeat center / 2.4rem;
    }
    // Variant > modal spec detail arrow
    &.ic-detail-option-arrow {
      width: 1.8rem;
      height: 1.8rem;
      background: url('/assets/images/ic_detail_option_arrow.svg') no-repeat center / 1.8rem;
    }
    // Variant > modal spec detail option arrow
    &.ic-option-item-arrow {
      width: 1.8rem;
      height: 1.8rem;
      background: url('/assets/images/ic_option_item_arrow.svg') no-repeat center / 1.8rem;
    }
    // Modal > payment progress
    &.ic-payment-progress {
      width: 4.8rem;
      height: 4.8rem;
      background: url('/assets/images/ic_payment_progress.svg') no-repeat center / 4.8rem;
    }
    // My Garage > booking payment complete
    &.ic-after-booking-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_after_booking_arrow.svg') no-repeat center / 2.4rem;
    }
    // My Garage > booking payment complete
    &.ic-after-booking-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_after_booking_arrow.svg') no-repeat center / 2.4rem;
    }
    // Compare
    &.ic-compare-move {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_compare_move.svg') no-repeat center / 2.4rem;
    }
    // Compare
    &.ic-compare-remove {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_compare_remove.svg') no-repeat center / 2.4rem;
    }
    // Compare
    &.ic-compare-add {
      width: 5.6rem;
      height: 5.6rem;
      background: url('/assets/images/ic_compare_add.svg') no-repeat center / 5.6rem;
    }
    // Compare
    &.ic-compare-arrow {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_compare_arrow.svg') no-repeat center / 2.4rem;
    }
    // Compare
    &.ic-compare-swiper-nav {
      width: 4.8rem;
      height: 4.8rem;
      background: url('/assets/images/ic_compare_swiper_nav.svg') no-repeat center / 4.8rem;
    }
    // Comment - page title back button
    &.ic_header_back {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_header_back.svg') no-repeat center / 2.4rem;
    }
    // My Garage - block
    &.ic-ctb-block-remove {
      width: 2.4rem;
      height: 2.4rem;
      background: url('/assets/images/ic_ctb_block_remove.svg') no-repeat center / 2.4rem;
    }
    // My Garage - block
    &.ic_notice {
      height: 70px;
      width: 70px;
      // TODO: 네트워크 단절시에도 쓰이는 이미지로 base64로 고정값 사용
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI0IiBjeT0iMjMuOTY0OCIgcj0iMjEuMiIgc3Ryb2tlPSIjOEY4RjhGIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8cGF0aCBkPSJNMjQgMTQuNzE2OEwyNCAyNi43MTY4IiBzdHJva2U9IiM4RjhGOEYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTIyLjUgMzMuMjEyOUwyNS41IDMzLjIxMjlMMjUuNSAzMC4yMTI5TDIyLjUgMzAuMjEyOUwyMi41IDMzLjIxMjlaIiBmaWxsPSIjOEY4RjhGIi8+DQo8L3N2Zz4NCg==)
        no-repeat center / 2.4rem;
      background-size: contain;
    }

    // Configuration - image button
    &.ic-background {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_background.svg') no-repeat center / cover;
    }

    &.ic-no-background {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_no-background.svg') no-repeat center / cover;
    }

    &.ic-config-360 {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_config-360.svg') no-repeat center / cover;
    }

    &.ic-exterior {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_exterior.svg') no-repeat center / cover;
    }

    &.ic-interior {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_interior.svg') no-repeat center / cover;
    }

    &.ic-two-dimensional {
      width: 4rem;
      height: 4rem;
      background: url('/assets/images/ic_two-dimensional.svg') no-repeat center / cover;
    }
  }

  @media ${layout.$mobile} {
    .ic {
      &.ic-rotation {
        width: 6.6rem;
        height: 6.6rem;
        background-size: 6.6rem;
      }
    }
  }
`;

export default icon2Style;
