import { css } from '@emotion/react';
import { flex } from 'styles/mixin';

// const FooterStyle = css`
//   padding: 6rem 2rem;
//   background-color: ${colors.$color_1B1};
//   .footer-area {
//     ${flex('row', 'flex-start', 'flex-start')};
//     max-width: ${layout.$content_size};
//     height: 14rem;
//     margin: 0 auto;
//   }

//   .footer-top,
//   .footer-middle,
//   .footer-bottom {
//     height: 100%;
//   }
//   .footer-top {
//     padding-right: 10.6rem;
//     border-right: 1px dotted ${colors.$color_999};
//     .footer-logo-box {
//       display: block;
//       .logo {
//         display: block;
//         width: 15rem;
//         img {
//           display: block;
//           width: 100%;
//           height: auto;
//         }
//       }
//     }
//   }
//   .footer-middle {
//     padding: 0 3.2rem;
//     border-right: 1px dotted ${colors.$color_999};
//     .footer-middle-box {
//       ${flex('column', 'flex-start', 'flex-start')};
//       width: 32rem;
//       .footer-info-box {
//         ${flex('row', 'flex-start', 'flex-start')};
//         &:not(:first-of-type) {
//           margin-top: 1.6rem;
//         }
//         .ic {
//           position: relative;
//           top: 0.1rem;
//         }
//         .info-text-box {
//           ${flex('column', 'flex-start', 'flex-start')};
//           margin-left: 0.8rem;
//           ${font('1.4rem', 400, colors.$color_999, '2rem')};
//           .text {
//             &:not(:first-of-type) {
//               margin-top: 0.8rem;
//             }
//           }
//         }
//       }
//     }
//   }
//   .footer-bottom {
//     flex: 1;
//     ${flex('column', 'flex-start', 'flex-end')};
//     .top-box {
//       ${flex('column', 'flex-start', 'flex-end')};
//       .link {
//         ${font('1.4rem', 500, colors.$color_999, '2rem')};
//         &:not(:first-of-type) {
//           margin-top: 1rem;
//         }
//       }
//     }
//     .middle-box {
//       ${flex('row', 'flex-start', 'flex-end')};
//       margin-top: 3rem;
//       .sns-box {
//         // display: block;
//         width: 2.4rem;
//         height: 2.4rem;
//         &:not(:first-of-type) {
//           margin-left: 2.4rem;
//         }
//       }
//     }
//     .bottom-box {
//       margin-top: 1.6rem;
//       ${font('1.4rem', 400, colors.$color_999, '2rem')};
//     }
//   }

//   @media ${layout.$mobile} {
//     padding: 3.2rem 2rem;
//     .footer-area {
//       ${flex('column', 'flex-start', 'flex-start')};
//       width: 100%;
//       height: auto;
//     }

//     .footer-top,
//     .footer-middle,
//     .footer-bottom {
//       width: 100%;
//     }
//     .footer-top {
//       padding: 0;
//       border: none;
//       .footer-logo-box {
//         width: 13rem;
//       }
//     }
//     .footer-middle {
//       padding: 3.2rem 0;
//       border-right: none;
//       border-bottom: 1px dotted ${colors.$color_999};
//       .footer-middle-box {
//         width: auto;
//         .footer-info-box {
//           .info-text-box {
//             .text {
//               &:not(:first-of-type) {
//                 margin-top: 1rem;
//               }
//             }
//           }
//         }
//       }
//     }
//     .footer-bottom {
//       flex: auto;
//       align-items: flex-start;
//       padding-top: 3.2rem;
//       .top-box {
//         align-items: flex-start;
//         .link {
//           font-weight: 400;
//         }
//       }
//       .middle-box {
//         margin-top: 3.2rem;
//       }
//       .bottom-box {
//         margin-top: 3.2rem;
//       }
//     }
//   }
// `;
//
// export default FooterStyle;

const FooterStyle = css`
  &.footerWrap {
    background: rgb(28, 27, 27);
    height: auto;
    min-width: 1240px;
    padding-bottom: 24px;
    .footerArea {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 1240px;
      margin: 0 auto;
      padding: 24px 60px 0;
      box-sizing: content-box;
      letter-spacing: 0;
      .footerMenu {
        flex: 1;
        overflow: hidden;
        margin: 0 55px 15px;
        > ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -6px;
          li {
            position: relative;
            padding: 0 6px;
            line-height: 1;
            &:after {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              top: 3px;
              width: 1px;
              height: 9px;
              background: #333232;
            }
            a {
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
      .snsShare {
        // TODO SNS 추가시 display: none삭제
        display: none;
        ul {
          display: flex;
          margin: 0 -5px;
          li {
            margin: 0 5px;
            a {
              display: flex;
              align-items: center;
              // display: block;
              overflow: hidden;
              width: 24px;
              height: 24px;
              text-indent: -9999px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              margin-top: -15%;
            }
          }
        }
      }
      .tabC {
        width: 100%;
        padding: 0 115px;
      }
      .copyright {
        color: #5f5f5f;
        font-size: 12px;
        line-height: 1;
      }
      .telgab {
        padding-left: 16px;
      }
      .goto {
        overflow: hidden;
        position: absolute;
        right: 4px;
        top: 96px;
        color: #999;
        font-size: 14px;
        line-height: 18px;
        a {
          padding-left: 5px;
          color: #999;
          font-size: 14px;
          text-decoration: underline;
        }
        img.flag {
          float: right;
          margin-left: 20px;
          box-shadow: none !important;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) nd (max-width: 1240px) {
    /* footer */
    &.footerWrap {
      min-width: unset;
      .footerArea {
        width: 100%;
        box-sizing: border-box;
        .footerMenu {
          margin: 0 30px;
          li {
            margin-bottom: 8px;
            &.tabE {
              margin-left: 0;
              padding-left: 0;
              &:after {
                width: 0;
              }
            }
          }
        }
        .tabC {
          margin-top: 30px;
          padding: 0 88px;
        }
        .copyright .telgab {
          display: block;
          margin-top: -30px;
          padding-left: 0;
        }
      }
    }
  }

  /* D:tab */
  @media screen and (max-width: 1024px) {
    &.footerWrap {
      min-width: unset;
      .footerArea {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        .footerMenu {
          margin: 0 30px;
          li {
            margin-bottom: 8px;
            &.tabE {
              margin-left: 0;
              padding-left: 0;
              &:after {
                width: 0;
              }
            }
          }
        }
        .tabC {
          margin-top: 30px;
          padding: 0 88px;
        }
        .copyright .telgab {
          display: block;
          margin-top: -30px;
          padding-left: 0;
        }
      }
    }
  }

  /* D:mobile */
  @media screen and (max-width: 767px) {
    img.flag {
      width: 26px;
      height: 18px;
      box-shadow: 1px 1px 3px rgba(148, 148, 148, 0.8);
      vertical-align: middle;
    }

    &.footerWrap {
      background: #1c1b1b;
      padding-bottom: 30px;
      height: auto;
      min-width: 100%;
      .footerArea {
        flex-flow: column;
        padding: 20px 0 0;
        .logo {
          order: 1;
          margin-top: 30px;
          margin-top: 30px;
          text-align: center;
          ${flex('row', 'center', 'center')}
          img {
            width: 48px;
          }
        }
        .footerMenu {
          overflow: hidden;
          margin: 0 20px;
          letter-spacing: -0.02em;
          ul {
            overflow: hidden;
            vertical-align: top;
            position: relative;
            li {
              position: relative;
              width: 50%;
              height: 32px;
              padding: 0 6px 15px;
              line-height: 1.2;
              a {
                color: #999;
                font-size: 13px;
                font-family: 'HyundaiRegular';
              }
              &:after {
                display: block;
                content: none;
                position: absolute;
                left: 0;
                top: 3px;
                width: 1px;
                height: 9px;
                background: #333232;
              }
            }
          }
        }
        .snsShare {
          position: relative;
          border-bottom: 1px solid #1f1f1f;
          border-top: 1px solid #1f1f1f;
          height: 60px;
          ul {
            height: 60px;
            padding: 0 12px;
            width: 100%;
            box-sizing: border-box;
            ${flex('row', 'center', 'center')}
            li {
              width: 16%;
              margin-left: 0;
              a {
                display: flex;
                align-items: center;
                height: 60px;
                overflow: hidden;
                text-indent: -9999px;
                background-position: center center;
                background-repeat: no-repeat;
                margin: 0 auto;
              }
              &.tabE {
                margin-left: 0;
                padding: 0 0 15px 12px;
                &:after {
                  width: auto;
                }
              }
            }
          }
        }
        .copyright {
          // width: 190px;
          margin: 25px auto 0;
          color: #666;
          font-size: 12px;
          text-align: center;
        }
        .goto {
          position: absolute;
          top: 35px;
          left: 20px;
          color: #999;
          font-size: 14px;
          line-height: 18px;
          a {
            padding-left: 5px;
            color: #999;
            font-size: 14px;
            text-decoration: underline;
          }
          img.flag {
            width: auto;
            margin-right: 15px;
            box-shadow: none !important;
            vertical-align: top;
          }
        }
        .tabC {
          display: flex;
          order: 2;
          flex-flow: column-reverse;
          padding: 0;
          text-align: center;
        }
        .copyright .telgab {
          display: block;
          margin-top: -30px;
          padding-left: 0;
        }
        .copyright a {
          color: #666;
        }
      }
    }
  }
`;

export default FooterStyle;
