import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const DealerBlockBottom = css`
  position: relative; // TODO: THAIONSALE-1954 Doty 추가 건 수정사항

  &.dealer-block-bottom {
    margin-top: 0.8rem;

    .info-box {
      position: relative;
      padding-right: 10rem;
      ${flex('row', 'flex-start', 'flex-start')};
      &:not(:first-of-type) {
        margin-top: 1.2rem;
      }

      .ic {
        min-width: 1.4rem;
        margin-top: 0.3rem;
        &.ic-delivery-ready {
          + .info {
            color: ${colors.$secondary1};
          }
        }
      }
      .info {
        margin-left: 0.6rem;
        ${font('1.3rem', 400, colors.$color_767, '2rem')};
        text-align: left;

        &.ready {
          color: ${colors.$secondary1};
        }
      }

      .info-inline {
        display: inline;
      }

      .info-copy {
        margin-left: 1.2rem;
        ${font('1.3rem', 400, colors.$secondary1, '2rem')};
      }
    }

    .line-button-box {
      width: 100%;
      button {
        width: 100%;
        margin-top: 1.6rem;
      }

      .btn-line {
        ${flex('row', 'center', 'center')};
        background-color: ${colors.$line};
        ${font('1.6rem', 400, colors.$color_fff, '2.4rem')};
        &:disabled {
          background-color: ${colors.$color_767};
          color: ${colors.$color_999};
          .ic-line-pay {
            background: url('/aseets/images/ic_line_pay_dis.svg') no-repeat center / 7.6rem 2.2rem;
          }
        }

        .ic-line {
          margin-right: 0.8rem;
        }
        .ic-line-pay {
          margin-left: 0.8rem;
        }
      }
    }
  }
`;

export default DealerBlockBottom;
