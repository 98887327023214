import React, { useCallback, useRef } from 'react';
import ModalWrapper from '@/components/haeLibraryRefactored/Modal/components/modalWrapper';
import modalStyle from './style';
import Button from '@components/units/button';
import { IAlertModalProps } from '../type';
import classNames from 'classnames';

function AlertModal({
  className,
  visible,
  closeModal = () => null,
  backDropClose = true,
  displayCloseButton = true,
  modalTitle = '',
  handlerOk,
  handlerCancel,
  okString = 'OK',
  cancelString = 'Cancel',
  title,
  text,
  icon = 'ic_notice',
  modalWrapperClassName = '',
  modalWrapperStyle,
  ...otherProps
}: IAlertModalProps) {
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);

  const handleFocusModal = useCallback((e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (!e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
      modalRef?.current?.focus();
    }
  }, []);

  const handleFocusCloseButton = useCallback((e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
      closeButtonRef?.current?.focus();
    }
  }, []);

  return (
    <ModalWrapper
      backDropClose={backDropClose}
      open={visible}
      closeModal={() => closeModal()}
      wrapperClassName={`alert-popup ${modalWrapperClassName}`}
      style={modalWrapperStyle}
    >
      <div
        css={modalStyle}
        className={classNames(
          className,
          `modal-wrap-class  modal-inner ${!modalTitle ? 'none-title' : ''} ${
            !handlerOk || !handlerCancel ? 'none-button' : ''
          }`,
        )}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-label"
        {...otherProps}
      >
        <div className="modal-area">
          <div className={`modal-top modal-header`}>
            <p
              className="modal-title modal-title-class"
              id="modal-label"
              style={{ opacity: modalTitle ? 1 : 0 }}
              tabIndex={0}
              ref={modalRef}
              onKeyDown={handleFocusCloseButton}
            >
              {modalTitle}
            </p>
            {displayCloseButton && (
              <button type="button" onClick={() => closeModal()} className="btn-close modal-closeButton" />
            )}
          </div>
          <div className="modal-middle modal-content">
            {icon && (
              <div className="modal-icon-box">
                <i className={`ic ${icon}`} />
              </div>
            )}
            {title && <em className="title">{title}</em>}
            {text &&
              (typeof text === 'string' ? (
                <p
                  className="desc"
                  tabIndex={0}
                  onKeyDown={(e: React.KeyboardEvent<HTMLParagraphElement>) => {
                    if (handlerOk || handlerCancel) {
                    } else {
                      handleFocusModal(e);
                    }
                  }}
                  ref={closeButtonRef}
                  dangerouslySetInnerHTML={{
                    __html: text.toString(),
                  }}
                ></p>
              ) : (
                <div
                  className="desc"
                  tabIndex={0}
                  onKeyDown={(e: React.KeyboardEvent<HTMLParagraphElement>) => {
                    if (handlerOk || handlerCancel) {
                    } else {
                      handleFocusModal(e);
                    }
                  }}
                  ref={closeButtonRef}
                >
                  {text}
                </div>
              ))}
          </div>
          <div className="modal-bottom modal-footer">
            {handlerCancel && (
              <Button
                buttonType="primary"
                onClick={async () => {
                  handlerCancel();
                  closeModal();
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLParagraphElement>) => {
                  if (handlerOk) {
                  } else {
                    handleFocusModal(e);
                  }
                }}
                refProps={closeButtonRef}
                className="modal-close secondary-button m-buttonSize h56"
              >
                {cancelString}
              </Button>
            )}
            {handlerOk && (
              <Button
                buttonType="primary"
                onClick={() => {
                  handlerOk();
                  // closeModal();
                }}
                onKeyDown={handleFocusModal}
                refProps={closeButtonRef}
                className="modal-ok primary-button m-buttonSize h56"
              >
                {okString}
              </Button>
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default AlertModal;
