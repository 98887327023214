export const FOOTER_LINK = [
  {
    key: 'Footer.ContactUs',
    gnbMenu: 'service/contact-us',
  },
  {
    key: 'Footer.LegalDisclaimer',
    gnbMenu: 'utility/legal-disclaimer',
  },
  {
    key: 'Footer.Terms',
    gnbMenu: 'utility/terms-conditions',
  },
  {
    key: 'Footer.PrivacyPolicy',
    gnbMenu: 'utility/privacy-policy',
  },
  {
    key: 'Footer.CookiePolicy',
    gnbMenu: 'utility/cookie-policy',
  },
  {
    key: 'Footer.BookingCondition',
    gnbMenu: 'utility/booking-condition',
  },
  {
    key: 'Footer.Sitemap',
    gnbMenu: 'utility/sitemap',
  },
];
