import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import CheckboxGroup from './CheckboxGroup';
import { STATUS_CLASS } from '../constants/status';
import { CheckboxBasicProps, CustomCheckedValues } from './type';

function Checkbox({
  onChange = () => null,
  onClick = () => null,
  checked: checkedProp,
  defaultChecked,
  customCheckedValues,
  disabled = false,
  label,
  name = '',
  value = '',
  className = '',
  status,
  style,
  ...otherProps
}: CheckboxBasicProps) {
  const [checked, setChecked] = useState<boolean>(checkedProp ?? defaultChecked ?? false);
  const statusClassName = STATUS_CLASS[status] ?? null;

  const ref = useRef(null);

  const getCheck = (check: boolean, customCheckedValues: CustomCheckedValues) => {
    return customCheckedValues && check
      ? customCheckedValues.checked
      : customCheckedValues && !check
      ? customCheckedValues.unChecked
      : check;
  };

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (disabled) return;

      const check = !checked;

      onClick(check, e, value);
      onChange(getCheck(check, customCheckedValues), e, value);

      if (checkedProp !== undefined) return;

      setChecked(check);
    },
    [value, checked, customCheckedValues, onClick, onChange, checkedProp, disabled],
  );

  useEffect(() => {
    if (checkedProp === undefined) return;

    setChecked(checkedProp);
  }, [checkedProp]);

  return (
    <span className={classNames(className, 'haefe-checkbox')}>
      <label
        className={classNames(
          'haefe-checkbox-label',
          checked ? 'haefe-checkbox-checked' : 'haefe-checkbox-unchecked',
          disabled && 'haefe-checkbox-disabled',
          statusClassName ?? null,
        )}
      >
        <input
          className="haefe-checkbox-input"
          type="checkbox"
          name={name}
          value={value}
          disabled={disabled}
          onClick={handleClick}
          ref={ref}
          checked={checked}
          onChange={() => {
            return;
          }}
          {...otherProps}
        />
        <i className="haefe-checkbox-itag"></i>
        {label && <div className="haefe-checkbox-content">{label}</div>}
      </label>
    </span>
  );
}

Checkbox.Group = CheckboxGroup;

export default Checkbox;
