import { QueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentConsultantSelector, currentDealerSelector, userSelector } from '@/stores/common/user';
import { useCookies } from 'react-cookie';
import { getDefaultDealer } from '@/query/dealers/api';
import { isValidNumber } from '@/utils/validation';

function useSetDefaultDealer() {
  const setCurrentDealer = useSetRecoilState(currentDealerSelector);
  const setCurrentConsultant = useSetRecoilState(currentConsultantSelector);
  const userStore = useRecoilValue(userSelector);
  const [cookies] = useCookies(['lang', 'buyOnline_curDealerId', 'buyOnline_consultantId']);
  const userProfileInfo = userStore?.profile?.userAssociatedDealer;

  /**
   * 우선순위 별 딜러 id 값
   */
  const getDealerId = (isLangChanged?: boolean): number => {
    // 유저정보에 있는 dealer id
    if (userProfileInfo?.dealerId && !isLangChanged) {
      return userProfileInfo.dealerId;
    }

    // 쿠키 dealerId
    if (isValidNumber(cookies?.buyOnline_curDealerId)) {
      return Number(cookies?.buyOnline_curDealerId) || null;
    }

    // env 값
    return Number(process.env.NEXT_PUBLIC_DEFAULT_DEALER_ID);
  };

  /**
   * 우선순위 별 sc id 값
   */
  const getConsultantId = (isLangChanged?: boolean): number | null => {
    // 유저정보에 있는 sc id
    if (userProfileInfo?.employeeId && !isLangChanged) {
      return userProfileInfo.employeeId;
    }

    // 쿠키 sc id
    if (isValidNumber(cookies?.buyOnline_consultantId)) {
      return Number(cookies?.buyOnline_consultantId) || null;
    }

    return null;
  };

  /**
   * default 딜러 설정
   */
  const setDefaultDealer = async (queryClient: QueryClient, isLangChanged?: boolean) => {
    const targetDealerId = getDealerId(isLangChanged);
    const targetConsultantId = getConsultantId(isLangChanged);

    const targetDealerEntity = await getDefaultDealer(targetDealerId, cookies?.lang, queryClient);
    const targetConsultantEntity = targetDealerEntity.dealer.employees.find(employee => {
      return employee.id === targetConsultantId;
    });

    setCurrentDealer(targetDealerEntity?.dealer);
    setCurrentConsultant(targetConsultantEntity);
  };

  return setDefaultDealer;
}

export default useSetDefaultDealer;
