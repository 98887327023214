import { useRef, useState } from 'react';
import { getValueByKeys, setValueByKeys } from '@/components/haeLibraryRefactored/utils/form';
import _ from 'lodash-es';
// import { _.cloneDeep } from '@/components/haeLibraryRefactored/utils/_.cloneDeep';
import { Entity, FormInstance, InternalProperty, NamePath } from '../type';

class FormFactory<T extends object> {
  getForm = () => {
    return {
      getFieldsValueAll: this.getFieldsValueAll,
      getFieldsValue: this.getFieldsValue,
      getFieldValue: this.getFieldValue,
      setFieldsValue: this.setFieldsValue,
      setFieldValue: this.setFieldValue,
      resetFields: this.resetFields,
      submit: this.submit,
      getInternalProperty: this.getInternalProperty,
    } as FormInstance<T>;
  };
  private fields: Partial<T>;
  private mountedEntities: Entity[];
  private entities: Entity[];
  private rerenderForm: () => void;
  private getInternalProperty = (): InternalProperty<T> => {
    return {
      registerField: this.registerField,
      setInitialValues: this.setInitialValues,
      checkValid: this.checkValid,
    };
  };
  private submit: () => void;
  constructor(rerenderForm: () => void) {
    this.fields = {};
    this.mountedEntities = [];
    this.entities = [];
    this.rerenderForm = rerenderForm;
  }
  private registerField = (itemObj: Entity, value: any) => {
    this.mountedEntities.push(itemObj);
    if (!this.entities?.some(d => d?.name === itemObj?.name)) {
      this.setFieldValue(itemObj.name, value);
      this.entities.push(itemObj);
    }

    return () => {
      this.mountedEntities = this.mountedEntities.filter(d => d !== itemObj);
    };
  };
  private checkValid = () => {
    return this.entities?.map((d: Entity) => ({
      name: d?.name,
      status: d?.onCheckValid(getValueByKeys(d?.name, this.fields)),
    }));
  };

  private setInitialValues = (newFields: Partial<T>, init: boolean) => {
    if (init) {
      this.setFieldsValue(newFields);
      this.rerenderForm();
    }
  };

  private getFieldsValueAll = () => {
    return _.cloneDeep(this.fields);
  };
  private getFieldsValue = (nameList?: (string | number | NamePath)[]) => {
    const newNameList = nameList ?? this.mountedEntities?.map(d => d.name);
    const targetFields: Partial<T> = {};

    newNameList.forEach((name: NamePath | string | number) => {
      const value = getValueByKeys(name as (string | number)[] | string, this.fields);

      if (value) {
        Array.isArray(name)
          ? setValueByKeys(getValueByKeys(name as (string | number)[], this.fields), name, targetFields)
          : (targetFields[name as keyof T] = this.fields?.[name as keyof T]);
      }
    });

    return _.cloneDeep(targetFields);
  };
  private getFieldValue = (name: NamePath) => {
    return _.cloneDeep(getValueByKeys(name, this.fields));
  };

  private setFieldsValue = (values: Partial<T>) => {
    this.fields = values;
    this.rerenderForm();
  };
  private setFieldValue = (name: NamePath, value: any) => {
    if (typeof name === 'string') {
      this.setFieldsValue({ ...this.fields, [name]: value });
    } else if (Array.isArray(name)) {
      const newFormFields = _.cloneDeep(this.fields);

      setValueByKeys(value, name, newFormFields);
      this.setFieldsValue(newFormFields);
    }
  };

  private resetFields = (initialValues?: Partial<T>) => {
    this.setFieldsValue(initialValues ?? {});
  };
}

const useForm = <T extends object>(form?: FormInstance<T>) => {
  const formRef = useRef<FormInstance<T>>(null);
  const [, setRenderFlag] = useState({});

  if (!formRef.current) {
    if (form) {
      formRef.current = form;
    } else {
      const rerenderForm = () => setRenderFlag({});
      const formStore = new FormFactory(rerenderForm);

      formRef.current = formStore.getForm();
    }
  }

  return formRef.current;
};

export default useForm;
