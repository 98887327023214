import React, { memo, useEffect, useRef, useState } from 'react';
import AgreementModal from '@/components/modules/agreementModal';
import { memberCheckState, userSelector } from '@/stores/common/user';
import { useSetRecoilState } from 'recoil';
import { globalAlertPropsState } from '@/stores/common';
import { useRouter } from 'next/router';
import useMenuLists from '@/hooks/useMenuList';
import { useUserJoin, useUserProfile } from '@/query/users/query';
import Loading from '@/components/modules/Loading';
import { useTranslation } from 'next-i18next';
import useLogout from '@/hooks/useLogout';
import { useCustomCookies } from '@/hooks/useCustomCookie';

interface Props {}

function AgreementLayout({}: Props) {
  const modal = useRef<any>({});
  const router = useRouter();
  const menuLists = useMenuLists();
  const { t } = useTranslation('common');

  const setGlobalAlertStore = useSetRecoilState<Global.IAlert>(globalAlertPropsState);
  const setUserStore = useSetRecoilState(userSelector);
  const setMemberCheckState = useSetRecoilState(memberCheckState);
  const [cookies] = useCustomCookies(['lang', 'cookie_BuyOnline_th', 'main']);
  const logout = useLogout();
  const [loading, setLoading] = useState(false);
  const { mutate: getUserProfile, reset } = useUserProfile({
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const { mutate: setUserJoin } = useUserJoin({
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (cookies?.cookie_BuyOnline_th) {
      getUserProfile(null, {
        onSuccess: profile => {
          if (!profile?.agreedTerms?.some(item => item.name === 'AGREE_PRIVACY_POLICY')) {
            modal.current.openModal();

            return;
          }

          if (profile.prevStatus === 'WITHDRAWAL_REQUESTED') {
            setGlobalAlertStore({
              title: t('Label.LogIn'),
              message: t('Alert.WelcomeBack'),
              handlerOk: () => {
                setGlobalAlertStore(null);
              },
            });

            return;
          }

          setMemberCheckState(true);
          setUserStore({ profile: profile });
        },
        onError: (error: any) => {
          switch (+error?.response.status) {
            case 401:
              if (error?.response?.data?.code === 'TOKEN_EXPIRED') {
                // NOTE::Layouts/index.tsx에서 처리함

                break;
              }

              modal.current.openModal();

              break;
            case 400:
              if (error?.response?.data?.code === 'USER_HAS_BEEN_ALREADY_WITHDRAWN') {
                logout();
                setGlobalAlertStore({
                  title: t('Label.RequestFailed'),
                  message: t('Alert.AlreadyWithdrawal'),
                  handlerOk: () => {
                    setGlobalAlertStore(null);
                    if (router.pathname !== '/') {
                      router.push(menuLists.root.path);
                    }
                  },
                });
              }

              break;
            default:
              break;
          }
        },
      });
    } else {
      setMemberCheckState(true);
    }

    return () => reset();
  }, [cookies?.cookie_BuyOnline_th]);

  const AgreementModalCallback = (checked: string[]) => {
    if (!checked?.includes('policy')) {
      logout();
      modal.current.closeModal();

      if (/configuration/g.test(router.pathname)) {
        //todo: middleware router replace 수정 이후 변경 필요
        window.location.replace(menuLists.root.path);
      }

      if (/mygarage/g.test(router.pathname)) {
        router.push(menuLists.root.path);
      }

      return false;
    }
    const language = cookies.lang === 'th' ? 'THAILAND' : 'ENGLISH';
    const params: Users.IUsersJoinReq = {
      accessToken: cookies?.cookie_BuyOnline_th,
      agreedPrivacyPolicy: checked?.includes('policy'),
      agreedMarketing: checked?.includes('receive'),
      agreedOfflineDataSync: checked?.includes('offline'),
      authProvider: process.env.NEXT_PUBLIC_AUTH_PROVIDER || 'HMC',
      language: language,
    };

    setLoading(true);
    setUserJoin(params, {
      onSuccess: profile => {
        setUserStore({ profile: profile });
        modal.current.closeModal();
        if (router.pathname === '/configuration') {
          router.push({
            pathname: '/configuration',
            query: router.query,
          });

          return false;
        }
        if (router.pathname !== '/') {
          router.push({
            pathname: '/',
            query: router.query,
          });

          return false;
        }
      },
    });
  };

  return (
    <>
      <AgreementModal
        ref={modal}
        agreeKeys={['policy', 'receive']}
        displayCloseButton={true}
        onFinish={AgreementModalCallback}
        onClose={() => AgreementModalCallback([])}
      />
      {loading && <Loading />}
    </>
  );
}

export default memo(AgreementLayout);
