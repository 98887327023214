import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { appWithTranslation } from 'next-i18next';
import Layout from '@layouts/index';
import GlobalStyle from 'styles/globalStyle';
import 'styles/haeLibrary/thai.hyundai.scss';
import { useRouter } from 'next/router';
import '@/utils/logger';
import { selfSelectedDealerState } from '@/stores/common/user';
import { useDatadogRum } from '@/hooks/useDatadogRum';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

function MyApp({ Component, pageProps }: AppProps) {
  useDatadogRum();

  const router = useRouter();

  if (router.pathname.startsWith('/logout')) {
    return (
      <>
        <Component {...pageProps} />
      </>
    );
  }

  return (
    <RecoilRoot
      initializeState={({ set }) => set(selfSelectedDealerState, pageProps.cookies?.['buyOnline_selfSelectedDealer'])}
    >
      <GlobalStyle />
      <Layout pagePropsError={pageProps.error}>
        <Component {...pageProps} />
        <div id="modal-portal"></div>
      </Layout>
    </RecoilRoot>
  );
}

const AppWithI18n = appWithTranslation(MyApp);

export default AppWithI18n;
